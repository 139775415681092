import { lazy, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthContext } from "../contextProviderFiles/AuthContextProvider";
const LoadingPage = lazy(() => import('./LoadingPage'))

const ProtectedRoute = () => {
    const authInfo = useAuthContext();
    const location = useLocation();
       
    useEffect(() => {
        if (authInfo.wasAuth0Redirect()) {   
           if(process.env.REACT_APP_ENV !== 'staging'){
                authInfo.ensureAuthentication("/");
           }
           else{    
                authInfo.ensureAuthentication("/login");
           }
        }
    });

    return(
        authInfo.isLoggedIn() 
            ? <Outlet /> 
            : authInfo.wasAuth0Redirect() ? <LoadingPage /> : <Navigate to="/login" state={{ from: location }} replace />
    );
}
export default ProtectedRoute

