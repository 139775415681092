import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUserContext } from "../contextProviderFiles/UserContextProvider"

const unrestrictedUsers = ["vpatwa","hchaudhary"];

const AuthoriseRoute = (props : any) => {
    const { allowedPrivileges } = props
    const { loggedUserInfo }  = useUserContext();
    const location = useLocation(); 
    return(
        
        (loggedUserInfo?.assignedPrivileges?.split(',').find(role => allowedPrivileges.find(a => a.toString() === role.trim())))
            || (unrestrictedUsers.find(user => user === loggedUserInfo.userName))
                ? <Outlet />
                : <Navigate to="/unauthorized" state={{ from: location }} replace />
    );
}

export default AuthoriseRoute