import * as React from "react";
import { Calendar, CalendarValueType } from "primereact/calendar";
import dateFormat from "dateformat";

const PrwcDatePicker = (props: any) => {
  const { options, placeHolder, ...restProps } = props;  
  const showIcon = options["showIcon"] ?? false;
  const icon = options["icon"] ?? "";  
  const [value, setValue] = React.useState(options.defaultValue || null) 
  return (
    <Calendar      
      value={value !== null ? new Date( value) : ""}
      onChange={(e) => {        
        restProps.onChange(dateFormat(e.value, "mm-dd-yyyy"));
        setValue(e.value);
      }} 
      showIcon={showIcon}
      icon={icon}
      placeholder={options.placeHolder}
      monthNavigator
      yearNavigator
      yearRange="2010:2030"      
    />
  );
};

export default PrwcDatePicker;
