import React, { createContext, useContext } from "react";
import { useAxiosContext } from "../../../contextProviderFiles/AxiosContextProvider";
import {
  getVerintActivityUrl,
  editVerintActivityUrl,
  getAgentSkillsUrl,
  getVerintSchedulesUrl, 
  getVerintOvertimeSchedulesUrl,
  getSchedulesApiUrl,
  getOvertimeSchedulesApiUrl,
  getTunisPayrollApiUrl,
  getTunisOvertimePayrollApiUrl,
  getQueuesDropDownUrl,
  getGroupsDropDownUrl,
  getStaffingDataUrl,
  manageGroupUrl,
  deleteGroupUrl 
} from "../constants/VerintConstant";

let operationsAllowed: any = {};
const VerintServiceContext = createContext(operationsAllowed);

export const VerintServiceProvider = (props) => {
  const { axiosObj } = useAxiosContext();
  
  const getVerintActivities = () => {    
    return axiosObj.post(getVerintActivityUrl);
  };
  const getAgentSkills = (request) => {   
    return axiosObj.post(getAgentSkillsUrl,request);
  };

  const editVerintActivities = (request) => {   
    return axiosObj.post(editVerintActivityUrl, request);
  };

  const getVerintSchedules = (request) => {   
    return axiosObj.post(getVerintSchedulesUrl,request);
  };   
  const getVerintOvertimeSchedules = (request) => {   
    return axiosObj.post(getVerintOvertimeSchedulesUrl,request);
  };
  const updateVerintSchedules=(request) =>{
    return axiosObj.post(getSchedulesApiUrl,request);      
  }
  const updateVerintOvertimeSchedules=(request) =>{
    return axiosObj.post(getOvertimeSchedulesApiUrl,request);      
  }
  const InsertTunisPayrollSchedules=(request) =>{
    return axiosObj.post(getTunisPayrollApiUrl,request);      
  } 
  const InsertTunisPayrollOvertimeSchedules=(request) =>{
    return axiosObj.post(getTunisOvertimePayrollApiUrl,request);      
  } 
  const getQueuesForDropDown = (request) => {
    return axiosObj.get(getQueuesDropDownUrl, request);
  };
  const getGroupsForDropDown = (request) => {
    return axiosObj.get(getGroupsDropDownUrl, request);
  };
  const getStaffingData = (request) => {
    return axiosObj.post(getStaffingDataUrl, request);
  };
  const manageGroup = (request) => {
    return axiosObj.post(manageGroupUrl, request);
  };
  const deleteGroup = (request) => {
    return axiosObj.post(deleteGroupUrl, request);
  }; 
  
  operationsAllowed = {    
    getVerintActivities,
    editVerintActivities,
    getAgentSkills,
    getVerintSchedules,    
    getVerintOvertimeSchedules,
    updateVerintSchedules,
    updateVerintOvertimeSchedules,
    InsertTunisPayrollSchedules,
    InsertTunisPayrollOvertimeSchedules ,
    getQueuesForDropDown,
    getGroupsForDropDown,
    getStaffingData,
    manageGroup,
    deleteGroup   
  }; 

  return (
    <VerintServiceContext.Provider value={operationsAllowed}>
      {props.children}
    </VerintServiceContext.Provider>
  );
};

export const useVerintService = () => {
  return useContext(VerintServiceContext);
};
