import React, { createContext, useContext, useState } from "react";
import { useAxiosContext } from "../../../contextProviderFiles/AxiosContextProvider";

import {getForecastUrl, deleteForecastUrl, uploadForecastUrl, getFcstTypeDropDownUrl, 
    getFcstCountryDropDownUrl,getFcstComparisonUrl, getLanguageDropDownUrl, getContactTypeDropDownUrl, 
    getDomainDropDownUrl, getLOBDropDownUrl, getAgentDomainMappingsUrl, verifyDomainMappingUrl, manageDomainMappingUrl,
    getDomainMappingMasterUrl, getUsersAndSupervisorsUrl, getFcstTypeDatesUrl, getFcstDownloadUrl, manageDomainMappingMasterUrl, 
    deleteMappingMasterUrl, deleteADMUrl, getCampaignDropdownsUrl, manageForecastCampaignUrl, getForecastCampaignMasterUrl,
    deleteForecastCampaignUrl, getAllMappingsUrl, saveFilterSettingUrl, getForecastMappingFiltersUrl, deleteForecastFilterUrl,
    getQueuesByCampaignIdUrl, getForecastCampaignValuesUrl, getFilteredCampaignsDropdownUrl, uploadForecastToVerintUrl,
    getFcstBVForDropDownUrl, getCampaignsByGroupIdUrl, forecastProfileSavingUrl, getUserForecastProfileDetailsUrl,
    getForecastProfileIntegrationDetailsUrl, deleteForecastProfileUrl, saveDistributionCurveUrl,
    getUserDistributionCurveDetailsUrl, deleteForecastDistributionCurveUrl, getCampaignDistributionCurvesForUploadUrl} from "../constants/ForecastConstants";
import { forecastQuery, forecastStore } from "../state";
import axios from "axios";
import { useAuthContext } from "./../../../contextProviderFiles/AuthContextProvider";

let operationsAllowed: any = {};
const ForecastServiceContext = createContext(operationsAllowed);

export const ForecastServiceProvider = (props) => {
    const { axiosObj } = useAxiosContext();
    const authInfo = useAuthContext();   
    let token = authInfo.getAccessToken();

    const getAdminMasterActiveTabIndex = () => {
        return forecastQuery.getValue().adminMasterPage.activeTabValue;
    }
    
    const setAdminMasterActiveTabIndex = (activeTabValue: string) => {
        forecastStore.update({adminMasterPage: {activeTabValue: activeTabValue}});
    }

    // const uploadfile = (request) => { 
    //     return axios.post(process.env.REACT_APP_API_URL + uploadForecastUrl, request, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    // };

    const getDetails = (request) => {                
        return axiosObj.post(getForecastUrl, request);
    };

    const getFcstTypeForDropDown = (request) => {
        return axiosObj.get(getFcstTypeDropDownUrl, request);
    };
    const getFcstCountryForDropDown = (request) => {
        return axiosObj.get(getFcstCountryDropDownUrl, request);
    };
    const getFcstBVForDropDown = (request) => {
        return axiosObj.get(getFcstBVForDropDownUrl, request);
    };

    const getFcstTypeDates = (request) => {        
        return axiosObj.post(getFcstTypeDatesUrl, request);
    };
    const getFcstDownloadData = (request) => {
        return axiosObj.post(getFcstDownloadUrl, request);
    }

    const getDomainMappingDetails = (request) => {        
        return axiosObj.post(getAgentDomainMappingsUrl, request);
    };
    
    const getLanguageForDropDown = (request) => {
        return axiosObj.get(getLanguageDropDownUrl, request);
    };

    const getContentTypeForDropDown = (request) => {
        return axiosObj.get(getContactTypeDropDownUrl, request);
    };

    const getDomainForDropDown = (request) => {
        return axiosObj.get(getDomainDropDownUrl, request);
    };

    const getLOBDropDown = (request) => {
        return axiosObj.get(getLOBDropDownUrl, request);
    };

    const deleteForecast =(request) => {        
        return axiosObj.post(deleteForecastUrl, request);
    };

    const uploadForecast =(request) => {                                        
        //return axiosObj.post(uploadForecastUrl, request); 
        
        return axios.post(process.env.REACT_APP_API_URL + uploadForecastUrl, request, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        })
    };

    const getFcstComparison = (request) => {        
        return axiosObj.post(getFcstComparisonUrl, request);
    };

    const manageDomainMapping = (request) => {
        return axiosObj.post(manageDomainMappingUrl, request);
    }

    const verifyDomainMapping = (request) => {
        return axiosObj.post(verifyDomainMappingUrl, request);
    }

    const getUsersAndSupervisors = (request) => {
        return axiosObj.post(getUsersAndSupervisorsUrl,request);
    }

    const getDomainMappingMaster = (request) => {        
        return axiosObj.post(getDomainMappingMasterUrl, request);
    };

    const manageDomainMappingMaster = (request) => {        
        return axiosObj.post(manageDomainMappingMasterUrl, request);
    };

    const deleteMappingMaster = (request) => {        
        return axiosObj.post(deleteMappingMasterUrl, request);
    };

    const deleteAgentDomainMapping = (request) => {        
        return axiosObj.post(deleteADMUrl, request);
    };

    const getCampaignDropdownDetails = () => {
        return axiosObj.get(getCampaignDropdownsUrl);
    }

    const manageForecastCampaign = (request) =>{
        return axiosObj.post(manageForecastCampaignUrl, request);
    };

    const getForecastCampaignMaster = () => {
        return axiosObj.get(getForecastCampaignMasterUrl);
    }

    const deleteForecastCampaign = (request) => {        
        return axiosObj.post(deleteForecastCampaignUrl, request);
    };

    const getAllMappings = () => {
        return axiosObj.get(getAllMappingsUrl);
    };

    const saveFilterSetting = (request) => {                
        return axiosObj.post(saveFilterSettingUrl, request);
    };

    const getForecastMappingFilters = (request) => {
        return axiosObj.post(getForecastMappingFiltersUrl, request);
    };

    const deleteForecastFilter = (request) => {        
        return axiosObj.post(deleteForecastFilterUrl, request);
    };

    const getCampaignsByGroupId = (request) => {        
        return axiosObj.post(getCampaignsByGroupIdUrl, request);
    };

    const getQueuesByCampaignId = (request) => {        
        return axiosObj.post(getQueuesByCampaignIdUrl, request);
    };
    
    const getForecastCampaignValues = (request) => {
        return axiosObj.post(getForecastCampaignValuesUrl, request);
    };

    const getFilteredCampaignsDropdownDetails = () => {
        return axiosObj.get(getFilteredCampaignsDropdownUrl);
    }

    const uploadForecastToVerint = (request) =>{
        return axiosObj.post(uploadForecastToVerintUrl, request);
    }
    
    const forecastProfileSaving = (request) => {
        return axiosObj.post(forecastProfileSavingUrl, request);
    };
    
    const getUserForecastProfileDetails = (request) => {
        return axiosObj.post(getUserForecastProfileDetailsUrl, request);
    };

    const getForecastProfileIntegrationDetails = (request) => {
        return axiosObj.post(getForecastProfileIntegrationDetailsUrl, request);
    };

    const deleteForecastProfile = (request) => {        
        return axiosObj.post(deleteForecastProfileUrl, request);
    };

    const saveDistributionCurve = (request) => {
        return axiosObj.post(saveDistributionCurveUrl, request);
    };

    const getUserDistributionCurveDetails = (request) => {
        return axiosObj.post(getUserDistributionCurveDetailsUrl, request);
    };

    const deleteForecastDistributionCurve = (request) => {
        return axiosObj.post(deleteForecastDistributionCurveUrl, request);
    };

    const getCampaignDistributionCurvesForUpload = (request) => {
        return axiosObj.post(getCampaignDistributionCurvesForUploadUrl, request);
    };
    
    operationsAllowed = {
        getAdminMasterActiveTabIndex,
        setAdminMasterActiveTabIndex,
        getDetails,
        deleteForecast,
        uploadForecast,
        getFcstTypeForDropDown,
        getFcstCountryForDropDown,
        getFcstBVForDropDown,
        getFcstTypeDates,
        getFcstDownloadData,
        getFcstComparison,
        getDomainMappingDetails,
        getLanguageForDropDown,
        getDomainForDropDown,
        getContentTypeForDropDown,
        getLOBDropDown,
        manageDomainMapping,
        verifyDomainMapping,
        getUsersAndSupervisors,
        getDomainMappingMaster,
        manageDomainMappingMaster,
        deleteMappingMaster,
        deleteAgentDomainMapping,
        getCampaignDropdownDetails,
        manageForecastCampaign,
        getForecastCampaignMaster,
        deleteForecastCampaign,
        getAllMappings,
        saveFilterSetting,
        getForecastMappingFilters,
        deleteForecastFilter,
        getQueuesByCampaignId,
        getForecastCampaignValues,
        getFilteredCampaignsDropdownDetails,
        uploadForecastToVerint,
        getCampaignsByGroupId,
        forecastProfileSaving,
        getUserForecastProfileDetails,
        getForecastProfileIntegrationDetails,
        deleteForecastProfile,
        saveDistributionCurve,
        getUserDistributionCurveDetails,
        deleteForecastDistributionCurve,
        getCampaignDistributionCurvesForUpload
    };
    return (
        <ForecastServiceContext.Provider value={operationsAllowed}>
            {props.children}
        </ForecastServiceContext.Provider>
    );
};

export const useForecastService = () => {
    return useContext(ForecastServiceContext);
};