import React from "react";
import { Outlet } from 'react-router-dom'

import { HCPContextProvider } from "./contextProviders/HCPContextProvider";
import { HCPServiceProvider } from "./services/HCPService";

const HCPRoute = () =>{
    return(
      <HCPServiceProvider>
        <HCPContextProvider>
          <Outlet />
        </HCPContextProvider>
      </HCPServiceProvider>
    );
}

export default HCPRoute;