import { RTDConstants } from "../constants/RTDConstants";


interface statusThresholdsType {
    [status: string]: number;
}

export default class Settings{ 

    static second_in_milli = 1000;
    
    static statusThresholds:statusThresholdsType = {
        Lunch: 60, // 60 min
        Break: 15, // 15 min
        Personal: 7, // 7  min
        ACW1: 1, // 1  min
        ACW2: 4 // 4  min
    };

    static getStatusThresholdsTime(time:number){
        return time * 60 * Settings.second_in_milli;
    }
    public static getThreshold = function(agentStatus:any, contactState:any, thresholdTime:any) {
        let statusThresholds = thresholdTime ? thresholdTime : Settings.statusThresholds;
        if (agentStatus === RTDConstants.Phone && contactState === RTDConstants.Ended)
          return Settings.getStatusThresholdsTime(statusThresholds["ACW2"]);
        return Settings.getStatusThresholdsTime(statusThresholds[agentStatus]);
    }

    
    public static getPersonalThreshold(thresholdTime:any, agentStatus:any) {
        return Settings.getStatusThresholdsTime(thresholdTime ? thresholdTime[agentStatus] : Settings.statusThresholds[agentStatus]);
    }
    
    public static getACW1Threshold(agentStatus:any, contactState:any, thresholdTime:any) {
        let statusThresholds = thresholdTime ? thresholdTime : Settings.statusThresholds;
        if (agentStatus ===  RTDConstants.Phone && contactState === RTDConstants.Ended)
            return Settings.getStatusThresholdsTime(statusThresholds["ACW1"]);
    }
}