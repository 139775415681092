import { Query } from '@datorama/akita';
import { DNAState } from './dna.model';
import { dnaStore, DNAStore } from './dna.store';

export class DNAQuery extends Query<DNAState> {
  constructor(protected store: DNAStore) {
    super(store);
  }
}

export const dnaQuery = new DNAQuery(dnaStore);