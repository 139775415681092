import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import React, { forwardRef, useEffect, useState } from 'react';
import { IColumn } from '../../../../../../contracts/IColumn';
import { GetColumnToCustomTemplate, GetColumnToShow, getChangeColumnHeader } from '../../../../services/helper';
import './ChatStatusList.css';
import { MarketHelpersSLAThreshold } from '../../../../services/marketservice';
import { useRTDService } from '../../../../services/RTDService';
import Timer from '../../Timer';

const ChatStatusList = forwardRef((props: any, ref) => {
  const { data, onChangeItem, widgetInfo, globalFilterInfo } = props;
  const rtdService = useRTDService()
  const columns: IColumn[] = [
    {
      id: "QueueName",
      label: "Domain",
      minWidth: 400,
      align: "left",
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "timeZone",
      label: "Timezone",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100,
    },
    {
      id: "Missed",
      label: "Abandoned",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100
    },
    {
      id: "In_Progress",
      label: "In Progress",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100
    },
    {
      id: "Waiting",
      label: "Queue",
      visible: true,
      align: "right",
      sortable: true,
      resizeable: true,
      minWidth: 100
    },
    {
      id: "Completed",
      label: "Handled",
      sortable: true,
      resizeable: true,
      visible: true,
      minWidth: 100
    },
    {
      id: "Offered",
      label: "Offered",
      sortable: true,
      resizeable: true,
      visible: true,
      minWidth: 100
    },
    {
      id: "AWT",
      label: "ASA",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100,
      customTemplate: (e) => `${e.data.AWT?.toFixed()}`
    },
    {
      id: "ACD",
      label: "AHT",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100,
      customTemplate: (e) => `${e.data.ACD?.toFixed()}`
    },
    {
      id: "QTime",
      label: "QTime",
      visible: true,
      sortable: true,
      minWidth: 100,
      resizeable: true,
      customTemplate: (e) => {
        return (
          e.data && <Timer data={e.data} rules={widgetInfo.appliedCFRules} />
        );
      },
      defaultFormat: (e) => {
        return (
          e.data && <Timer data={e.data} />
        );
      }
      //customTemplate: (e) => ChatQTimeCalc(e.data.QTime)
    },
    {
      id: "HandledInSL",
      label: "Handled in SL",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100
    },
    {
      id: "AbandonedInSL",
      label: "Abandoned in SL",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100
    },
    {
      id: "AbandonedRate",
      label: "Aband. Rate",
      sortable: true,
      resizeable: true,
      visible: true,
      minWidth: 100,
      customTemplate: (e) => `${e.data.AbandonedRate?.toFixed(1)} %`,
    },
    // {
    //   id: "ART",
    //   label: "ART",
    //   visible: true,
    //   align: "right",
    //   sortable: true,
    //   resizeable: true,
    //   minWidth: 100
    // },
    // {
    //   id: "AMRT",
    //   label: "AMRT",
    //   sortable: true,
    //   resizeable: true,
    //   visible: true,
    //   minWidth: 100
    // },
    {
      id: "ServiceLevel",
      label: "SL",
      sortable: true,
      resizeable: true,
      visible: true,
      minWidth: 100,
      customTemplate: (e) => `${e.data.ServiceLevel?.toFixed(1)} %`,
      defaultFormat: (e) => {
        let value = e.data.ServiceLevel;
        return (
          <div className={MarketHelpersSLAThreshold(value)}>
            {value?.toFixed(1)}%
          </div>
        );
      }
    } 
  ]
  const [columnToShow, setColumnToShow] = useState<any>(columns);
  //const [formattedColumns, setFormattedColumns] = useState<any>(columns);
  const [multiSortMeta, setMultiSortMeta] = useState<any>([{field: "name", order:1}]);
  
  useEffect(()=>{
      let multiSortMeta = widgetInfo.multiSortMeta !== undefined && widgetInfo.multiSortMeta !== null ? JSON.parse(widgetInfo.multiSortMeta) : [{field: "name", order:1}]
      setMultiSortMeta(multiSortMeta)
  },[widgetInfo.multiSortMeta])

  useEffect(() => {
    let updatedColumns = GetColumnToCustomTemplate(rtdService,columns, widgetInfo.appliedCFRules);
    //setFormattedColumns(updatedColumns)
    let filterColumns = GetColumnToShow(updatedColumns, widgetInfo.selectedColumns);
    let groupColumns = getChangeColumnHeader(widgetInfo,filterColumns)
    setColumnToShow(groupColumns)
  }, [widgetInfo.groupField,widgetInfo.selectedColumns,widgetInfo.appliedCFRules])

  const onSaveColReorder = (item) => {
      let newDragIndex = item.dragIndex - 1;
      let newDropIndex = item.dropIndex - 1;
      var element = columnToShow[newDragIndex];
      columnToShow.splice(newDragIndex, 1);
      columnToShow.splice(newDropIndex, 0, element);

      let reorderColumns = columnToShow.map((item) => {
          return item.id;
      });
      let updatedWidgetInfo = {...widgetInfo, selectedColumns: JSON.stringify(reorderColumns)}
      onChangeItem(updatedWidgetInfo)
  };

  const onSort = (e) => {
      setMultiSortMeta(e.multiSortMeta)
      let updatedWidgetInfo = {...widgetInfo, "multiSortMeta": JSON.stringify(e.multiSortMeta)}
      onChangeItem(updatedWidgetInfo)
  };

    return (
      <div className="AgentMarketlist">
          <TreeTable
              value={data}
              globalFilter={globalFilterInfo !== undefined ? globalFilterInfo['global'].value : ""}
              filterMode = {globalFilterInfo !== undefined ? globalFilterInfo['global'].matchMode : ""}              
              onSort={onSort}
              sortMode="multiple"
              multiSortMeta={multiSortMeta}
              resizableColumns
              reorderableColumns
              removableSort
              columnResizeMode="expand"
              onColReorder={onSaveColReorder}
              scrollable
              scrollHeight={widgetInfo.dynamicHeight}
          >
          <Column expander style={{ width: "4em", justifyContent: "center" }} />
          {columnToShow.map((column, index) => {
              return (
              <Column
                  key={index}
                  hidden={!column.visible}
                  sortable={column.sortable !== undefined ? column.sortable : false}
                  resizeable={
                  column.resizeable !== undefined ? column.resizeable : false
                  }
                  field={column.id}
                  header={column.label}
                  body={column.customTemplate}
              ></Column>
              );
          })}
          </TreeTable>
      </div>
      );
})

export default ChatStatusList;
