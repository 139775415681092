import { format } from "date-fns";
import { Calendar } from "primereact/calendar";

type PrwcTimePickerProps = {
  value: string;
  onChange: (date?: string) => void;
  options?: any;
};

const PrwcTimePicker = (props: PrwcTimePickerProps) => {
  const { value, onChange, options } = props;
  const timeFormat = options["format"] ?? "";
  return (
    <div>
      <Calendar
        value={ value !== undefined ? new Date(value)  : new Date() }
        onChange={(e) => {
          let newDate = format(e.target.value as Date,timeFormat)
          onChange(newDate)
        }}
        timeOnly
        hourFormat="12"
      />
    </div>
  );
};

export default PrwcTimePicker;
