import React,{ createContext, useContext } from "react";
import { useAxiosContext } from "../../../contextProviderFiles/AxiosContextProvider";
import { getTimeOffsUrl, uploadTimeOffsUrl } from '../constants/TimeOffConstant'

let timeOffsOperations: any = {};
const TimeOffServiceContext = createContext(timeOffsOperations);

export const TimeOffServiceProvider = (props) => {
    const { axiosObj } = useAxiosContext();    

    const getTimeOffs = (request) => {
        return axiosObj.post(getTimeOffsUrl,request);

    };

    const uploadTimeOffs = (request) => {        
        return axiosObj.post(uploadTimeOffsUrl, request);
    };
     
    timeOffsOperations = {
        getTimeOffs,
        uploadTimeOffs
    };
    return (
        <TimeOffServiceContext.Provider value={timeOffsOperations}>
            {props.children}
        </TimeOffServiceContext.Provider>
    );
}

export const useTimeOffService = () => {
    return useContext(TimeOffServiceContext);
}