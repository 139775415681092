import { createContext, useContext, useState } from "react";

let verintInfo: any = {};

const verintContext = createContext(verintInfo);

export const VerintContextProvider = (props: any) => {
    const [allVerintActivityRequestData, setAllVerintActivityRequestData] = useState<any>([]); 
    const [ allAgentSkillsRequestData, setAllAgentSkillsRequestData ] = useState<any>([]); 
    const [ allSchedulesRequestData, setSchedulesRequestData ] = useState<any>([]); 
    const [ allOvertimeSchedulesRequestData, setOvertimeSchedulesRequestData ] = useState<any>([]); 
    const [ allPayrollRequestData, setPayrollRequestData ] = useState<any>([]); 
    const [ staffingRequestData, setStaffingRequestData ] = useState<any>([]); 
    
    verintInfo = {      
        allVerintActivityRequestData,
        setAllVerintActivityRequestData,  
        allAgentSkillsRequestData,  
        setAllAgentSkillsRequestData,
        allSchedulesRequestData,
        setSchedulesRequestData,
        allOvertimeSchedulesRequestData,
        setOvertimeSchedulesRequestData,
        allPayrollRequestData,
        setPayrollRequestData,
        staffingRequestData,
        setStaffingRequestData
    }
    return (<>
        <verintContext.Provider value={verintInfo}>
            {props.children}
        </verintContext.Provider>
    </>);
}

export const useVerintContext = () => {
    return useContext(verintContext);
}