export interface AdminMasterPage {
    activeTabValue: string;
}
  
export interface ForecastState {
    adminMasterPage: AdminMasterPage
}

export function createInitialForecastState(): ForecastState {
    return {
        adminMasterPage: {
            activeTabValue: "1"
        }
    }
}