import { Box, Link } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { FC, useState } from "react";
import DynamicFeedOutlinedIcon from "@mui/icons-material/DynamicFeedOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { IColumn } from "../../../../../contracts/IColumn";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./RolePrivilegeList.css";
import { useUserContext } from "../../../../../contextProviderFiles/UserContextProvider";
import NxModalDialog from "../../../../../components/NxModalDialog/NxModalDialog";
import { LoadingButton } from "@mui/lab";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const RolePrivilegeList = (props) => {
  const { loader } = useUserContext();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDefaultDialogOpen, setIsDefaultDialogOpen] = useState(false);
  const [currentData, setCurrentData] = useState<any>([]);

  const { data, onEdit, onDeleteChange, isDeleteLoader, onDefaultRoleChange } =
    props;

  const onDelete = (data) => {
    setCurrentData(data);
    setDeleteDialogOpen(true);
  };

  function onSetAsDefault(data) {
    setCurrentData(data);
    setIsDefaultDialogOpen(true);
  }

  const columns: IColumn[] = [
    {
      id: "name",
      label: "Role Name",
      minWidth: 100,
      visible: true,
    },
    {
      id: "isDefault",
      label: "Default Role",
      minWidth: 50,
      visible: true,
      customTemplate: (value: any) => {
        return <Box>{value.isDefault ? "Yes" : "No"}</Box>;
      },
    },
    {
      id: "description",
      label: "Description",
      minWidth: 200,
      visible: true,
    },
    {
      id: "center",
      label: "Center",
      minWidth: 100,
      visible: true,
    },
    {
      id: "roleId",
      label: "Action",
      minWidth: 100,
      align: "right",
      visible: true,
      customTemplate: (value: any) => {
        return (
          <Box className="action-buttons">
            <Link
              underline="none"
              onClick={() => onEdit(value)}
              variant="button"
            >
              <Tooltip title={"Edit"} placement="top" arrow>
                <DriveFileRenameOutlineOutlinedIcon />
              </Tooltip>
            </Link>
            <Link
              underline="none"
              onClick={() => onEdit(value, true)}
              variant="button"
            >
              <Tooltip title={"Create From Existing"} placement="top" arrow>
                <DynamicFeedOutlinedIcon />
              </Tooltip>
            </Link>
            <Link
              underline="none"
              onClick={() => onDelete(value)}
              variant="button"
            >
              <Tooltip title={"Delete"} placement="top" arrow>
                <DeleteOutlineOutlinedIcon sx={{ color: "rgb(239, 83, 80)" }} />
              </Tooltip>
            </Link>
            <Link
              underline="none"
              onClick={() => onSetAsDefault(value)}
              variant="button"
            >
              <Tooltip title={"Set as Default"} placement="top" arrow>
                {value.isDefault ? (
                  <CheckCircleIcon />
                ) : (
                  <CheckCircleOutlineIcon />
                )}
              </Tooltip>
            </Link>
          </Box>
        );
      },
    },
  ];

  return (
    <Box className="RolePrivilegeList">
      <DataTable
        value={data}
        paginator
        scrollable
        scrollHeight="470px"
        rows={10}
        rowsPerPageOptions={[10, 25, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
      >
        {columns.map((column, index) => {
          return (
            <Column
              key={index}
              sortable={column.sortable !== undefined ? column.sortable : false}
              resizeable={
                column.resizeable !== undefined ? column.resizeable : false
              }
              field={column.id}
              header={column.label}
              body={column.customTemplate}
              style={{ width: "15%" }}
            ></Column>
          );
        })}
      </DataTable>

      <NxModalDialog
        id="deleteRole"
        titleName="Delete Role?"
        bodyContent={
          <Box className="text-popup">
            Are you sure you want to delete role <br />
            <strong style={{ textTransform: "capitalize" }}>
              {currentData.roleName}
            </strong>{" "}
            Request?
          </Box>
        }
        footerContent={
          <LoadingButton
            variant="contained"
            color="error"
            loading={isDeleteLoader}
            className="footerbutton"
            sx={{
              py: 1,
              px: 2,
              fontSize: "15px",
              letterSpacing: "0.05em",
              lineHeight: "22px",
              my: 1,
              mx: 1,
            }}
            autoFocus
            onClick={() => {
              setDeleteDialogOpen(false);
              onDeleteChange(currentData);
            }}
          >
            Delete
          </LoadingButton>
        }
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        open={deleteDialogOpen}
        titleContent={<></>}
      />
      <NxModalDialog
        id="defaultRole"
        titleName="Confirmation"
        bodyContent={
          <Box className="text-popup">
            Are you sure you want to{" "}
            {!currentData.isDefault ? "assign" : "unassign"} '
            <strong>{currentData.name}</strong>' role as Default Access role ?
          </Box>
        }
        footerContent={
          <LoadingButton
            variant="contained"
            color="error"
            loading={isDeleteLoader}
            className="footerbutton"
            sx={{
              py: 1,
              px: 2,
              fontSize: "15px",
              letterSpacing: "0.05em",
              lineHeight: "22px",
              my: 1,
              mx: 1,
            }}
            autoFocus
            onClick={() => {
              setIsDefaultDialogOpen(false);
              onDefaultRoleChange(currentData);
            }}
          >
            Confirm
          </LoadingButton>
        }
        onClose={() => {
          setIsDefaultDialogOpen(false);
        }}
        open={isDefaultDialogOpen}
        titleContent={<></>}
      />
    </Box>
  );
};

export default RolePrivilegeList;
