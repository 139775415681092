import DialogContent from "@mui/material/DialogContent";
import { Sidebar } from "primereact/sidebar";
import * as React from "react";
import { useState } from "react";
import "./PrwcSidebar.css";

const PrwcSidebar = (props: any) => {
  const {
    isSideBarVisible,
    headerTitle,
    position = "right",
    showCloseIcon = false,
    className,
    enableFullScreen = false,
    onHide,
    dismissable = false,
    headerButtons,
  } = props;

  const [isFullScreenExpand, setIsFullScreenExpand] = useState(false);

  const handleOnHide = () => {
    onHide(false);
    // setIsFullScreenExpand(false);
  };

  return (
    <div>
      <Sidebar
        visible={isSideBarVisible}
        position={position}
        className={`${isFullScreenExpand ? null : className} p-0`}
        showCloseIcon={showCloseIcon}
        onHide={handleOnHide}
        blockScroll={true}
        dismissable={dismissable}
        style={{ padding: "0" }}
      >
        <h3>{headerTitle}</h3>
        <div>{props.children}</div>
      </Sidebar>
    </div>
  );
};

export default PrwcSidebar;
