import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './BarChart.css';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const BarChart = forwardRef((props: any, ref: any) => {
  const { data, categories } = props;
  const [chart, setChart] = useState<any>(undefined)

  // Create chart configuration
  const chartConfig = {
    chart: {
      type: 'bar'
    },
    title: {
      text: undefined
    },
    credits: {
      enabled: false,
    },
    legend: {
      reversed: true
    },
    xAxis: {
      categories: categories
    },
    yAxis: {
      min: 0,
      allowDecimals: false,
      title: {
        text: 'Ages'
      }
    },
    plotOptions: {
      bar: {
        stacking: 'normal',
        dataLabels: {
          enabled: false
        }
      }
    },
    series: { 
      data: data
    }
  }

  useImperativeHandle(ref, () => ({
    reflowChart(){
      if (chart) {
        chart.reflow(false);
      }
    }
  }));

  return (
    <div id="testChart" className="LineChart">
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { width: "100%", height: "100%" } }}
        options={chartConfig}
        callback={chart => setChart(chart)}
      />
    </div>
  );
})

export default BarChart;

