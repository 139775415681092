import React, { createContext, useContext } from "react";
import { useAxiosContext } from "../../../contextProviderFiles/AxiosContextProvider";
import { getWeeksUrl,getheadcounthistoricaldetailsUrl,getHCPAlertApplyUrl,manageMappingPagesUrl, getHeadCountDetailUrl, getHeadCountAlertDetailUrl,getDropdownsUrl, getHCdetailsbyIdUrl,deleteMappingDetailsUrl, getWorkGroupUrl, manageHCAttributesUrl, getAttributesListUrl,getMasterPagesListUrl,manageMasterPagesUrl,deleteMasterPagesDetailsUrl,getMappingDetailsUrl,getfilterCriteriaDataUrl,saveFilterSettingUrl,getFilterSettingsUrl,deleteFilterSettingUrl,getCostCenterUrl, getAlertDetailsUrl,applyCustomUpdateAlertUrl,getAlertFiltersUrl,getalertsfilterCriteriaDataUrl } from "../constants/HCPConstant";
import { hcpQuery, hcpStore } from "../state"

let operationsAllowed: any = {};
const HCPServiceContext = createContext(operationsAllowed);

export const HCPServiceProvider = (props) => {
  const { axiosObj } = useAxiosContext();

  const getAdminMasterActiveTabIndex = () => {
    return hcpQuery.getValue().adminMasterPage.activeTabValue;
  }

  const setAdminMasterActiveTabIndex = (activeTabValue: string) => {
    hcpStore.update({ adminMasterPage: { activeTabValue: activeTabValue } });
  }

  const getHeadCountDetail = (request) => {
    return axiosObj.post(getHeadCountDetailUrl, request);
  };
  const getHeadCountAlertsDetail = (request) => {
    return axiosObj.post(getHeadCountAlertDetailUrl, request);
  };
  const getHCPAlertApply = (request) => {
    return axiosObj.post(getHCPAlertApplyUrl, request);
  };

  const getHCDetailsById = (request) => {
    return axiosObj.post(getHCdetailsbyIdUrl, request);
  };
  const manageHCAttributes = (request) => {
    return axiosObj.post(manageHCAttributesUrl, request);
  };
  const getAttributes = (request) => {
    return axiosObj.post(getAttributesListUrl, request);
  };
  const getWorkGroups = (request) => {
    return axiosObj.post(getWorkGroupUrl, request);
  };
  const getMasterPagesDetails = (request) => {
    return axiosObj.post(getMasterPagesListUrl, request);
  };
  const manageMasterPages = (request) => {
    return axiosObj.post(manageMasterPagesUrl, request);
  };  
  const deleteMasterPagesDetails = (request) => {
    return axiosObj.post(deleteMasterPagesDetailsUrl, request);
  };
  const getMappingDetails = (request) => {
    return axiosObj.post(getMappingDetailsUrl, request);
  };
  const deleteMappingDetails = (request) => {
    return axiosObj.post(deleteMappingDetailsUrl, request);
  };
  const getDropdownDetails = async (request) => {
    return axiosObj.post(getDropdownsUrl, request);
  };
  const manageMappingPages = (request) => {
    return axiosObj.post(manageMappingPagesUrl, request);
  };  
  const getfilterCriteriaData = () => {
    return axiosObj.get(getfilterCriteriaDataUrl);
  }; 
  const saveFilterSetting = (request) => {
    return axiosObj.post(saveFilterSettingUrl, request);
  };
  const getFilterSettings = (request) => {
    return axiosObj.post(getFilterSettingsUrl, request);
  };
  const deleteFilterSetting = (request) => {
    return axiosObj.post(deleteFilterSettingUrl, request);
  };
  const getCostCenters = (request) => {
    return axiosObj.post(getCostCenterUrl, request);
  };
  const getAlertDetails = (request) => {
    return axiosObj.post(getAlertDetailsUrl,request);
  };
  const getWeeks = (request) => {
    return axiosObj.post(getWeeksUrl, request);
  };
  const applyCustomUpdateAlert = (request) => {
    return axiosObj.post(applyCustomUpdateAlertUrl, request);
  };
  const getheadcounthistoricaldetails = (request) => {
    return axiosObj.post(getheadcounthistoricaldetailsUrl, request);
  };
  const getAlertFilters = (request) => {
    return axiosObj.post(getAlertFiltersUrl, request);
  }; 
  const getAlertsFilterCriteriaData = () => {
    return axiosObj.get(getalertsfilterCriteriaDataUrl);
  }; 

  operationsAllowed = {
    getAdminMasterActiveTabIndex,
    setAdminMasterActiveTabIndex,
    getHeadCountDetail,
    getHeadCountAlertsDetail,
    getHCPAlertApply,
    getHCDetailsById,
    manageHCAttributes,
    getAttributes,
    getWorkGroups,
    getMasterPagesDetails,
    manageMasterPages,    
    deleteMasterPagesDetails,
    getMappingDetails,
    deleteMappingDetails,
    getDropdownDetails,
    manageMappingPages,
    getfilterCriteriaData,
    saveFilterSetting,
    getFilterSettings,
    deleteFilterSetting,
    getCostCenters,
    getAlertDetails,
    applyCustomUpdateAlert,
    getWeeks,
    getheadcounthistoricaldetails,
    getAlertFilters,
    getAlertsFilterCriteriaData
  }

  return (
    <HCPServiceContext.Provider value={operationsAllowed}>
      {props.children}
    </HCPServiceContext.Provider>
  );
};

export const useHCPService = () => {
  return useContext(HCPServiceContext);
};
