import { Store, StoreConfig } from '@datorama/akita'
import { IUserBaseDetails } from '../contracts/IUserBaseDetails';
import { createInitialSessionState, createSession, SessionState } from "./session.model";

@StoreConfig({ name: 'session', resettable: true})
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialSessionState());
  }

  setLoggedUserInfo(data: IUserBaseDetails) {
    const user = createSession(data);
    this.update({loggedInUserInfo: user});
  }

  setAgentProfileData(data) {
    this.update({agentProfileData: data});
  }
    
}

export const sessionStore = new SessionStore();