import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import React, { FC, forwardRef, useEffect, useState } from 'react';
import { IColumn } from '../../../../../../contracts/IColumn';
import { GetColumnToCustomTemplate, GetColumnToShow, getChangeColumnHeader } from '../../../../services/helper';
import './ChatCaseAgeList.css';
import { useRTDService } from '../../../../services/RTDService';


const ChatCaseAgeList = forwardRef((props: any, ref) => {
  const { data, onChangeItem, widgetInfo, globalFilterInfo } = props;
  const rtdService = useRTDService()

  const columns: IColumn[] = [
      {
        id: "QueueName",
        label: "Domain",
        minWidth: 400,
        align: "left",
        visible: true,
        sortable: true,
        resizeable: true
      },      
      {
        id: "0-30",
        label: "0-30",
        visible: true,
        align: "center",
        sortable: true,
        resizeable: true,
        minWidth: 100
      },
      {
        id: "30-60",
        label: "30-60",
        visible: true,
        align: "right",
        sortable: true,
        resizeable: true,
        minWidth: 100
      },
      {
        id: "60-90",
        label: "60-90",
        visible: true,
        align: "right",
        sortable: true,
        resizeable: true,
        minWidth: 100
      },
      {
        id: "90-120",
        label: "90-120",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100
      },
      {
        id: ">120",
        label: ">120",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100
      },
      {
        id: "Total",
        label: "Total",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100
      }
  ]
  const [columnToShow, setColumnToShow] = useState<any>(columns);
  //const [formattedColumns, setFormattedColumns] = useState<any>(columns);
  const [multiSortMeta, setMultiSortMeta] = useState<any>([{field: "name", order:1}]);
  
  useEffect(()=>{
      let multiSortMeta = widgetInfo.multiSortMeta !== undefined && widgetInfo.multiSortMeta !== null ? JSON.parse(widgetInfo.multiSortMeta) : [{field: "name", order:1}]
      setMultiSortMeta(multiSortMeta)
  },[widgetInfo.multiSortMeta])

  useEffect(() => {
    let updatedColumns = GetColumnToCustomTemplate(rtdService,columns, widgetInfo.appliedCFRules);
    //setFormattedColumns(updatedColumns)
    let filterColumns = GetColumnToShow(updatedColumns, widgetInfo.selectedColumns)
    let groupColumns = getChangeColumnHeader(widgetInfo,filterColumns)
    setColumnToShow(groupColumns)
  }, [widgetInfo.groupField,widgetInfo.selectedColumns,widgetInfo.appliedCFRules])

const onSaveColReorder = (item) => {
  let newDragIndex = item.dragIndex - 1;
  let newDropIndex = item.dropIndex - 1;
  var element = columnToShow[newDragIndex];
  columnToShow.splice(newDragIndex, 1);
  columnToShow.splice(newDropIndex, 0, element);

  let reorderColumns = columnToShow.map((item) => {
      return item.id;
  });
  let updatedWidgetInfo = {...widgetInfo, selectedColumns: JSON.stringify(reorderColumns)}
  onChangeItem(updatedWidgetInfo)
};

const onSort = (e) => {
  setMultiSortMeta(e.multiSortMeta)
  let updatedWidgetInfo = {...widgetInfo, "multiSortMeta": JSON.stringify(e.multiSortMeta)}
  onChangeItem(updatedWidgetInfo)
};

return (
  <div className="AgentMarketlist">
      <TreeTable
          value={data}
          globalFilter={globalFilterInfo !== undefined ? globalFilterInfo['global'].value : ""}
          filterMode = {globalFilterInfo !== undefined ? globalFilterInfo['global'].matchMode : ""} 
          onSort={onSort}
          sortMode="multiple"
          multiSortMeta={multiSortMeta}
          resizableColumns
          removableSort
          reorderableColumns
          columnResizeMode="expand"
          onColReorder={onSaveColReorder}
          scrollable
          scrollHeight={widgetInfo.dynamicHeight}
      >
      <Column expander style={{ width: "4em", justifyContent: "center" }} />
      {columnToShow.map((column, index) => {
          return (
          <Column
              key={index}
              hidden={!column.visible}
              sortable={column.sortable !== undefined ? column.sortable : false}
              resizeable={
              column.resizeable !== undefined ? column.resizeable : false
              }
              field={column.id}
              header={column.label}
              body={column.customTemplate}
          ></Column>
          );
      })}
      </TreeTable>
  </div>
  );
})

export default ChatCaseAgeList;
