import { sessionQuery } from "../../../state"
import { AgentName, ConditionOprators, Domain, HourFilters, WidgetComponentConstants } from "../constants/RTDConstants"

export function GetColumnToShow(defaultColumn: any, selectedColumn: any) {
    let columnToShow: any[] = []
    let parseSelectedColumn = selectedColumn !== undefined ? JSON.parse(selectedColumn) : null
    if (parseSelectedColumn !== null && parseSelectedColumn.length > 0) {
        parseSelectedColumn.map((item: any) => {
            let filter = defaultColumn.filter(c => { return c.id === item })

            if (filter.length !== 0) {
                columnToShow.push(filter[0])
            }
        })
    }
    else {
        columnToShow = defaultColumn
    }

    return columnToShow;
}

export function GetColumnToCustomTemplate(rtdService, columns: any, rules: any) {
    return columns.map((column) => {
        if (checkUndefinedAndNull(rules)) {
            if (rules.length > 0) {
                let format = (rowData) => {
                    return rtdService.applyConditionalFormating(rules, rowData, column)
                }
                return { ...column, customTemplate: format }
            } else {
                if (column.defaultFormat !== undefined)
                    return { ...column, customTemplate: (obj) => column.defaultFormat(obj) }
                else
                    return column
            }
        }
        return column;
    });
}

export const getChangeColumnHeader = (widgetInfo, columns: any = undefined, changeColumnField:any = 'QueueName') => {
    let currentViewInfo: any = WidgetComponentConstants.find(a => a.widgetId === widgetInfo.widgetType)
    let currentView = currentViewInfo?.items?.find(item => item.id === widgetInfo.viewItemId)
    let currentViewType = currentView?.viewTypes.find(item => item.id === widgetInfo.viewTypeId)
    let groupItems = currentViewType?.groupItems !== undefined ? currentViewType?.groupItems : []
    let selectedGroup = groupItems.length > 0 ? groupItems.find((a) => a.value === widgetInfo.groupField) : { name : Domain }
    let groupName = selectedGroup !== undefined ? selectedGroup.name : Domain

    columns = columns !== undefined ? columns : currentView.defaultColumns  
    let updatedColumnToShow = columns.map((col) => {
        if (col.id === changeColumnField) {
            return { ...col, label: groupName };
        }
        return col;
    })

    return updatedColumnToShow
}

export const getMarketGroupName = (widgetInfo) => {
    let currentViewInfo: any = WidgetComponentConstants.find(a => a.widgetId === widgetInfo.widgetType)
    let currentView = currentViewInfo?.items?.find(item => item.id === widgetInfo.viewItemId)
    let currentViewType = currentView?.viewTypes.find(item => item.id === widgetInfo.viewTypeId)
    let groupItems = currentViewType?.groupItems !== undefined ? currentViewType?.groupItems : []
    let selectedGroup = groupItems.length > 0 ? groupItems.find((a) => a.value === widgetInfo.groupField) : Domain
    let groupName = selectedGroup !== undefined ? selectedGroup.name : Domain

    return groupName
}

export const checkUndefinedAndNull = (obj) => {
    return obj !== undefined && obj !== null
}

export const checkUndefinedAndNullEmpty = (obj) => {
    return obj !== undefined && obj !== null && obj !== ""
}
export const checkUndefinedAndNullLength = (obj) => {
    return obj !== undefined && obj !== null && obj.length > 0
}

export const isJSONParsable = (object) => {
    try {
        JSON.parse(object)
        return true;
    } catch (error) {
        return false;
    }
}

export const parseJSONToObject = (object) => {
    if (checkUndefinedAndNull(object)) {
        try {
            return JSON.parse(object)
        } catch (error) {
            return object
        }
    }
    else {
        return []
    }
}

export const splitArrayByColumn = (array, column) => {
    return array.reduce((result, item) => {
        const value = item[column];
        if (!result[value]) {
            result[value] = [];
        }
        result[value].push(item);
        return result;
    }, {});
}

export const filterByAdvancedConditions = ((responseData, conditions, agentFiltersCache) => {

    return responseData.filter((item) => {
        let conditionsMatch = true; let columnValue;

        conditions.forEach((filter, index) => {
            const logicalOperator = filter.logicalOperator;

            const col = filter.conditionColumn;
            if ((checkUndefinedAndNullLength(col) && (col === 'TeamLead' || col === 'Organization'))) {
                let agentFiltredData = agentFiltersCache.get('agentsData')
                if (checkUndefinedAndNullLength(agentFiltredData)) {
                    agentFiltredData = applyTeamLeadOrganizationFilter(agentFiltredData, filter, col);
                    agentFiltredData = agentFiltredData.map(item => item.fullName)
                    responseData = responseData.filter(item => agentFiltredData.includes(item.FirstName + ' ' + item.LastName));
                    return responseData;
                }
            }
            else {
                if (col === AgentName)
                    columnValue = `${item.FirstName} ${item.LastName}`.trim();
                else
                    columnValue = item[col];
                let filterResult = false;
                switch (filter.operator) {
                    case "startwith":
                        filterResult = checkUndefinedAndNull(columnValue) && columnValue.toLowerCase().startsWith(filter.value.toLowerCase());
                        break;
                    case "endwith":
                        filterResult = checkUndefinedAndNull(columnValue) && columnValue.toLowerCase().endsWith(filter.value.toLowerCase());
                        break;
                    case "equals":
                        filterResult = checkUndefinedAndNull(columnValue) && columnValue.toLowerCase() === filter.value.toLowerCase();
                        break;
                    case "notequals":
                        filterResult = checkUndefinedAndNull(columnValue) && columnValue.toLowerCase() !== filter.value.toLowerCase();
                        break;
                    case "contains":
                        filterResult = checkUndefinedAndNull(columnValue) && columnValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case "notContains":
                        filterResult = checkUndefinedAndNull(columnValue) && !columnValue.toLowerCase().includes(filter.value).toLowerCase();
                        break;
                }
                if (!checkUndefinedAndNull(logicalOperator))
                    conditionsMatch = conditionsMatch && filterResult;
                else {
                    if (logicalOperator === "OR") {

                        conditionsMatch = conditionsMatch || filterResult;
                    } else {
                        conditionsMatch = conditionsMatch && filterResult;
                    }
                }
            }
        });
        return conditionsMatch;
    });
})
function applyTeamLeadOrganizationFilter(agentFiltredData, filter, col) {
    return agentFiltredData.filter(item => {
        switch (filter.operator) {
            case "startwith":
                if (col === 'TeamLead') {
                    return filter.value.startsWith(item.teamLeadName) || filter.value.startsWith(item.managerName);
                } else {
                    return filter.value.startsWith(item.organizationName);
                }
            case "endwith":
                if (col === 'TeamLead') {
                    return filter.value.endsWith(item.teamLeadName) || filter.value.endsWith(item.managerName);
                } else {
                    return filter.value.endsWith(item.organizationName);
                }
            case "equals":
                if (col === 'TeamLead') {
                    return filter.value === item.teamLeadName || filter.value === item.managerName;
                } else {
                    return filter.value === item.organizationName;
                }
            case "notequals":
                if (col === 'TeamLead') {
                    return filter.value !== item.teamLeadName || filter.value !== item.managerName;
                } else {
                    return filter.value !== item.organizationName;
                }
            case "contains":
                if (col === 'TeamLead') {
                    return filter.value.includes(item.teamLeadName) || filter.value.includes(item.managerName);
                } else {
                    return filter.value.includes(item.organizationName);
                }
            case "notContains":
                if (col === 'TeamLead') {
                    return !filter.value.includes(item.teamLeadName) || !filter.value.includes(item.managerName);
                } else {
                    return !filter.value.includes(item.organizationName);
                }
            default:
                if (col === 'TeamLead') {
                    return filter.value.includes(item.teamLeadName) || filter.value.includes(item.managerName);
                } else {
                    return filter.value.includes(item.organizationName);
                }
        }
    });
}
export const filterByMarket = ((responseData, filterObj) => {
    if (checkUndefinedAndNullLength(filterObj.domains))
        responseData = responseData.filter(item => filterObj.domains.map(item => item.value).includes(item.Domain));
    if (checkUndefinedAndNullLength(filterObj.queues))
        responseData = responseData.filter(item => filterObj.queues.map(item => item.value).includes(item.QueueName));
    if (checkUndefinedAndNullLength(filterObj.forecastGroups))
        responseData = responseData.filter(item => filterObj.forecastGroups.map(item => item.value).includes(item.ForecastGroup));
    if (checkUndefinedAndNullLength(filterObj.verticals))
        responseData = responseData.filter(item => filterObj.verticals.map(item => item.value).includes(item.BusinessVertical));
    if (checkUndefinedAndNullLength(filterObj.planingEntities))
        responseData = responseData.filter(item => filterObj.planingEntities.map(item => item.value).includes(item.PlaningEntity));
    if (checkUndefinedAndNullLength(filterObj.hierarchyLevel1s))
        responseData = responseData.filter(item => filterObj.hierarchyLevel1s.map(item => item.value).includes(item.HierarchyL1));
    if (checkUndefinedAndNullLength(filterObj.emailAgeStatuses)){
        let selectedStatuses =  filterObj.emailAgeStatuses.map(a=>a.value)
        responseData = responseData.filter(item => item.AgeStatus === undefined || selectedStatuses.includes(item.AgeStatus))
        // responseData = responseData.map(a => {
        //     let filterItems = a.items.filter(a=> a.AgeStatus === undefined || filterObj.emailAgeStatuses.map(a=>a.value).includes(a.AgeStatus))
        //     return {...a, 'items': filterItems}
        // })   
    }
        
    if (checkUndefinedAndNull(filterObj.advanceFilters)) {
        let conditions = filterObj.advanceFilters;
        responseData = applyAdvanceFilters(responseData, conditions);
    }
    return responseData;
})

export const applyAdvanceFilters = (sourceData, conditions) => {
    let filteredData = [...sourceData]; // Create a copy of sourceData

    filteredData = filteredData.filter(item => {
        let result = false
        if(conditions.length > 1) {
            for(let i = 0; i < conditions.length - 1 ; i++){
                let conditionColumn = conditions[i].conditionColumn;
                let operator = conditions[i].operator; 
                let value = conditions[i].value;
                let logicalOperator = conditions[i+1]?.logicalOperator
                let nextConditionColumn = conditions[i+1]?.conditionColumn
                let nextOperator = conditions[i+1]?.operator
                let nextValue = conditions[i+1]?.value

                let columnValue = conditionColumn === AgentName ? `${item.FirstName} ${item.LastName}`.trim() : item[conditionColumn];
                if(checkUndefinedAndNull(nextConditionColumn))
                {
                    let nextColumnValue = nextConditionColumn === AgentName ? `${item.FirstName} ${item.LastName}`.trim() : item[nextConditionColumn]
                    
                    if(logicalOperator === ConditionOprators.OR) {
                        if(checkUndefinedAndNull(conditions[i].logicalOperator)){
                            result = result || applyOperator(nextColumnValue, nextOperator, nextValue)
                        } else {
                            result = applyOperator(columnValue, operator, value) || applyOperator(nextColumnValue, nextOperator, nextValue)
                        } 
                    } else if (logicalOperator === ConditionOprators.AND){
                        //console.log(applyOperator(columnValue, operator, value))
                        if(checkUndefinedAndNull(conditions[i].logicalOperator)){
                            result = result && applyOperator(nextColumnValue, nextOperator, nextValue)
                        } else {
                            result = applyOperator(columnValue, operator, value) && applyOperator(nextColumnValue, nextOperator, nextValue)
                        }
                    }
                }
            }
        } else {
            conditions.forEach(condition => {
                const { conditionColumn, operator, value } = condition
                let columnValue = conditionColumn === AgentName ? `${item.FirstName} ${item.LastName}`.trim() : item[conditionColumn]
                result = applyOperator(columnValue, operator, value)
            })
        }
        return result
    })
        
    return filteredData
};

// Helper function to apply individual operators
const applyOperator = (columnValue, operator, value) => {
    switch (operator) {
        case 'startwith':
            return columnValue.toLowerCase().startsWith(value.toLowerCase());
        case 'endwith':
            return columnValue.toLowerCase().endsWith(value.toLowerCase());
        case 'equals':
            return columnValue.toLowerCase() === value.toLowerCase();
        case 'notequals':
            return columnValue.toLowerCase() !== value.toLowerCase();
        case 'contains':
            return columnValue.toLowerCase().includes(value.toLowerCase());
        case 'notContains':
            return !columnValue.toLowerCase().includes(value.toLowerCase());
        default:
            return false;
    }
}

export const filterByCareDesign = ((responseData, filterObj) => {
    if (checkUndefinedAndNull(filterObj.advanceFilters)) {
        let conditions = filterObj.advanceFilters;
        responseData = applyAdvanceFilters(responseData, conditions);
    }
    return responseData;
})

export const filterByAgent = ((filtredData, filterObj) => {

    if (checkUndefinedAndNullLength(filterObj.routingProfiles))
        filtredData = filtredData.filter(item => { return filterObj.routingProfiles.map(item => { return item.value }).includes(item.RoutingProfile) })

    if (checkUndefinedAndNullLength(filterObj.agentStatuses))
        filtredData = filtredData.filter(item => { return filterObj.agentStatuses.map(item => { return item.value }).includes(item.AgentStatus) })

    if (checkUndefinedAndNullLength(filterObj.contactStatuses))
        filtredData = filtredData.filter(item => { return filterObj.contactStatuses.map(item => { return item.value }).includes(item.ContactState) })

    if (checkUndefinedAndNullLength(filterObj.agentNames))
        filtredData = filtredData.filter(item => { return filterObj.agentNames.map(item => { return item.value }).includes(`${item.FirstName} ${item.LastName}`) })

    if ((checkUndefinedAndNullLength(filterObj.teamLeads))
        || (checkUndefinedAndNullLength(filterObj.organizations))) {
        const agentFiltredData = sessionQuery.getAgentProfileData()
        if (checkUndefinedAndNullLength(agentFiltredData)) {
            if (checkUndefinedAndNullLength(filterObj.teamLeads)) {
                let teamLeadIds = filterObj.teamLeads.map(item => item.id)
                let updatedFilterData = agentFiltredData
                    .filter(item => teamLeadIds.includes(item.teamLeadID) || teamLeadIds.includes(item.managerUserID))
                updatedFilterData = updatedFilterData.map(item => item.fullName)
                filtredData = filtredData.filter(item => updatedFilterData.includes(item.FirstName + ' ' + item.LastName))
            }
            if (checkUndefinedAndNullLength(filterObj.organizations)) {
                let selectedOrgs = filterObj.organizations.map(item => { return item.value })
                let updatedFilterData = agentFiltredData.filter(item => selectedOrgs.includes(item.organizationName))
                updatedFilterData = updatedFilterData.map(item => item.fullName)
                filtredData = filtredData.filter(item => { return updatedFilterData.includes(item.FirstName + ' ' + item.LastName) })
            }
        }
    }

    if (checkUndefinedAndNull(filterObj.advanceFilters)) {
        let conditions = filterObj.advanceFilters;
        filtredData = applyAdvanceFilters(filtredData, conditions);
    }
    return filtredData;
})

export const CheckAgentValidationAdvanceFilter = ((item, conditions) => {

    return conditions.some(condition => {
        const { conditionColumn, operator, logicalOperator, value } = condition; let itemValue;

        if (conditionColumn === AgentName)
            itemValue = `${item.FirstName} ${item.LastName}`.trim()
        else
            itemValue = item[conditionColumn];

        let filterResult = false;
        switch (operator) {
            case 'contains':
                filterResult = checkUndefinedAndNull(itemValue) && itemValue.includes(value);
                break;
            case 'startwith':
                filterResult = checkUndefinedAndNull(itemValue) && itemValue.startsWith(value);
                break;
            case 'endwith':
                filterResult = checkUndefinedAndNull(itemValue) && itemValue.endsWith(value);
                break;
            case 'equals':
                filterResult = checkUndefinedAndNull(itemValue) && itemValue === value;
                break;
            case 'notequals':
                filterResult = checkUndefinedAndNull(itemValue) && itemValue !== value;
                break;
            case 'notContains':
                filterResult = checkUndefinedAndNull(itemValue) && !itemValue.includes(value);
                break;
        }
        if (!checkUndefinedAndNull(logicalOperator))
            return filterResult;
        else {
            if (logicalOperator === "OR")
                return filterResult;
            else
                return !filterResult;
        }
    });
});

export const HistoryDataFilter = (data, previousHistoryHour, historyType) => {
    // Calculate the time range for the last x hours
    const now = new Date();
    let newTimeAsPerSelectedHour;

    if(historyType === HourFilters.SPECIFIC_HOUR){                
        const [hourStr, minuteStr] = previousHistoryHour?.split(':');
        const hour = parseInt(hourStr, 10);
        const minute = parseInt(minuteStr, 10);
        let nextHourDate = new Date();
        nextHourDate.setHours(hour);
        nextHourDate.setMinutes(minute);
        nextHourDate.setSeconds(0);

        newTimeAsPerSelectedHour = new Date(nextHourDate.getTime());
    } else
        newTimeAsPerSelectedHour = new Date(now.getTime() - previousHistoryHour * 60 * 60 * 1000);

    // Filter data based on createdDate
    const filteredData = data.filter(item => {
        if(checkUndefinedAndNull(item.CreatedDate)){
            const itemDate = new Date(item.CreatedDate);
            return itemDate >= newTimeAsPerSelectedHour && itemDate <= now;
        }
    });

    return filteredData;    
}