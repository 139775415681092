import { Store, StoreConfig } from '@datorama/akita';
import { createInitialHCPState, HCPState } from './hcp.model';

@StoreConfig({ name: 'hcp', resettable: true })
export class HCPStore extends Store<HCPState> {
  constructor() {
    super(createInitialHCPState());
  }
}

export const hcpStore = new HCPStore();