export const apiUrl = "admin/"
export const getRolesUrl = apiUrl + "role/getall"
export const getRoleByIdUrl = apiUrl + "role/getbyid"
export const deleteRoleUrl = apiUrl + "role/delete"
export const manageRoleUrl =  apiUrl + "role/manage"
export const getPrivilegeUrl =  apiUrl + "role/getprivileges"
export const setAsDefaultUrl =  apiUrl + "role/setasdefault"
export const getBaseUsersUrl =  apiUrl + "User/GetAllUsers"
export const getUserRolesUrl = apiUrl + "userprivileges/getassignuserrole"
export const assignRoleToUserUrl = apiUrl + "userprivileges/assignrole"
export const ACM_TABLE =  "userAccessRightTable"
export const copyRoleToUserUrl = apiUrl + "userprivileges/copyrole"
export const assignDefaultAccessUrl = apiUrl + "userprivileges/assigndefaultaccess"