import { forwardRef, useEffect, useState } from "react";
import DynamicComponent from "../../../../../Core/components/DynamicComponent";
import {
  ViewTypeConstants,
  WidgetComponentConstants,
} from "../../../../constants/RTDConstants";
import {
  applyAgentFilter,
  getColorValue,
  getContactState,
  groupByItem,
  proccessUpdateAgentSummaryData,
  updateAgentSummaryStatus,
} from "../../../../services/agentStatusService";
import { useRTDService } from "../../../../services/RTDService";
import "./AgentStatusSummary.css";
import _ from "lodash"
import { checkUndefinedAndNull, checkUndefinedAndNullLength } from "../../../../services/helper";
import { useRTDWidgetDataContext } from "../../../../contextProviders/RTDWidgetDataContextProvider";
import PageLoader from "../../../../../../components/PageLoader";
const AgentStatusSummary = forwardRef((props: any,ref) => {
  const { widgetInfo, filterInfo, onChangeItem, clientSocket } = props;
  const [agentListData, setAgentListData] = useState<any>(undefined);
  const rtdService = useRTDService();
  const { agentStatusSummaryData } = useRTDWidgetDataContext()
  let columns = [
    {
      id: "AgentStatus",
      label: "Status",
      minWidth: 250,
      sortable: true,
      resizeable: true,
      visible: true,
      defaultFormat: (obj) => {
        return (
          <div className={rtdService.getColor(obj.AgentStatus, obj.ContactState)} >
            {obj.AgentStatus}
          </div>
        );
      },
    },
    {
      id: "ContactState",
      label: "Contact State",
      minWidth: 250,
      resizeable: true,
      sortable: true,
      visible: true,
      customTemplate: (obj) => {
        return <div>{obj.ContactState}</div>;
      }
    },
    {
      id: "AgentCount",
      label: "Agent Count",
      minWidth: 250,
      visible: true,
      sortable: true,
      resizeable: true,
      customTemplate: (obj) => {
        return <div>{obj.AgentCount}</div>;
      },
    },
  ];
  // useEffect(() => {
  //   let socket = rtdService.initialSocketConnection(clientSocket);
  //   setClientSocket(socket);
  //   return () => {
  //     setAgentListData([]);
  //     unsubscribeAgentSummaryEvent(clientSocket);
  //     if (clientSocket) {
  //       console.log("Disconnecting socket...");
  //       clientSocket.disconnect();
  //     }
  //   };
  // }, [clientSocket]);
  useEffect(() => {
      let currentData = applyAgentFilter(filterInfo?.filterValues,agentStatusSummaryData)
      if(checkUndefinedAndNullLength(currentData)){
        currentData = currentData.map((a) => {
          return {
            ...a,
            AgentStatus: a.AgentStatus.replace(
              "Working On Customer Case",
              "WOCC"
            ),
            ContactState: getContactState(a.ContactState, a.AgentStatus),
          };
        })
        prepareGridData(currentData)
      }
      updateAgentSummaryStatus(clientSocket, (err, currentItem) => {
        let updatedData = proccessUpdateAgentSummaryData(currentData,currentItem,filterInfo?.filterValues)
        prepareGridData(updatedData)
        currentData = [...updatedData]
      })
  }, [agentStatusSummaryData,filterInfo,widgetInfo.isHideGrouping,widgetInfo.appliedCFRules])
  const prepareGridData = (currentData) => {
    if(widgetInfo.viewTypeId === ViewTypeConstants.PieChart.id)
    {
      let groupData = groupByItem(currentData, ['AgentStatus', 'ContactState'])
      let seriesData = groupData.map((item) => {
        return {
          y: item.AgentCount,
          name: getName(item.AgentStatus, item.ContactState),
          color: getColorValue(widgetInfo.appliedCFRules, item)
        };
      });
      setAgentListData(seriesData);
    }
    else if(widgetInfo.isHideGrouping){
      let groupData = groupByItem(currentData, ['AgentStatus', 'ContactState'])
      setAgentListData(groupData)
    }
    else {
      setAgentListData(currentData)
    }
  }
  const getName = (agentStatus, contactState) => {
    return contactState !== undefined && contactState !== ""
      ? agentStatus + " (" + contactState + ") "
      : agentStatus;
  };
  const calculateAgentCount = (name) => {
    let total = 0;
    if (agentListData) {
      for (let agent of agentListData) {
        if (agent.RoutingProfile === name) {
          total += agent.AgentCount;
        }
      }
    }
    return total;
  };
  const rowHeaderGroupTemplate = (data) => {
    return (
      <>
        {data.RoutingProfile} : {calculateAgentCount(data.RoutingProfile)}{" "}
        agent(s)
      </>
    );
  };
  const getWidgetItemByType = () => {
    let currentWidgetComponent: any = WidgetComponentConstants.find(
      (item) => item.widgetId === widgetInfo.widgetType
    );
    let currentWidgetItem = currentWidgetComponent?.items?.find(
      (item) => item.id === widgetInfo.viewItemId
    );
    let currentWidgetType = currentWidgetItem?.viewTypes?.find(
      (item) => item.id === widgetInfo.viewTypeId
    );
    return (
      <DynamicComponent
        ref={ref}
        component={currentWidgetType?.viewCompoent}
        data={agentListData}
        widgetInfo={widgetInfo}
        columns={columns}
        onChangeItem={onChangeItem}
        rowHeaderGroupTemplate={rowHeaderGroupTemplate}
      />
    );
  };
  return (
    <>
      {agentListData &&(<div
        className="role-content"
        style={{ overflow: "auto", height: "auto", position: "relative" }}
      >
        {getWidgetItemByType()}
      </div>)}
      {!agentListData && <PageLoader />}
    </>
  );
})
export default AgentStatusSummary;