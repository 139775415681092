import React, { FC } from "react";
import "./LoadingPage.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface LoadingpageProps {}

const LoadingPage: FC<LoadingpageProps> = () => (
  <Box className="Loadingpage">
    <Box
      sx={{
        position: "fixed",

        width: "100%",
        height: "100vh",
        zIndex: "9999",
      }}
    >
      <CircularProgress />
    </Box>
  </Box>
);

export default LoadingPage;
