import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./NxModalDialog.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface NxDialogProps {
  id: string;
  titleName?: string;
  titleContent: React.ReactNode;
  bodyContent: React.ReactNode;
  footerContent?: React.ReactNode;
  onClose: () => void;
  open: boolean;
  className?: string;
  classes?: object;
  customStyle?: object;
}

const NxModalDialog = (props: NxDialogProps) => {
  const {
    titleName,
    titleContent,
    bodyContent,
    footerContent,
    open,
    onClose,
    className,
    classes,
    customStyle,
    ...other
  } = props;

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={className}
      classes={classes}
      style={customStyle}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {titleName}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>{bodyContent}</DialogContent>
      {footerContent != null && <DialogActions>{footerContent}</DialogActions>}
    </BootstrapDialog>
  );
};

export default NxModalDialog;
