import { Checkbox } from "primereact/checkbox";
import * as React from "react";
import "./PrwCheckbox.scss";

const PrwCheckbox = (props: any) => {
  return (
    <div className="prw-checkbox">
      <Checkbox
        className={`p-d-block p-inputtext-sm ${
          props?.rawErrors?.length > 0 ? "p-invalid" : null
        }`}
        onChange={(e) => props.onChange(e.checked)}
        checked={props.value}
      />
    </div>
  );
};

export default PrwCheckbox;
