import { Autocomplete, Box, Button, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumb/Breadcrumb";
import { useUserContext } from "../../../../../contextProviderFiles/UserContextProvider";
import { OperationType } from "../../../../../enums/common.enum";
import { useCommonService } from "../../../../../services/CommonService";
import { useACMService } from "../../../services/ACMService";
import AddIcon from "@mui/icons-material/Add";
import "./RolePrivilegePage.css";
import PrwcSidebar from "../../../../UI/widgets/custom/PrwcSidebar";
import ManageRolePrivilege from "../../presentations/ManageRolePrivilege";
import RolePrivilegeList from "../../presentations/RolePrivilegeList";

const RolePrivilegePage = () => {
  const { setLoader, loggedUserInfo } = useUserContext();
  const acmService = useACMService();
  const commonService = useCommonService();
  const [privileges, setPrivileges] = useState<any>([]);
  const [centers, setCenters] = useState<any>([]);
  //const [users, setUsers] = useState<any>([]);
  const [selectedCenterId, setSelectedCenterId] = useState(
    loggedUserInfo.centerId
  );
  const [isSideBarVisible, setIsSideBarVisible] = useState(false);
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);

  const [operationType, setOperationType] = useState(OperationType.ADD);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [rolePrivilegeData, setRolePrivilegeData] = useState<any>([]);
  const [allBreadCrumbs, setAllBreadCrumbs] = useState<any>([]);
  useEffect(() => {
    setAllBreadCrumbs([
      {
        title: "ACM",
        link: "",
      },
      {
        title: "Role Privilege",
        link: "",
      },
    ]);
    const getCentersReq = commonService.getCentersForDropDown();
    getCentersReq.then((res) => {
      var result = res.data;
      if (result.isSuccess) {
        setCenters(result.centers);
      }
    });
    fetchRolePrivileges(loggedUserInfo.centerId);
  }, []);

  const fetchRolePrivileges = (centerId: number) => {
    setLoader(true);
    let reqObj = {
      centerId: centerId,
    };
    acmService
      .getRoles(reqObj)
      .then((res) => {
        var result = res.data;
        if (result.isSuccess) {
          setRolePrivilegeData(result.roleResults);
          setLoader(false);
        } else {
          setRolePrivilegeData([]);
          setLoader(false);
          //commonService.showToast("error", result.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        commonService.showToast("error", err);
      });
  };

  const onDeleteChange = (data) => {
    let requestObj = {
      roleId: data.roleId,
    };

    setIsDeleteLoader(true);
    acmService
      .deleteRole(requestObj)
      .then((res) => {
        var result = res.data;
        if (result.isSuccess) {
          setIsDeleteLoader(false);
          fetchRolePrivileges(selectedCenterId);
          commonService.showToast("success", result.message);
        } else {
          setIsDeleteLoader(false);
          commonService.showToast("error", result.message);
        }
      })
      .catch((err) => {
        setIsDeleteLoader(false);
        commonService.showToast("error", err);
      });
  };

  const onSaveSubmit = (rowData) => {
    var requestObj = {
      id: rowData.roleId,
      name: rowData.name,
      centerId: rowData.centerId,
      description: rowData.description !== undefined ? rowData.description : "",
      assignPrivileges: JSON.stringify(rowData.selectedPrivileges),
    };

    acmService
      .manageRole(requestObj)
      .then((res) => {
        var result = res.data;
        if (result.isSuccess) {
          setIsSideBarVisible(false);
          commonService.showToast("success", result.message);
          fetchRolePrivileges(selectedCenterId);
        } else {
          commonService.showToast("error", result.message);
        }
      })
      .catch((err) => {
        commonService.showToast("error", err);
      });
  };

  const handleAddRolePrivilege = () => {
    setOperationType(OperationType.ADD);
    let defaultObj = { roleId: 0, centerId: selectedCenterId };
    setSelectedRowData(defaultObj);

    getPrivileges(defaultObj.roleId);
  };

  const getPrivileges = (roleId) => {
    let reqObj = {
      roleId: roleId,
    };

    const getPrivilegeReq = acmService.getPrivileges(reqObj);
    getPrivilegeReq.then((res) => {
      var result = res.data;
      if (result.isSuccess) {
        setPrivileges(result.privileges);
        setIsSideBarVisible(true);
      }
    });
  };

  const handleEditRolePrivilege = (rowData: any, isClone: boolean = false) => {
    getPrivileges(rowData.roleId);
    if (isClone) {
      rowData.roleId = 0;
      setOperationType(OperationType.CLONE);
    } else setOperationType(OperationType.EDIT);

    setSelectedRowData(rowData);
  };

  const onDefaultRoleChange = (rowData: any) => {
    let requestObj = {
      RoleId: rowData.roleId,
      CenterId: selectedCenterId,
      IsDefault: !rowData.isDefault,
    };

    setIsDeleteLoader(true);
    acmService
      .setAsDefaultRole(requestObj)
      .then((res) => {
        var result = res.data;
        if (result.isSuccess) {
          setIsDeleteLoader(false);
          fetchRolePrivileges(selectedCenterId);
          commonService.showToast("success", result.message);
        } else {
          setIsDeleteLoader(false);
          commonService.showToast("error", result.message);
        }
      })
      .catch((err) => {
        setIsDeleteLoader(false);
        commonService.showToast("error", err);
      });
  };

  return (
    <>
      <Box>
        <Box className="bread-crums-box">
          <Breadcrumbs allBreadCrumbs={allBreadCrumbs} />
        </Box>
        <Box className="white-board roleprivilege">
          <Box
            className="select-user-box cf"
            sx={{ textAlign: "left", marginBottom: "20px" }}
          >
            <Box className="cent-orgBox" sx={{ width: "20%", float: "left" }}>
              <Autocomplete
                id="centerDropdown"
                defaultValue={{
                  id: loggedUserInfo.centerId,
                  name: loggedUserInfo.center,
                }}
                options={centers}
                isOptionEqualToValue={(option, value) => {
                  return option.id == value.id;
                }}
                getOptionLabel={(option: any) =>
                  option.name ? option.name : ""
                }
                onChange={(event, value) => {
                  setSelectedCenterId(value?.id);
                  fetchRolePrivileges(value?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Center"
                    placeholder="Select Center"
                  />
                )}
              />
            </Box>
            <Box className="common-iconbutton" sx={{ float: "right" }}>
              <Tooltip title={"Create Role"} placement="top" arrow>
                <Button
                  variant="outlined"
                  onClick={() => handleAddRolePrivilege()}
                  sx={{ margin: "0 !Important" }}
                >
                  <AddIcon
                    sx={{
                      verticalAlign: "top",
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Tooltip>
              <PrwcSidebar
                headerTitle={
                  operationType === OperationType.ADD
                    ? "Add Role"
                    : operationType === OperationType.CLONE
                    ? "Clone Role"
                    : "Edit Role"
                }
                isSideBarVisible={isSideBarVisible}
                position="right"
                showCloseIcon={true}
                className="p-sidebar-md"
                onHide={() => setIsSideBarVisible(false)}
              >
                <ManageRolePrivilege
                  operationType={operationType}
                  data={selectedRowData}
                  onSaveSubmit={onSaveSubmit}
                  centers={centers}
                  privileges={privileges}
                />
              </PrwcSidebar>
            </Box>
          </Box>
          <Box className="role-content" sx={{ height: "auto !important" }}>
            <RolePrivilegeList
              data={rolePrivilegeData}
              onEdit={handleEditRolePrivilege}
              onDeleteChange={onDeleteChange}
              isDeleteLoader={isDeleteLoader}
              onDefaultRoleChange={onDefaultRoleChange}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RolePrivilegePage;
