
import { Carousel } from 'primereact/carousel'
import GaugeChart from 'react-gauge-chart'
import "../../../../CSS/carouselGlobalCSS.css"
import { MarketHelpersOldesttime } from '../../../../services/marketservice'
import { getMarketGroupName } from '../../../../services/helper'

const MarketCard = (props:any) => {
    const { data, widgetInfo } = props

    const responsiveOptions = [
        {
            breakpoint: '1920px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '1024px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '360px',
            numVisible: 1,
            numScroll: 1
        }
    ]
    
    let groupName = getMarketGroupName(widgetInfo)
    const cardTemplate = (item,groupName) => {
       let percentage = item.data.SERVICE_LEVEL/100
        return (
            <div className="carousel-item">
                <div className="carousel-item-content phone-market-box">
                    <div className="carousel-heading">{`${groupName}: ${item.data.QueueName}`}</div>
                    <div className="firstpart-carousel">
                        <div className="carousel-box">
                            <p>OFFERED</p>
                            <span>{item.data.CONTACTS_QUEUED}</span>
                        </div>
                        <div className="carousel-box">
                            <p>HANDLED</p>
                            <span>{item.data.CONTACTS_HANDLED}</span>
                        </div>
                        <div className="carousel-box">
                            <p>ABANDONED</p>
                            <span>{item.data.CONTACTS_ABANDONED}<sub>{item.data.ABANDONED_RATE.toFixed(2)}%</sub></span>
                        </div>
                    </div>
                    <div className="sla-mainbox ">
                        <div><GaugeChart id={`service-level-${item.id}`} 
                            arcsLength={[0.5, 0.35, 0.15]}
                            colors={['#EA4228','#F5CD19','#5BE12C']}
                            arcPadding={0.03}
                            animate={false}
                            nrOfLevels={3} arcWidth={0.3} percent={percentage} textColor="#000"/>
                            <div className='sla-text'>SLA</div>
                        </div>
                    </div>  
                    <div className="carousel-data">
                        <div className="carousel-box">
                            <p>IN QUEUE</p>
                            <span>{item.data.CONTACTS_IN_QUEUE}</span>
                        </div>
                        <div className="carousel-box">
                            <p>QUEUE TIME</p>
                            <span>{MarketHelpersOldesttime(item.data.OLDEST_CONTACT_AGE)}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div>
                <div className="carousel-part phoneMarketData">
                    <div className="card">
                        <Carousel value={data} circular autoplayInterval={5000} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions}
                            itemTemplate={(item) => cardTemplate(item,groupName)} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarketCard