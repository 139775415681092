import React, { FC, useEffect, useState } from 'react';
import { Carousel } from 'primereact/carousel'
import "../../../../CSS/carouselGlobalCSS.css"
import BarChart from '../../Charts/BarChart';

const ChatAgeCard = (props) => {
    const { data } = props
    const [chartData, setChartData] = useState<any>([])
    useEffect(() => {
        setChartData(data)
    }, [data])

   const responsiveOptions = [
        {
            breakpoint: '1920px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '1024px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '360px',
            numVisible: 1,
            numScroll: 1
        }
    ]
    const categories = ["0-30","30-60","60-90","90-120",">120"]
    const productTemplate = (item) => {
        let ageData: any = []
        categories.forEach(category => {
            return ageData.push(item.data[category]);
        }) 
        return (
            <div className="carousel-item chat-age">
                <div className="carousel-item-content">
                    <div className="carousel-heading">{item.data.QueueName}</div>
                    <div className='barchart-box'>
                        <BarChart data={ageData} categories = {categories}/>
                    </div>
                </div>
            </div>
        );
    }

  
  return(
  <>
            <div>
                <div className="carousel-part">
                    <div className="card">
                        {data &&(<Carousel value={data} circular 
                                autoplayInterval={5000} 
                                numVisible={1} numScroll={1} 
                                responsiveOptions={responsiveOptions}
                                itemTemplate={productTemplate} />)}
                    </div>
                </div>
            </div>
  </>)
}

export default ChatAgeCard;
