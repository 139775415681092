import { forwardRef, useEffect, useState } from 'react';
import { processChatAnalysisData } from '../../../../services/chatEmailService';
import { WidgetComponentConstants } from '../../../../constants/RTDConstants';
import DynamicComponent from '../../../../../Core/components/DynamicComponent';
import { checkUndefinedAndNullLength } from '../../../../services/helper';
import { useRTDWidgetDataContext } from '../../../../contextProviders/RTDWidgetDataContextProvider';
import PageLoader from '../../../../../../components/PageLoader';

const ChatStatusAnalysis = forwardRef((props: any, ref) => {
  const { widgetInfo, onChangeItem, filterInfo, globalFilterInfo, clientSocket } = props
  const [chatStatusData, setChatStatusData] = useState<any>(undefined)
  const { chatAnalysisData } = useRTDWidgetDataContext()
  useEffect(() => {
    if(checkUndefinedAndNullLength(chatAnalysisData)){
        let combinedData = processChatAnalysisData(chatAnalysisData,filterInfo,widgetInfo)
        chatStatusAnalysisCallback(null,combinedData)
    }
  }, [clientSocket, chatAnalysisData,filterInfo,widgetInfo?.groupField, widgetInfo?.historySettings])

  const chatStatusAnalysisCallback  = (err, currentData) => {            
    setChatStatusData(currentData)
  }

  const getWidgetItemByType = () => {
    let currentWidgetComponent: any = WidgetComponentConstants.find(
      (item) => item.widgetId === widgetInfo.widgetType
    );
    let currentWidgetItem = currentWidgetComponent?.items?.find(
      (item) => item.id === widgetInfo.viewItemId
    );
    let currentWidgetType = currentWidgetItem?.viewTypes?.find(
      (item) => item.id === widgetInfo.viewTypeId
    );

    return (
      <DynamicComponent
        component={currentWidgetType?.viewCompoent}
        data={chatStatusData}
        widgetInfo={widgetInfo}
        onChangeItem={onChangeItem}
        ref={ref}
        globalFilterInfo = {globalFilterInfo}
      />
    )
  }
  
  return (
    <>
      {chatStatusData && (<div
          className="role-content"
          style={{ overflow: "auto", height: "auto", position: "relative" }}
      >
        {getWidgetItemByType()}
      </div>)}
      {!chatStatusData && <PageLoader />}
    </>
  )
})

export default ChatStatusAnalysis;
