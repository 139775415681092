import { IUserBaseDetails } from '../contracts/IUserBaseDetails'
export interface SessionState {
    loggedInUserInfo: IUserBaseDetails | null;
    agentProfileData: any
}

export function createInitialSessionState(): SessionState {
    return {    
        loggedInUserInfo: {
            email: '',
            userName: '',
            firstName: '',
            lastName: '',
            fullName: '',            
            teamMemberID: '',
            userID: '',
            isWFMUser: false,
            center: '',
            centerId: 0,
            isManager : false,
            managerUserId : '',
            designation: '',
            assignedPrivileges:'',
            userTheme:''
        },
        agentProfileData: []
    }
}

export function createSession(userInfo: IUserBaseDetails) {
    return {...userInfo};
}