import React,{ createContext, useContext, useState } from "react";

let hcpInfo: any = {};

const HCPContext = createContext(hcpInfo);

export const HCPContextProvider = (props: any) => {
     const [ allHCPSkillsRequestData, setAllHCPSkillsRequestData ] = useState<any>([]); 
     const [ mappingMasterData, setMappingMasterData ] = useState<any>([]); 
     const [ alertMasterData, setAlertMasterData ] = useState<any>([]); 
    hcpInfo = {
        allHCPSkillsRequestData,
        setAllHCPSkillsRequestData,
        mappingMasterData,
        setMappingMasterData,
        alertMasterData,
        setAlertMasterData
    }

    return (<>
        <HCPContext.Provider value={hcpInfo}>
            {props.children}
        </HCPContext.Provider>
    </>);
}

export const useHCPContext = () => {
    return useContext(HCPContext);
}