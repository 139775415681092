
import { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumb/Breadcrumb";
import HeadCountHistoricalAlertMasterList from "../../presentations/HeadCountHistoricalAlertMasterList";
import { useHCPService } from "../../../services/HCPService";
import { useUserContext } from "../../../../../contextProviderFiles/UserContextProvider";
import { useHCPContext } from "../../../contextProviders/HCPContextProvider";
import { useCommonService } from "../../../../../services/CommonService";
import GlobalSearchFilter from "../../presentations/GlobalSearchFilter";
import './HeadCountHistoricalAlertsPage.css';
import AlertsFilter from "../../presentations/AlertsFilter";

type alertProps = {
  filterValues: string; 
};
const HeadCountHistoricalAlertsPage = () => {
  const { setLoader, loggedUserInfo } = useUserContext();
  const [allBreadCrumbs, setAllBreadCrumbs] = useState<any>([]);
  const { alertMasterData, setAlertMasterData } = useHCPContext();
  const [saveFilters, setSaveFilters] = useState<any>([]);
  const hcpService = useHCPService();
  const commonService = useCommonService();
  const [globalFilters, setGlobalFilters] = useState(undefined);

  useEffect(() => {
    setAllBreadCrumbs([
      {
        title: "P & F",
        link: "",
      },
      {
        title: "HeadCountPlanner",
        link: "/hcp/headcountplanner",
      },
      {
        title: "HeadCount",
        link: "/hcp/headcountpage",
      },
      {
        title: "Alerts",
        link: "",
      },
    ]);
  }, []);

  useEffect(() => {
    fetchAlertListData();
    getAlertFilterSettings();
    return () => {
      setSaveFilters([])
    };
  }, []);
  const getAlertFilterSettings = () => {
    let reqObj = {
      userName: loggedUserInfo.userName
    };
    hcpService
      .getAlertFilters(reqObj)
      .then((response) => {
        let result = response.data;
        setSaveFilters(result.filters);
      })
      .catch((err) => {
        commonService.showToast("error", err);
      });
  }
  const onDeleteFilterSetting = (item) => {
    hcpService
      .deleteFilterSetting(item)
      .then((response) => {
        let result = response.data;
        if (result.isSuccess) {
          commonService.showToast("success", result.errorMessage);
          getAlertFilterSettings();
        } else {
          commonService.showToast("error", result.errorMessage);
        }
      })
      .catch((err) => {
        commonService.showToast("error", err);
      });
  };
  const fetchAlertListData = (params?: alertProps) => {
    setLoader(true);   
    const reqObj = {
      filterValues: params?.filterValues ? (typeof (params.filterValues) !== 'string' ? JSON.stringify(params.filterValues) : params.filterValues) : "",
    };
    hcpService
      .getAlertDetails(reqObj)
      .then((res) => {
        var result = res.data;
        if (result.isSuccess) {
          setAlertMasterData(result.notifications);         
          setLoader(false);
        } else {
          setAlertMasterData([]);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        commonService.showToast("error", err);
      });
  };
  const customAlertApplyData = (request) => {

    //request date conversion due to previous day
    const localStartDate = new Date(request.newStartDate);
    localStartDate.setMinutes(localStartDate.getMinutes() - localStartDate.getTimezoneOffset());
    const newStartDate = localStartDate.toISOString();
    let alertData = request.alertData;
    alertData.fieldDetails = JSON.parse(alertData.fieldDetails);
    let alertJSONData = JSON.stringify(alertData);

    let reqObj = {
      status: request.status,
      alertJSONData: alertJSONData,
      userId: loggedUserInfo.userID,
      newStartDate: newStartDate
    };
    hcpService.applyCustomUpdateAlert(reqObj).then((res) => {
      var result = res.data;
      if (result.isSuccess) {
        commonService.showToast("success", "Notification updated Successfully.");      
      } else
        commonService.showToast("error", result.errorMessage);
    })
      .catch((err) => {
        commonService.showToast("error", err);
      });
  };
  const onSaveFilterChange = (item) => {
    getAlertFilterSettings()

  };
  const hcpAlertApplyData = (request) => {
    //setBtnLoader(true);
    let alertData = request.alertData;
    alertData.fieldDetails = JSON.parse(alertData.fieldDetails);
    let alertJSONData = JSON.stringify(alertData);
    let reqObj = {
      status: request.status,
      alertJSONData: alertJSONData,
      userId: loggedUserInfo.userID
    };
    hcpService.getHCPAlertApply(reqObj).then((res) => {
      var result = res.data;
      if (result.isSuccess) {
        commonService.showToast("success", "Notification updated Successfully.");   
      } else
        commonService.showToast("error", result.errorMessage);
    })
      .catch((err) => {
        commonService.showToast("error", err);
      });
  };

  const onGlobalFilterCallback = (filters) => {
    setGlobalFilters(filters);
  };
  const getGlobalFilterContent = () => {
    return (<div className="viewall-search-box-hcp">
      <GlobalSearchFilter onGlobalFilterCallback={onGlobalFilterCallback} />
    </div>)
  }
  const TabContent = () => {
    return (
      <div className="tab-content masterPage">
        <div className='viewall-alert-butn-box'>
          {getGlobalFilterContent()}
          <div className='alert-filter-button-hcp'>

            <AlertsFilter onSaveFilterChange={onSaveFilterChange} saveFilters={saveFilters} onChangeCallback={fetchAlertListData} onDeleteFilterSetting={onDeleteFilterSetting} />
          </div>
        </div>
        <div className="headcounthistory-list">
          <HeadCountHistoricalAlertMasterList
            alertData={alertMasterData}           
            globalFilters={globalFilters}
            onQuickUpdate={hcpAlertApplyData}           
            onCustomUpdateCallback={customAlertApplyData}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="bread-crums-box">
        <Breadcrumbs allBreadCrumbs={allBreadCrumbs} />
      </div>
      <div className="white-board hcp">
        <div className="headcounthistorylist-box">
          {TabContent()}
        </div>
      </div>
    </>
  );
};
export default HeadCountHistoricalAlertsPage;