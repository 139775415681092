export const checkUndefinedAndNull = (obj) => {
    return obj !== undefined && obj !== null
}

export const checkUndefinedAndNullEmpty = (obj) => {
    return obj !== undefined && obj !== null && obj !== ""
}
export const checkUndefinedAndNullLength = (obj) => {
    return obj !== undefined && obj !== null && obj.length > 0
}

export const isJSONParsable = (object) => {
    try {
        JSON.parse(object)
        return true;
    } catch (error) {
        return false;
    }
}

export const parseJSONToObject = (object) => {
    if (checkUndefinedAndNull(object)) {
        try {
            return JSON.parse(object)
        } catch (error) {
            return object
        }
    }
    else {
        return []
    }
}