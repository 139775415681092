import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import { DNAContextProvider } from "./contextProviders/DNAContextProvider";
import { DNAServiceProvider } from "./services/DNAService";
const DNARoute = () => {
  return (
    <DNAServiceProvider>
      <DNAContextProvider>
        <Outlet />
      </DNAContextProvider>
    </DNAServiceProvider>
  );
};

export default DNARoute;
