import React, { useEffect, useRef, useState } from "react";
import { RTDConstants, StatusStartTimestamp, OnHoldTimestamp, compareValues } from "../../../../constants/RTDConstants";
import { useRTDService } from "../../../../services/RTDService";
import Settings from "../../../../utils/settings";
import "./TimeStatusComponent.css";
import { checkUndefinedAndNullEmpty } from "../../../../services/helper";

const usePrevious = (value) => {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const TimeStatusComponent = (props) => {
  const { agent, thresholdTime, rules } = props;
  const { timeTillNow, timeTillNowDistance } = useRTDService();
  const [time, setTime] = useState<any>(timeTillNow(agent));
  const [timeOnHold, setTimeOnHold] = useState<any>("");
  const [timeOnHoldDistance, setTimeOnHoldDistance] = useState<any>(0);
  const [intervalId, setIntervalId] = useState<any>(0);
  const [onHoldInterval, setOnHoldInterval] = useState<any>(0);
  const [listOnHoldTime, setListOnHoldTime] = useState<any>([]);
  const prevProps = usePrevious(props);

  useEffect(() => {
    if (prevProps) {
      if (
        agent.ConnectedToAgentTimestamp !==
          prevProps.agent.ConnectedToAgentTimestamp ||
        agent.EventTimestamp !== prevProps.agent.EventTimestamp
      ) {
        const newAgent = agent;

        clearInterval(intervalId);

        let currentIntervalId = setInterval(function () {
          setTime(timeTillNow(newAgent));
        }, 1000);

        setTime(timeTillNow(agent));
        setIntervalId(currentIntervalId);
      }

      if (
        agent.ContactState === RTDConstants.OnHold &&
        agent.ContactState !== prevProps.agent.ContactState
      ) {
        let onHoldTime = new Date().getTime();
        clearInterval(onHoldInterval);
        let holdIntervalId = setInterval(function () {
          setTimeOnHold(timeTillNow(agent, onHoldTime));
          setTimeOnHoldDistance(timeTillNowDistance(agent, onHoldTime));
        }, 1000);

        setTimeOnHold(timeTillNow(agent, onHoldTime));
        setTimeOnHoldDistance(timeTillNowDistance(agent, onHoldTime));
        setOnHoldInterval(holdIntervalId);
      }

      if (
        agent.ContactState === RTDConstants.Connected &&
        prevProps.agent.ContactState === RTDConstants.OnHold
      ) {
        listOnHoldTime.push(timeOnHold);
        setListOnHoldTime(listOnHoldTime);
      }

      if (
        prevProps.agent.ContactState !== RTDConstants.OnHold &&
        timeOnHold !== ""
      ) {
        clearInterval(onHoldInterval);
        setTimeOnHold("");
        setTimeOnHoldDistance(0);
      }
    }
  });

  useEffect(() => {
    let currentIntervalId = setInterval(function () {
      setTime(timeTillNow(agent));
    }, 1000);

    if (agent.ContactState === RTDConstants.OnHold) {
      let onHoldTime = new Date().getTime();
      const onHoldIntervalId = setInterval(function () {
        setTimeOnHold(timeTillNow(agent, onHoldTime));
        setTimeOnHoldDistance(timeTillNowDistance(agent, onHoldTime));
      }, 1000);
      setTimeOnHold(timeTillNow(agent, onHoldTime));
      setTimeOnHoldDistance(timeTillNowDistance(agent, onHoldTime));
      setOnHoldInterval(onHoldIntervalId);
    }
    setIntervalId(currentIntervalId);

    return () => {
      clearInterval(intervalId);
      clearInterval(onHoldInterval);
    }
  }, []);
 
  var distance: number = new Date().getTime() - new Date(agent.EventTimestamp).getTime()
  var className = "";
  
  if(rules !== null){
    if(rules !== undefined && rules.length > 0){
      let comparisonResult = false;
      let bgColor = ''
      let foreColor = ''
      let fontsize = 12
      let specificRules = rules.filter(a => [StatusStartTimestamp, OnHoldTimestamp].includes(a.column))
      if(specificRules.length > 0){
        specificRules.map(item => {
          //let previousConditionColumn: string | null = null;
          let result = true
          const { backcolor, color, fontSize, conditions} = item
          let conditionList = JSON.parse(conditions)
  
          conditionList.sort((a, b) => {
            if (a.conditionColumn < b.conditionColumn) {
              return -1;
            } else if (a.conditionColumn > b.conditionColumn) {
              return 1;
            } else {
              return 0;
            }
          })
  
          conditionList.forEach((a) => {
            const { conditionColumn, operator, value, logicalOp } = a;
            let targetValue = value
            let sourceValue = agent[conditionColumn]
            let valueResult = false
            switch (conditionColumn) {
              case StatusStartTimestamp:
                valueResult = compareValues(distance,operator, Settings.getStatusThresholdsTime(value))
                break;
              case OnHoldTimestamp:
                valueResult = compareValues(timeOnHoldDistance,operator, Settings.getStatusThresholdsTime(value/60))
                break;
              default:
                valueResult = compareValues(sourceValue,operator,targetValue)
                break;
            }

            if(checkUndefinedAndNullEmpty(logicalOp))
            {
              switch(logicalOp)
              {
                case 'and': 
                  result = result && valueResult
                  break
                case 'or' :
                  result = result || valueResult
                  break 
              }
            }
            else{
              result = result && valueResult
            }
          })
          if(result){
            bgColor = backcolor
            foreColor = color
            fontsize = fontSize
          }
          return comparisonResult = comparisonResult || result
        })
        return (
          <>
            <div style={{backgroundColor:  bgColor, color: foreColor, fontSize: fontsize}}>
              <span>{time}</span>
              <span style={{ fontSize: 9 }}>
                {agent.ContactState === RTDConstants.OnHold
                  ? " (" + timeOnHold + ")"
                  : ""}
              </span>
            </div>
          </>
        );
      }
    } else{
      if (
        distance >
        Settings.getThreshold(
          agent.AgentStatus,
          agent.ContactState,
          agent.ThresholdTime
        )
      ) {
        className = "redCls";
      }
      else {
        // personal 1min
        let calculatedDistance = Settings.getACW1Threshold(
          agent.AgentStatus,
          agent.ContactState,
          thresholdTime
        );
        if (calculatedDistance && distance > calculatedDistance) {
          className = "personalCls";
        }
      }
    }
  }

  return (
    <>
      <div className={className}>
        <span>{time}</span>
        <span style={{ fontSize: 9 }}>
          {agent.ContactState === RTDConstants.OnHold
            ? " (" + timeOnHold + ")"
            : ""}
        </span>
      </div>
    </>
  );
};

export default TimeStatusComponent;
