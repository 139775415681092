import React, { useEffect } from "react";
import DynamicForm from "../../../../Core/components/DynamicForm/DynamicForm";
import { HCPMasterPagesTabValue } from "../../../../../enums/common.enum";
import PrwcBasicButton from "../../../../UI/widgets/custom/buttons/PrwcBasicButton";
import Validators from "../../../../UI/helpers/validator";
import NoLabelFieldTemplate from "../../../../UI/templates/fields/NoLabelFieldTemplate";
import VerticalFormTemplate from "../../../../UI/templates/objects/VerticalFormTemplate";

import "./ManageMasterPages.css";
const ManageMasterPages = (props) => {

    const { data, onSaveSubmit, mappingMasterId } = props;
    const submitButtonRef: any = React.useRef(null);
    const [formData, setFormData] = React.useState(data);  

    useEffect(() => { 
        setFormData(data);
    }, [data]);


    const onChange = (formData: any) => {
        setFormData(formData);
    };

    const onSubmit = () => {
        onSaveSubmit(formData);
    };

    const onError = (data: any) => {
        console.log("on error ---");
        console.log(data);
    };

    const formButtons = () => {
        return (
            <button ref={submitButtonRef} type="submit" className="hidden p-col-12" />
        );
    };
    const handleOnSubmit = () => {
        // It is to submit form programmatically.
        submitButtonRef.current.click();
    };

    var schema: any;
    var validationSchema: any;
    var uiSchema: any;

    const isWorkgroupOrCostCenter =
        mappingMasterId === HCPMasterPagesTabValue.Workgroup ||
        mappingMasterId === HCPMasterPagesTabValue.ForecastGroup ||
        mappingMasterId === HCPMasterPagesTabValue.WebCountry ||
        mappingMasterId === HCPMasterPagesTabValue.ContactType;

    const title = isWorkgroupOrCostCenter ? "Name" : "CostCenterNo";
    const requiredField = isWorkgroupOrCostCenter ? "name" : "costCenterNo";
    const requiredMessage = isWorkgroupOrCostCenter ? "Name is required" : "CostCenter No is required";

    schema = {
        type: "object",
        properties: {
            [requiredField]: {
                type: "string",
                title: title,
            },
            description: {
                type: "string",
                title: "Description",
            },            
        },
        required: [requiredField],
    };

    validationSchema = {
        [requiredField]: {
            validators: [
                {
                    validate: (value) => !Validators.isStringEmpty(value),
                    messageKey: requiredMessage,
                },
            ],
        },
        description: {
      validators: [
        {
          validate: (value: string) => {
            return Validators.isMaxLengthValid(value, 100);
          },
          messageKey: "Description should not more than 100 characters",
        },
      ],
    },
    };

    uiSchema = {
        "ui:ObjectFieldTemplate": VerticalFormTemplate,
        [requiredField]: {
            "ui:widget": "prwInputText",
            "ui:FieldTemplate": NoLabelFieldTemplate,
            classNames: "textbox",
            "ui:emptyValue": "",
        },
        description: {
            "ui:widget": "prwInputTextArea",
            "ui:FieldTemplate": NoLabelFieldTemplate,
            classNames: "description",
            "ui:emptyValue": "",
            "ui:rows":"15",
    },
    };

    const dynamicFormContent = () => {      
        return (
            <div className="manage-workgrup">
                <DynamicForm
                    onChange={onChange}
                    formData={formData}
                    onSubmit={onSubmit}
                    onError={onError}
                    schema={schema}
                    uiSchema={uiSchema}
                    validationSchema={validationSchema}
                    formButtons={() => formButtons()}
                ></DynamicForm>
            </div>
        );
    };
    const customButtonsContent = () => {
        return (
            <div className="rightsidebutn" style={{marginTop:"0 !important"}}>
                <PrwcBasicButton
                    onClick={handleOnSubmit}
                    onSubmit={onSubmit}
                    color="primary"
                    variant="contained"
                    labelKey={"Save"}
                    classObj={{}}
                ></PrwcBasicButton>
            </div>
        );
    };

    return (
        <>
            <div className="group editqueueform">
                {dynamicFormContent()}
                {customButtonsContent()}
            </div>
        </>
    );
}
export default ManageMasterPages;