import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "./FilterOption.css";
import PickListOption from "../../PickListOption";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useRTDService } from "../../../../services/RTDService";
import { useCommonService } from "../../../../../../services/CommonService";
import { useUserContext } from "../../../../../../contextProviderFiles/UserContextProvider";
import { WidgetViewItemConstants,AgentStatusTabConstants } from "../../../../constants/RTDConstants";
import { checkUndefinedAndNull } from "../../../../services/helper";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const FilterOption = forwardRef((props:any,ref) => {
  const { onApplyFilter, onSaveFilter, data, viewItemId } = props
  const { loggedUserInfo } = useUserContext();
  const rtdService = useRTDService()
  const commonService = useCommonService()
  const [routingProfiles,setRoutingProfiles] = useState<any>([]);
  const [targetRoutingProfiles,setTargetRoutingProfiles] = useState<any>([]);
  const [agentStatus,setAgentStatus] = useState<any>([]);
  const [targetAgentStatus,setTargetAgentStatus] = useState<any>([]);
  const [contactStatus,setContactStatus] = useState<any>([]);
  const [agentNames,setAgentNames] = useState<any>([]);
  const [targetContactStatus,setTargetContactStatus] = useState<any>([]);
  const [targetAgentNames,setTargetAgentNames] = useState<any>([]);
  const [teamLeads,setTeamLeads] = useState<any>([]);
  const [targetTeamLeads,setTargetTeamLeads] = useState<any>([]);
  const [organizations,setOrganizations] = useState<any>([]);
  const [targetOrganizations,setTargetOrganizations] = useState<any>([]);
  const [value, setValue] = useState(0); 
  
  useImperativeHandle(ref, () => ({
    saveAsFilter(data) {
      onSaveAsFilter(data)
    },
    clearAllParameter() {
      onClearAllParameters()
    },
    runFilter() {
      return onRunFilter()
    }
  }));

  useEffect(() => {
    getRoutingProfile()
    getAgentStatuses()
    getContactStatuses()
    if(viewItemId === WidgetViewItemConstants.AgentStatusView){
      getAgentNames()
      getTeamLeads()
      getOrganizations()
    }
  }, [data?.filterValues]);
    
  const onSaveAsFilter = (filterData) => {   
      //setSaveAs(false);

      let filterValues = [{
        routingProfiles : targetRoutingProfiles,
        agentStatuses: targetAgentStatus,
        agentNames: targetAgentNames,
        contactStatuses: targetContactStatus,
        teamLeads:targetTeamLeads,
        organizations:targetOrganizations
      }];
      
      let savefilterObj = {
          ...filterData,
          "userId": loggedUserInfo.userID, 
          "filterValues":filterValues
      };

    onSaveFilter(savefilterObj);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {  
    setValue(newValue);  
  };

  const onChange = (event) => {
    switch (value) {
      case 0:
        setRoutingProfiles(event.source)
        setTargetRoutingProfiles(event.target)
        break;
      case 1 :
        setAgentStatus(event.source)
        setTargetAgentStatus(event.target)      
        break;
      case 2 :
        setContactStatus(event.source)
        setTargetContactStatus(event.target)
        break;
      case 3 :
        setAgentNames(event.source)
        setTargetAgentNames(event.target)
        break;
      case 4 :
         setTeamLeads(event.source)
         setTargetTeamLeads(event.target)
         break;
      case 5 :
         setOrganizations(event.source)
         setTargetOrganizations(event.target)
         break;
    }    
  }

  const onClearAllParameters = () => {
    let clearRoutingProfile = routingProfiles.concat(targetRoutingProfiles).sort((a, b) => a.id - b.id)
    setRoutingProfiles(clearRoutingProfile)
    setTargetRoutingProfiles([])
    
    let clearAgentStatus = agentStatus.concat(targetAgentStatus).sort((a,b) => a.id - b.id)
    setAgentStatus(clearAgentStatus)
    setTargetAgentStatus([])

    let clearContactStatus = contactStatus.concat(targetContactStatus).sort((a, b) => a.id - b.id)
    setContactStatus(clearContactStatus)
    setTargetContactStatus([])

    let clearAgentNames = agentNames.concat(targetAgentNames).sort((a,b) => a.id - b.id)
    setAgentNames(clearAgentNames)
    setTargetAgentNames([])

    let clearTeamLeads = teamLeads.concat(targetTeamLeads).sort((a,b) => a.id - b.id)
    setTeamLeads(clearTeamLeads)
    setTargetTeamLeads([])

    let clearOrganizations = teamLeads.concat(targetOrganizations).sort((a,b) => a.id - b.id)
    setOrganizations(clearOrganizations)
    setTargetOrganizations([])
    setValue(0)
  }

  const getRoutingProfile = () => {
    rtdService.getRoutingProfiles().then((res) => {
        if (checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues) &&
        checkUndefinedAndNull(data.filterValues[0].routingProfiles)) {
          let allData = res.data;
          let targetData = data.filterValues[0].routingProfiles;
          setTargetRoutingProfiles(targetData);
          let sourceData = allData.filter(item => {
            return targetData.filter(i => { return i.value === item.value }).length <= 0;
          });
          setRoutingProfiles(sourceData);
        }
        else {
          setRoutingProfiles(res.data);
          setTargetRoutingProfiles([])
        }
    }).catch((err) => {
      commonService.showToast("error", err);
    })
  }

  const getAgentStatuses = () => {
    rtdService.getAgentStatuses().then((res) => {
      if (checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues) &&
      checkUndefinedAndNull(data.filterValues[0].agentStatuses)) {
        let allData = res.data;
        let targetData = data.filterValues[0].agentStatuses;
        setTargetAgentStatus(targetData);
        let sourceData = allData.filter(item => {
          return targetData.filter(i => { return i.value === item.value }).length <= 0;
        });
        setAgentStatus(sourceData);
      }
      else {
        setAgentStatus(res.data)
        setTargetAgentStatus([])
      }
    })
      .catch((err) => {
        commonService.showToast("error", err);
      })
  }

  const getAgentNames = () => {
    rtdService.getAgentNames().then((res) => {
        if (checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues) &&
        checkUndefinedAndNull(data.filterValues[0].agentNames)) {
          let allData = res.data;
          let targetData = data.filterValues[0].agentNames;
          setTargetAgentNames(targetData);
          let sourceData = allData.filter(item => {
            return targetData.filter(i => { return i.value === item.value }).length <= 0;
          });
          setAgentNames(sourceData);
        }
        else {
          setAgentNames(res.data);
          setTargetAgentNames([])
        }
    })
      .catch((err) => {
        commonService.showToast("error", err);
      })
  }

  const getContactStatuses = () => {  
    rtdService.getContactStatuses().then((res) => {  
        if(checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues) &&
        checkUndefinedAndNull(data.filterValues[0].contactStatuses)){       
          let allData = res.data;
          let targetData = data.filterValues[0].contactStatuses;
          setTargetContactStatus(targetData);
          let sourceData = allData.filter(item => {
            return targetData.filter(i => { return i.value === item.value }).length <= 0;
          });
          setContactStatus(sourceData);
        }
        else {
          setContactStatus(res.data)
          setTargetContactStatus([])
        }
    })
      .catch((err) => {
        commonService.showToast("error", err);
      })
  }
  
  const getTeamLeads = () => {   
    let reqObj = {
      isManager: true,
    };
    rtdService.getTeamLeadNOrgs(reqObj).then((res) => { 
        if(checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues) &&
          checkUndefinedAndNull(data.filterValues[0].teamLeads)){
          let formatData = res.data.teamLeads;
          let allData= formatData.map((item)=>({id:item.userID,value:item.fullName}))
          let targetData = data.filterValues[0].teamLeads;
          setTargetTeamLeads(targetData);
          let sourceData = allData.filter(item => {
            return targetData.filter(i => { return i.value === item.value }).length <= 0;
          });
          setTeamLeads(sourceData);    
        }
        else{
          let formatData = res.data.teamLeads;
          let allData= formatData.map((item)=>({id:item.userID,value:item.fullName}))
          setTeamLeads(allData)
          setTargetTeamLeads([])
        } 
    })
    .catch((err) => {
      commonService.showToast("error", err);
    })
  }

  const getOrganizations = () => {   
    let reqObj = {
      isManager: false,
    };
    rtdService.getTeamLeadNOrgs(reqObj).then((res) => {
        if(checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues) 
          && checkUndefinedAndNull(data.filterValues[0].organizations)){
          let formatData = res.data.teamLeads;
          let allData= formatData.map((item)=>({id:item.organizationID,value:item.organizationName}))
          let targetData = data.filterValues[0].organizations;
          setTargetOrganizations(targetData);
          let sourceData = allData.filter(item => {
            return targetData.filter(i => { return i.value === item.value }).length <= 0;
          });
          setOrganizations(sourceData);    
        }
        else{
          let formatData = res.data.teamLeads
          let allData= formatData.map((item)=>({id:item.organizationID,value:item.organizationName}))
          setTargetOrganizations([])
          setOrganizations(allData)
        }    
    })
    .catch((err) => {
      commonService.showToast("error", err);
    })
  }
  
  const onRunFilter = () =>{    
    let filterValues = [{
      routingProfiles: targetRoutingProfiles,
      agentStatuses: targetAgentStatus,
      agentNames: targetAgentNames,
      contactStatuses: targetContactStatus,
      teamLeads:targetTeamLeads,
      organizations:targetOrganizations
    }];
    setValue(0)
    return { "filterValues": filterValues };
    //onApplyFilter({"filterValues" : filterValues});
  }

  const getSourceDataById = (id) => {
    switch(id)
    {
      case 0: 
        return routingProfiles
      case 1: 
        return agentStatus
      case 2: 
        return contactStatus
      case 3:
        return agentNames
      case 4:
        return teamLeads
      case 5: 
        return organizations
    }
  }

  const getTargetDataById = (id) => {
    switch(id)
    {
      case 0: 
        return targetRoutingProfiles
      case 1: 
        return targetAgentStatus
      case 2: 
        return targetContactStatus
      case 3:
        return targetAgentNames
      case 4:
        return targetTeamLeads
      case 5: 
        return targetOrganizations
    }
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "auto",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {
            AgentStatusTabConstants
            .filter(a=>a.allowedWidgets.some((tag) => tag === viewItemId))
            .map((item,index) => {
              return (<Tab label={item.label} {...a11yProps(item.id)} />)
            })
          }
        </Tabs>
        {
          AgentStatusTabConstants
          .filter(a=>a.allowedWidgets.some((tag) => tag === viewItemId))
          .map((item,index) => {
            let sourceData = getSourceDataById(item.id)
            let targetData = getTargetDataById(item.id)
            return (
              <TabPanel value={value} index={item.id}>
                <PickListOption sourceData={sourceData} targetData={targetData} 
                  onChange={onChange} 
                  filterText = {item.label} />
              </TabPanel>
            )
        })}
      </Box>
      <Box className="filter-box">
          <p className="defaultt">Filter:</p>
          {
            AgentStatusTabConstants
            .filter(a=>a.allowedWidgets.some((tag) => tag === viewItemId))
            .map((item,index) => {
              let targetData = getTargetDataById(item.id)
              return (<>
               {targetData.length > 0 && (
                <p>
                  {item.label} <span>{targetData.length}</span> Selected,
                </p>
               )}
              </>)
            })
          }
      </Box>
    </>
  );
})

export default FilterOption;