import { Box, Button,TextField } from "@mui/material";
import React, { useState } from "react";
import "./SaveUsFilter.css";

function SaveUsFilter(props) {
  const { onSubmit, onCancel } = props;
  const [filterObj, setFilterObj] = useState<any>({"isPrivate" : "true"});

  const onChange = (e) =>{
    let currentValue = {...filterObj, [e.target.name]: e.target.value }
    setFilterObj(currentValue);
  }

  const onSaveAsFilter = () =>{
    onSubmit(filterObj);
  }

  return (
    <>
      <Box className="popup-content">
        <Box className="textfield">
          <TextField
            id="outlined-basic"
            label="Please enter a Filter Name"
            name="filterName"
            variant="outlined"
            onChange={onChange}
          />
        </Box>
      </Box>
      <Box className="footerfiltername">
        <Button variant="contained" className="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!(filterObj.filterName !== undefined && filterObj.filterName !== "")} onClick={onSaveAsFilter}>Ok</Button>
      </Box>
    </>
  );
}

export default SaveUsFilter;
