import { AdminMasterTabValue } from "../../../enums/common.enum"

export const apiUrl = "fcm/"
export const getForecastUrl = apiUrl + "forecast/getforecast"
export const deleteForecastUrl = apiUrl + "forecast/delete"
export const uploadForecastUrl = apiUrl + "forecast/uploadforecast";
export const getFcstTypeDropDownUrl = apiUrl + "forecast/gettypes"
export const getFcstCountryDropDownUrl = apiUrl + "forecast/getcountries"
export const getFcstBVForDropDownUrl = apiUrl + "forecast/getbusinessverticals";
export const getFcstTypeDatesUrl = apiUrl + "forecast/getdates"
export const getFcstDownloadUrl = apiUrl + "forecast/downloadforecast"
export const getFcstComparisonUrl = apiUrl + "forecast/getforecastcomparison"
export const getAgentDomainMappingsUrl = apiUrl + "agentdomainmapping/getagentdomainmappings"
export const getLanguageDropDownUrl = apiUrl + "agentdomainmapping/getlanguages"
export const getContactTypeDropDownUrl = apiUrl + "agentdomainmapping/getcontacttypes"
export const getDomainDropDownUrl = apiUrl + "agentdomainmapping/getdomains"
export const getLOBDropDownUrl = apiUrl + "agentdomainmapping/getlobs"
export const verifyDomainMappingUrl = apiUrl + "agentdomainmapping/verifydomainmapping"
export const manageDomainMappingUrl = apiUrl + "agentdomainmapping/managedomainmapping"
export const getUsersAndSupervisorsUrl = apiUrl + "agentdomainmapping/getuserandsupervisor"
export const getDomainMappingMasterUrl = apiUrl + "agentdomainmapping/getdomainmappingmaster";
export const manageDomainMappingMasterUrl = apiUrl + "agentdomainmapping/managedomainmappingmaster";
export const deleteMappingMasterUrl = apiUrl + "agentdomainmapping/deletemappingmaster";

export const deleteADMUrl = apiUrl + "agentdomainmapping/deleteagentdomainmapping";
export const getCampaignDropdownsUrl = apiUrl + "forecast/getforecastdropdowns";
export const manageForecastCampaignUrl = apiUrl + "forecast/manageforecastcampaign";
export const getForecastCampaignMasterUrl = apiUrl + "forecast/getforecastcampaignmaster";
export const deleteForecastCampaignUrl = apiUrl + "forecast/deleteforecastcampaign";
export const getAllMappingsUrl = apiUrl + "forecast/getallmappings";
export const saveFilterSettingUrl = apiUrl + "forecast/saveFilterSettings";
export const getForecastMappingFiltersUrl = apiUrl + "forecast/getforecastmappingfilters";
export const deleteForecastFilterUrl = apiUrl + "forecast/deleteforecastfilter";
export const getCampaignsByGroupIdUrl = apiUrl + "forecast/getcampaignsbygroupid";
export const getQueuesByCampaignIdUrl = apiUrl + "forecast/getqueuesbycampaignid";
export const getForecastCampaignValuesUrl = apiUrl + "forecast/getforecastvaluesforverint";
export const getFilteredCampaignsDropdownUrl = apiUrl + "forecast/getfilteredcampaigndropdowns";
export const uploadForecastToVerintUrl = apiUrl + "forecast/uploadForecastToVerint";
export const forecastProfileSavingUrl = apiUrl + "forecast/profileSaving";
export const getUserForecastProfileDetailsUrl = apiUrl + "forecast/getforecastprofile";
export const getForecastProfileIntegrationDetailsUrl = apiUrl + "forecast/getforecastprofileintegration";
export const deleteForecastProfileUrl = apiUrl + "forecast/deleteforecastprofile";
export const saveDistributionCurveUrl = apiUrl + "forecast/distributioncurvesaving";
export const getUserDistributionCurveDetailsUrl = apiUrl + "forecast/getdistributioncurve";
export const deleteForecastDistributionCurveUrl = apiUrl + "forecast/deleteforecastdistributioncurve";
export const getCampaignDistributionCurvesForUploadUrl = apiUrl + "forecast/getcampaigndistributioncurves";

export const newExportToExcelFieldsArr = ["FCST_TYPE","FCST_DATE","WEB_COUNTRY","PLANNING_ENTITY","BUSINESS_VERTICAL", "CS_HIERARCHY_LEVEL1", "CS_HIERARCHY_LEVEL2", "CS_HIERARCHY_LEVEL3", "FORECAST_GROUP", "CONTACT_TYPE", "FCST_ENTITY_DATE", "FCST_OFFERED_CONTACTS", "WEEK", "MONTH", "YEAR", "DAY", "LOB_MARKET", "LOB_SEGMENT", "WEEK_ST"];
export const oldExportToExcelFieldsArr = ["fcsT_TYPE","fcsT_DATE","weB_COUNTRY","planninG_ENTITY","businesS_VERTICAL", "cS_HIERARCHY_LEVEL1", "cS_HIERARCHY_LEVEL2", "cS_HIERARCHY_LEVEL3", "forecasT_GROUP", "contacT_TYPE", "fcsT_ENTITY_DATE", "fcsT_OFFERED_CONTACTS", "week", "month", "year", "day", "loB_MARKET", "loB_SEGMENT", "weeK_ST"];
export const allFiltersFields = ["types","countries", "planningEntities", "businessVerticals", "hierarchyLevel1s", "hierarchyLevel2s",
                            "hierarchyLevel3s", "forecastGroups", "contactTypes", "lOBMarkets"];

export const ForecastConstants = { 
    BlankQueueName: "BlankQueue",
    GroupCode: "GRP",
    CampaignCode: "CMP",
};

export const ParentExpandRowsConstants = {
    ForecastValue: "Forecast Value",
    UpdateValue: "Update Value",
    DifferenceValue: "Difference Value",
    IncDec: "Inc/Dec"
};

export const RowStyleConstants = {
    DiffFieldNegativeBackColor: "diffFieldNegativeBackColor",
    DiffFieldBackColor: "diffFieldBackColor",
    WeekFieldBackColor: "weekFieldBackColor"
};
export const GridConstants = {
    FORECAST_HEADER_TYPE: "header",
    FORECAST_CHEVRON_TYPE: "chevron",
    FORECAST_BLANKFIELD_HEADER_BACKCOLOR_CLASS: "campHeaderColor",
    FORECAST_PARENT_HEADER_CLASS: "headerParentFont",
    FORECAST_HEADER_TITLE: "Duration",
    FORECAST_TEXT_TYPE: "text",
    FORECAST_CHILD_HEADER_CLASS: "headerChildParentFont"
};

export class AdminMasterGenericConstants {
    static readonly Tabs = [
        { 
            headerKey: "Domains",
            addLabelKey: "Add Domain",
            editLabelKey: "Edit Domain",
            value: AdminMasterTabValue.Domain
        },
        { 
            headerKey: "Languages",
            addLabelKey: "Add Language",
            editLabelKey: "Edit Language",
            value: AdminMasterTabValue.Language
        },
        { 
            headerKey: "Contact Types",
            addLabelKey: "Add Contact Type",
            editLabelKey: "Edit Contact Type",
            value: AdminMasterTabValue.ContactType
        },
        { 
            headerKey: "LOBs",
            addLabelKey: "Add LOB",
            editLabelKey: "Edit LOB",
            value: AdminMasterTabValue.LOB
        }
    ]
}
