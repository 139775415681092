import { lazy } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import AuthoriseRoute from "./components/AuthoriseRoute";
import ProtectedRoute from "./components/ProtectedRoute";
import { Privileges } from "./constants/CommonConstant";
import ACMRoute from "./modules/ACM/ACMRoute";
import RolePrivilegePage from "./modules/ACM/components/pages/RolePrivilegePage";
import ForecastRoute from "./modules/Forecast/ForecastRoute";
import TimeOffRoute from "./modules/TimeOff/TimeOffRoute";
import VerintRoute from "./modules/Verint/VerintRoute";
import WFORoute from "./modules/WFH/WFORoute";
import ReportRoute from "./modules/Reports/ReportRoute";
import { useCommonService } from "./services/CommonService";
import RTDRoute from "./modules/RTD/RTDRoute";
import DNARoute from "./modules/DNA/DNARoute";
import HCPRoute from "./modules/HCP/HCPRoute";
import MasterPages from "./modules/HCP/components/pages/MasterPages";
import Mappings from "./modules/HCP/components/pages/Mappings";
import HistoryAlerts from "./modules/HCP/components/pages/HeadCountHistoricalAlertsPage";
import { RTDWidgetDataContextProvider } from "./modules/RTD/contextProviders/RTDWidgetDataContextProvider";

const AgentMarketPage = lazy(
  () => import("./modules/RTD/components/pages/AgentMarketPage")
);
const AppWidget = lazy(
  () => import("./modules/AppWidget/components/AppWidget")
);
const Login = lazy(() => import("./components/Login"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Unauthorized = lazy(() => import("./components/Unauthorized"));
const ShiftReport = lazy(() => import("./modules/WFH/components/ShiftReport"));
const UserReport = lazy(() => import("./modules/WFH/components/UserReport"));
const WFH = lazy(() => import("./modules/WFH/components/WFH"));
const UserPrivileges = lazy(
  () => import("./modules/ACM/components/UserPrivileges")
);
const VerintActivity = lazy(
  () => import("./modules/Verint/components/VerintActivity")
);
const Payroll = lazy(() => import("./modules/Verint/components/Payroll"));
const Agentskill = lazy(() => import("./modules/Verint/components/Agentskill"));
const Schedules = lazy(
  () => import("./modules/Verint/components/pages/Schedules")
);
const StaffingData = lazy(
  () => import("./modules/Verint/components/StaffingData")
);
const Overtime = lazy(
  () => import("./modules/Verint/components/pages/Overtime")
);
const GetTimeOff = lazy(
  () => import("./modules/TimeOff/components/GetTimeOff")
);
const UploadTimeOff = lazy(
  () => import("./modules/TimeOff/components/UploadTimeOff")
);
const Forecast = lazy(
  () => import("./modules/Forecast/components/ForecastDetails")
);
const AgentDomainPage = lazy(
  () => import("./modules/Forecast/components/pages/AgentDomainPage")
);
const AdminMasterPage = lazy(
  () => import("./modules/Forecast/components/pages/AdminMasterPage")
);
const Verint = lazy(() => import("./modules/Reports/components/Verint"));
const Blueprint = lazy(() => import("./modules/Reports/components/Blueprint"));
const AgentStatusPage = lazy(
  () => import("./modules/RTD/components/pages/AgentStatusPage")
);

const AdminPage = lazy(
  () => import("./modules/DNA/components/pages/AdminPage")
);
const WidgetPage = lazy(
  () => import("./modules/RTD/components/pages/WidgetPage")
);
const DashboardPage = lazy(
  () => import("./modules/RTD/components/pages/DashboardPage")
);
const AgentStaffingPage = lazy(
  () => import("./modules/RTD/components/pages/AgentStaffingPage")
);
const Collaborators = lazy(
  () => import("./modules/RTD/components/pages/Collaborators")
);
const VerintIntegration = lazy(() => import("./modules/Forecast/components/pages/VerintIntegrationPage"));
const ForecastCampaign = lazy(() => import("./modules/Forecast/components/pages/CampaignQueuePage"));
const ForecastVerintCampaignMapping = lazy(() => import("./modules/Forecast/components/pages/ForecastVerintCampaignMappingPage"));
const VerintIntegrationProfile = lazy(() => import("./modules/Forecast/components/pages/VerintIntegrationProfilePage"));
const ForecastDistributionCurve = lazy(() => import("./modules/Forecast/components/pages/DistributionCurvePage"));

const HeadCountPlannerPage = lazy(
  () => import("./modules/HCP/components/pages/HeadCountPlannerPage")
);
const HeadCountDetailPage = lazy(
  () => import("./modules/HCP/components/pages/HeadCountDetailPage")
);
const HCSolverPage = lazy(
  () => import("./modules/HCP/components/pages/HCSolverPage")
);
const HCPReports = lazy(
  () => import("./modules/HCP/components/pages/HeadCountPlannerReportsPage")
);
export const AppRoutes = () => {
  const location = useLocation();
  const commonService = useCommonService();

  commonService.setupApplication();

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="login" element={<Login />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />}>
            <Route
              index
              element={
                <Navigate to="/dashboard" state={{ from: location }} replace />
              }
            />
            <Route path="dashboard" element={<AppWidget />} />
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route path="wfo" element={<WFORoute />}>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.WFO]} />
                }
              >
                <Route index element={<WFH />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute
                    allowedPrivileges={[Privileges.ShiftReport]}
                  />
                }
              >
                <Route path="shiftreport" element={<ShiftReport />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.UserReport]} />
                }
              >
                <Route path="userreport" element={<UserReport />} />
              </Route>
            </Route>
            <Route path="acm" element={<ACMRoute />}>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.RoleDetail]} />
                }
              >
                <Route index element={<RolePrivilegePage />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute
                    allowedPrivileges={[Privileges.UserPrivileges]}
                  />
                }
              >
                <Route path="userprivileges" element={<UserPrivileges />} />
              </Route>
            </Route>
            <Route path="timeoff" element={<TimeOffRoute />}>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.GetTimeOff]} />
                }
              >
                <Route index element={<GetTimeOff />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute
                    allowedPrivileges={[Privileges.UploadTimeOff]}
                  />
                }
              >
                <Route path="uploadtimeoff" element={<UploadTimeOff />} />
              </Route>
            </Route>
            <Route path="verint" element={<VerintRoute />}>
              <Route
                element={
                  <AuthoriseRoute
                    allowedPrivileges={[Privileges.VerintActivity]}
                  />
                }
              >
                <Route index element={<VerintActivity />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.AgentSkill]} />
                }
              >
                <Route path="agentskill" element={<Agentskill />} />
              </Route>
              <Route path="schedules" element={<Schedules />} />
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.Staffing]} />
                }
              >
                <Route path="staffingdata" element={<StaffingData />} />
              </Route>
              <Route path="overtime" element={<Overtime />} />
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.Payroll]} />
                }
              >
                <Route path="payroll" element={<Payroll />} />
              </Route>
            </Route>
            <Route path="forecast" element={<ForecastRoute />}>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.CanView]} />
                }
              >
                <Route index element={<Forecast />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute
                    allowedPrivileges={[Privileges.Administration]}
                  />
                }
              >
                <Route path="administration" element={<AdminMasterPage />} />
              </Route>

              <Route
                element={
                  <AuthoriseRoute
                    allowedPrivileges={[
                      Privileges.CanViewAll,
                      Privileges.CanViewSubordinates,
                    ]}
                  />
                }
              >
                <Route
                  path="agentdomainmapping"
                  element={<AgentDomainPage />}
                />
              </Route>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.VerintIntegration]} />
                }
              >
                <Route path="verintintegration" element={<VerintIntegration />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.VerintIntegration]} />
                }
              >
                <Route path="campaignqueue" element={<ForecastCampaign />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.VerintIntegration]} />
                }
              >
                <Route path="verintcampaignmapping" element={<ForecastVerintCampaignMapping />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.VerintIntegration]} />
                }
              >
                <Route path="verintintegrationprofile" element={<VerintIntegrationProfile />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.VerintIntegration]} />
                }
              >
                <Route path="distributioncurve" element={<ForecastDistributionCurve />} />
              </Route>
            </Route>
            <Route path="report" element={<ReportRoute />}>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.VerintUser]} />
                }
              >
                <Route path="verint" element={<Verint />} />
              </Route>
              <Route
                element={
                  <AuthoriseRoute
                    allowedPrivileges={[Privileges.BlueprintUser]}
                  />
                }
              >
                <Route path="blueprint" element={<Blueprint />} />
              </Route>
              <Route path="rtd" element={<RTDRoute />}>
                <Route
                  element={
                    <AuthoriseRoute
                      allowedPrivileges={[
                        Privileges.Dashboard,
                        Privileges.ViewDashboard,
                      ]}
                    />
                  }
                >
                  <Route path="dashboard" element={<DashboardPage />} />
                </Route>
                <Route path="agentstatus" element={<AgentStatusPage />} />
                <Route path="agentmarket" element={<AgentMarketPage />} />
                <Route path="agentstaffing" element={<AgentStaffingPage />} />

                <Route
                  element={
                    <AuthoriseRoute
                      allowedPrivileges={[
                        Privileges.Dashboard,
                        Privileges.ViewDashboard,
                      ]}
                    />
                  }
                >
                  <Route path="widget" element={<RTDWidgetDataContextProvider><WidgetPage /></RTDWidgetDataContextProvider>} />
                  <Route path="collaborators" element={<Collaborators />} />
                </Route>
              </Route>
            </Route>

            <Route path="dna" element={<DNARoute />}>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.DNAAdmin]} />
                }
              >
                <Route path="admin" element={<AdminPage />} />
              </Route>
            </Route>
            <Route path="rtd" element={<RTDRoute />}>
              <Route path="agentstatus" element={<AgentStatusPage />} />
            </Route>
            <Route path="hcp" element={<HCPRoute />}>
              <Route
                element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.HeadCountPlanner]} />
                }
              >
                <Route element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.HeadCountPlanner]} />
                }>
                   <Route path="headcountplanner" element={<HeadCountPlannerPage />} />
                </Route>
                <Route element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.Employees]} />
                }>
                  <Route path="headcountdetail" element={<HeadCountDetailPage />} />
                </Route>
                <Route element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.MasterPages]} />
                }>
                    <Route path="masterpages" element={<MasterPages />} />
                </Route>
                <Route element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.Mappings]} />
                }>
                    <Route path="mappings" element={<Mappings />} />
                </Route>
                <Route element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.HCPReports]} />
                }>
                    <Route path="reports" element={<HCPReports />} />
                </Route>
                <Route path="alerts" element={<HistoryAlerts />} />
                <Route element={
                  <AuthoriseRoute allowedPrivileges={[Privileges.HCSolver]} />
                }>
                  <Route path="hcsolver" element={<HCSolverPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
