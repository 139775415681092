import Form from '@rjsf/core';
import React, { FC, useState } from 'react';
import './DynamicForm.css';
import PrwcDatePicker from "../../../UI/widgets/custom/PrwcDatePicker";
import PrwcTimePicker from "../../../UI/widgets/custom/PrwcTimePicker";
import PrwCheckbox from "../../../UI/widgets/form/PrwCheckbox";
import PrwDropdownBasic from "../../../UI/widgets/form/PrwDropdownBasic";
import PrwImageUpload from "../../../UI/widgets/form/PrwImageUpload";
import PrwInputText from "../../../UI/widgets/form/PrwInputText";
import PrwInputTextArea from "../../../UI/widgets/form/PrwInputTextArea";
import PrwRadioButton from "../../../UI/widgets/form/PrwRadioButton";
import PrwToggleButton from "../../../UI/widgets/form/PrwToggleButton";
import PrwColorPicker from "../../../UI/widgets/form/PrwColorPicker";
import PrwTreeView from '../../../UI/widgets/form/PrwTreeView';
import PrwAutoComplete from '../../../UI/widgets/form/PrwAutoComplete';

const DynamicForm = React.forwardRef((props: any, ref: any) => {
  const { schema, uiSchema, validationSchema, formData, formButtons } = props;
  const [liveValidate, setLiveValidate] = useState(false);

  const validatorWrapper = (
    formData: any,
    errors: any,
    validationSchemaLocal: any = validationSchema
  ) => {
    if (validationSchemaLocal) {
      for (let field in validationSchemaLocal) {
        let obj = validationSchemaLocal[field];
        let validators = obj.validators as any[];
        if (!!obj.validators && validators.length > 0) {
          for (let item of validators) {
            let value = formData[field];
            let isValid = item.validate(value);

            if (isValid === false) {
              errors[field].addError(item.messageKey);

              // Show only one error message at a time.
              break;
            }
          }
        } else {
          // This is required when we have nested object structure.
          validatorWrapper(
            formData[field],
            errors[field],
            validationSchemaLocal[field]
          );
        }
      }
    }

    return errors;
  };

  const transformErrors = (errors: any) => {
    return errors.map((error: any) => {
      if (error.name === "required" || error.name === "type") {
        error.message = null;
      }
      return error;
    });
  };
  
  const widgets = {
    prwInputText: PrwInputText,
    prwDropdownBasic: PrwDropdownBasic,
    prwImageUpload: PrwImageUpload,
    prwInputTextArea: PrwInputTextArea,
    prwCheckbox: PrwCheckbox,
    prwToggleButton: PrwToggleButton,
    prwcDatePicker: PrwcDatePicker,
    prwcTimePicker: PrwcTimePicker,
    prwRadioButton: PrwRadioButton,
    prwColorPicker: PrwColorPicker,
    prwTreeView : PrwTreeView,
    prwAutoComplete : PrwAutoComplete
  };

  const handleOnChange = (e: any) => {
    props.onChange(e.formData);
  };

  const handleError = (e: any) => {
    //setLiveValidate(true);
    props.onError(e);
  };

  return (
    <>
      <Form
        ref={ref}
        schema={schema}
        uiSchema={uiSchema}
        widgets={widgets}
        formData={formData}
        onChange={(e) => {
          handleOnChange(e);
        }}
        validate={validatorWrapper}
        transformErrors={transformErrors}
        showErrorList={false}
        liveValidate={liveValidate}
        noHtml5Validate
        onSubmit={props.onSubmit}
        onError={handleError}
        additionalMetaSchemas={[]}
        // ref={props.form}
      >
        {formButtons ? formButtons() : null}
      </Form>
    </>
  );
});
export default DynamicForm;
