import React,{ createContext, useContext, useState } from "react";
import { eWFMClientID } from '../constants/CommonConstant'
import { centralizedAuth } from '@cimpress/simple-auth-wrapper'
let authInfo: any = {};
const AuthContext = createContext(authInfo);

export const AuthContextProvider = (props: any) => {
    const [selectedTimezone, setSelectedTimezone] = useState({})

    let options = {
        clientID: eWFMClientID,
        redirectUri: window.location.origin,
        selectedTimezone,
        setSelectedTimezone
    }
    const auth = new centralizedAuth(options)

    auth.on('tokenExpired', () => {
        // This is invoked each time the current token in localStorage becomes expired.
        // This will also be immediately invoked if the token in localStorage is already expired. (configurable via emitInitialTokenExpired)
        console.log('The token in local storage is expired.')
        auth.logout("/")
    });
    
    return (
        <AuthContext.Provider value={auth}>
            {props.children}
        </AuthContext.Provider>
    );
}

export const useAuthContext = () => {
    return useContext(AuthContext);
}