import React from "react";
import { Outlet } from "react-router-dom";
import { VerintContextProvider } from "./contextProviders/VerintContextProvider";
import { VerintServiceProvider } from "./services/VerintService";

const VerintRoute = () => {
  return (
    <VerintServiceProvider>
      <VerintContextProvider>
        <Outlet />
      </VerintContextProvider>
    </VerintServiceProvider>
  );
};

export default VerintRoute;
