import React from "react";
import { Outlet } from "react-router-dom";
import { TimeOffContextProvider } from "./contextProviders/TimeOffContextProvider";
import { TimeOffServiceProvider } from "./services/TimeOffService";

const TimeOffRoute = () => {
    return(
      <TimeOffServiceProvider>
        <TimeOffContextProvider>
          <Outlet/>
        </TimeOffContextProvider>
      </TimeOffServiceProvider>
    ); 
}

export default TimeOffRoute;