import { Query } from '@datorama/akita';
import { ForecastState } from './forecast.model';
import { forecastStore, ForecastStore } from './forecast.store';

export class ForecastQuery extends Query<ForecastState> {
  constructor(protected store: ForecastStore) {
    super(store);
  }
}

export const forecastQuery = new ForecastQuery(forecastStore);