
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Breadcrumbs from "../../../../../components/Breadcrumb/Breadcrumb";
import { useEffect, useState } from "react";
import { MappingGenericConstants } from "../../../constants/HCPConstant";
import { Tab } from "@mui/material";
import { useHCPService } from "../../../services/HCPService";
import PrwcBasicButton from "../../../../UI/widgets/custom/buttons/PrwcBasicButton";
import { HCPMappingTabValue, OperationType } from "../../../../../enums/common.enum";
import PrwcSidebar from "../../../../UI/widgets/custom/PrwcSidebar";
import { useHCPContext } from "../../../contextProviders/HCPContextProvider";
import { useUserContext } from "../../../../../contextProviderFiles/UserContextProvider";
import { useCommonService } from "../../../../../services/CommonService";
import { checkUndefinedAndNull } from "../../../services/helper";
import "./Mappings.css";
import MappingList from "../../presentations/MappingList";
import ManageMappings from "../../presentations/ManageMappings";
import GlobalSearchFilter from "../../presentations/GlobalSearchFilter";
import { Button } from "primereact/button";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { newExportToExcelWorkGroupMappingFieldsArr, oldExportToExcelWorkGroupMappingFieldsArr, oldExportToExcelCostCenterMappingFieldsArr, newExportToExcelCostCenterMappingFieldsArr } from '../../../constants/HCPConstant';
import { bindReportFields, exportToCSV } from '../../../services/helper';
import dateFormat from "dateformat";

const Mappings = () => {
    const { setLoader, loggedUserInfo } = useUserContext();
    const [allBreadCrumbs, setAllBreadCrumbs] = useState<any>([]);
    const { mappingMasterData, setMappingMasterData } = useHCPContext();
    const hcpService = useHCPService();
    const [operationType, setOperationType] = useState(OperationType.ADD);
    const [isSideBarVisible, setIsSideBarVisible] = useState(false);
    const [AddRowData, setAddRowData] = useState<any>(null);
    const commonService = useCommonService();
    const [allDropdowns, setAllDropdowns] = useState([]);
    const [isDeleteLoader, setIsDeleteLoader] = useState(false);
    const [globalFilters, setGlobalFilters] = useState(undefined);
    const [mappingFilteredData, setMappingFilteredData] = useState<any>([]);

    const getCurrentTabInfo = (value: string) => {
        return MappingGenericConstants.Tabs.find((item) => item.value === value);
    };
    const [currentTabInfo, setCurrentTabInfo] = useState<any>(
        getCurrentTabInfo(hcpService.getAdminMasterActiveTabIndex())
    );

    useEffect(() => {
        setAllBreadCrumbs([
            {
                title: "P & F",
                link: "",
            },

            {
                title: "HeadCountPlanner",
                link: "/hcp/headcountplanner",
            },

            {
                title: "Mappings",
                link: "",
            },
        ]);
    }, []);

    useEffect(() => {
        fetchMappingListData();
    }, [currentTabInfo]);

    const fetchMappingListData = () => {
        let reqObj = {
            mappingMasterId: currentTabInfo?.value,
            userId: loggedUserInfo.userID,
        };
        setLoader(true);
        hcpService
            .getMappingDetails(reqObj)
            .then((res) => {
                var result = res.data;
                if (result.isSuccess) {
                    setMappingMasterData(result.mappingResults);
                    setLoader(false);
                } else {
                    setMappingMasterData([]);
                    setLoader(false);
                }
            })
            .catch((err) => {
                setLoader(false);
                commonService.showToast("error", err);
            });
    };
    const fetchDropdownData = async () => {
        setLoader(true);
        let reqObj = {
            mappingMasterId: currentTabInfo?.value,
        };

        const getDropdownRes = await hcpService.getDropdownDetails(reqObj);
        try {
            let result = getDropdownRes.data;
            if (result.isSuccess) {
                setAllDropdowns(result?.allDropDowns);
            } else {
                setAllDropdowns([]);
            }
            setLoader(false);
        } catch (err) {
            setLoader(false);
            commonService.showToast("error", err);
        }
    };

    const handleAddMaster = () => {
        setOperationType(OperationType.ADD);
        var defaultData = {
            id: 0,
            mappingMasterId: 0,
            // costCenterMapping: "",
            costCenterId: "",
            costCenterNo: "",
            workGroupId: "",
            costCenterMappingId: "",
            forecastGroupId: "",
            webCountryId: "",
            contactTypeId: "",
            staffingPriority: 1,
            site: "",
            level: "",
            organization: "",
            planningEntity: "",
            costCenterType:""
        };
        setAddRowData(defaultData);
        fetchDropdownData();
        setIsSideBarVisible(true);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTabInfo(getCurrentTabInfo(newValue.toString()));
        hcpService.setAdminMasterActiveTabIndex(newValue);
    };

    const handleEditMaster = (rowData) => {
        setOperationType(OperationType.EDIT);
        setAddRowData(rowData);
        fetchDropdownData();
        setIsSideBarVisible(true);
    };
    const exportToExcel = () => {
        setLoader(true);

        var date = new Date();
        var curDate = dateFormat(date, "yyyy_mm_dd");
        var fileName = "Mappings_" + curDate;
        var masterDataExport: any = [];
        var oldFieldArray: any = [],
            newFieldArray: any = [];
        if (currentTabInfo?.value === HCPMappingTabValue.Workgroup) {
            oldFieldArray = oldExportToExcelWorkGroupMappingFieldsArr;
            newFieldArray = newExportToExcelWorkGroupMappingFieldsArr;
        } else {
            oldFieldArray = oldExportToExcelCostCenterMappingFieldsArr;
            newFieldArray = newExportToExcelCostCenterMappingFieldsArr;
        }
        if (mappingFilteredData.length > 0)
            masterDataExport = bindReportFields(mappingFilteredData, oldFieldArray, newFieldArray);
        else
            masterDataExport = bindReportFields(mappingMasterData, oldFieldArray, newFieldArray);        
        exportToCSV(masterDataExport, fileName);
        setLoader(false);
    };

    const onValueChange = (e) => {
        if (mappingMasterData?.length === e.length)
            setMappingFilteredData([]);
        else
            setMappingFilteredData(e);
    }

    const onSaveSubmit = (rowData) => {
        setAddRowData([]);
        setAddRowData(rowData);
        onFinalSaveSubmit(rowData);
    };
    const onGlobalFilterCallback = (filters) => {
        setGlobalFilters(filters);
    };

    const getGlobalFilterContent = () => {
        return (<div className="search-widget-box">
            <GlobalSearchFilter
                onGlobalFilterCallback={onGlobalFilterCallback}
            />
        </div>)
    }

    const onFinalSaveSubmit = (rowData) => {
        setLoader(true);
        const reqObj = {
            "id": checkUndefinedAndNull(rowData.id) ? rowData.id : 0,
            "mappingMasterId": parseInt(currentTabInfo?.value) || 0,
            //"name": checkUndefinedAndNull(rowData.costCenterMapping) ? rowData.costCenterMapping : "",
            "costCenterId": checkUndefinedAndNull(rowData.costCenterId) ? rowData.costCenterId : 0,
            "site": checkUndefinedAndNull(rowData.site) ? rowData.site : "",
            "level": checkUndefinedAndNull(rowData.level) ? rowData.level : "",
            "organization": checkUndefinedAndNull(rowData.organization) ? rowData.organization : "",
            "planningEntity": checkUndefinedAndNull(rowData.planningEntity) ? rowData.planningEntity : "",
            "costCenterType": checkUndefinedAndNull(rowData.costCenterType) ? rowData.costCenterType : "",
            "costCenterMappingId": checkUndefinedAndNull(rowData.costCenterMappingId) ? rowData.costCenterMappingId : 0,
            "workGroupId": checkUndefinedAndNull(rowData.workGroup) ? rowData.workGroup : 0,
            "webCountryId": checkUndefinedAndNull(rowData.webCountry) ? rowData.webCountry : 0,
            "forecastGroupId": checkUndefinedAndNull(rowData.forecastGroup) ? rowData.forecastGroup : 0,
            "contactTypeId": checkUndefinedAndNull(rowData.contactType) ? rowData.contactType : 0,
            "staffingPriority": checkUndefinedAndNull(rowData.staffingPriority) ? rowData.staffingPriority : 0,
            "userId": loggedUserInfo.userID,
        };

        hcpService
            .manageMappingPages(reqObj)
            .then((res) => {
                var result = res.data;
                if (result.isSuccess) {
                    setLoader(false);
                    setIsSideBarVisible(false);
                    fetchMappingListData();
                    commonService.showToast("success", result.errorMessage);
                } else {
                    setLoader(false);
                    commonService.showToast("error", result.errorMessage);
                }
            })
            .catch((err) => {
                setLoader(false);
                commonService.showToast("error", err);
            });
    };

    const onDeleteChange = (data) => {
        let requestObj = {
            Id: data.id,
            MappingMasterId: currentTabInfo?.value,
        };
        const deleteReq = hcpService.deleteMappingDetails(requestObj);
        deleteReq
            .then((res) => {
                var result = res.data;
                if (result.isSuccess) {
                    fetchMappingListData();
                    fetchDropdownData();
                    setIsDeleteLoader(false);
                    commonService.showToast("success", result.errorMessage);
                } else {
                    setIsDeleteLoader(false);
                    commonService.showToast("error", result.errorMessage);
                }
            })
            .catch((err) => {
                setIsDeleteLoader(false);
                commonService.showToast("error", err);
            });
    };
    const HeaderContent = () => {
        return (
            <div className="cf">
                <div className="admin-lobbuttn">
                    <Button className='alert-butn export-alert-butn' onClick={() => exportToExcel()}>
                        <DownloadForOfflineOutlinedIcon />
                    </Button>
                    <PrwcBasicButton
                        labelKey={currentTabInfo?.addLabelKey}
                        color="primary"
                        variant="contained"
                        classObj={{}}
                        onClick={handleAddMaster}
                        sx={{ textAlign: "right" }}
                    ></PrwcBasicButton>
                    <PrwcSidebar
                        headerTitle={
                            operationType === OperationType.ADD
                                ? currentTabInfo?.addLabelKey
                                : currentTabInfo?.editLabelKey
                        }
                        isSideBarVisible={isSideBarVisible}
                        position="right"
                        showCloseIcon={true}
                        className="p-sidebar-md"
                        onHide={() => setIsSideBarVisible(false)}
                    >
                        <ManageMappings
                            operationType={operationType}
                            data={AddRowData}
                            onSaveSubmit={onSaveSubmit}
                            mappingMasterId={currentTabInfo?.value}
                            allDropdowns={allDropdowns}
                        />
                    </PrwcSidebar>
                </div>
            </div>
        );
    };

    const TabContent = () => {
        return (
            <div className="tab-content masterPage">
                <TabContext value={currentTabInfo?.value}>
                    <div className="tab-master" >
                        <TabList onChange={handleTabChange} aria-label="">
                            {MappingGenericConstants.Tabs.map((item, index) => {
                                return (
                                    <Tab key={index} label={item.headerKey} value={item.value} />
                                );
                            })}
                        </TabList>
                    </div>
                    <TabPanel value={currentTabInfo?.value}>
                        <MappingList
                            data={mappingMasterData}
                            onDeleteChange={onDeleteChange}
                            isDeleteLoader={isDeleteLoader}
                            onEdit={handleEditMaster}
                            mappingMasterId={currentTabInfo?.value}
                            globalFilters={globalFilters}
                            onValueChange={onValueChange} />
                    </TabPanel>
                </TabContext>
            </div>
        );
    };

    return (
        <>
            <div className="bread-crums-box">
                <Breadcrumbs allBreadCrumbs={allBreadCrumbs} />
            </div>
            <div className="white-board">
                {/* <div className="search-box-common"> */}
                {getGlobalFilterContent()}
                {/* </div> */}
                {HeaderContent()}
                {TabContent()}
            </div>
        </>
    );
};
export default Mappings;

