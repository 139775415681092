import { createContext, useContext, useState } from "react";

let dnaInfo: any = {};

const dnaContext = createContext(dnaInfo);

export const DNAContextProvider = (props: any) => {
    const [mappingMasterData, setMappingMasterData] = useState<any>([]); 
        
    dnaInfo = {      
        mappingMasterData,
        setMappingMasterData        
    }
    return (<>
        <dnaContext.Provider value={dnaInfo}>
            {props.children}
        </dnaContext.Provider>
    </>);
}

export const useDNaContext = () => {
    return useContext(dnaContext);
}