import { TreeTable } from "primereact/treetable";
import { forwardRef, useEffect, useState } from "react";
import { generateDynamicColumns } from "../../../../services/careDesignService";
import { Column } from "primereact/column";
import { checkUndefinedAndNull } from "../../../../services/helper";
import { Dialog } from "primereact/dialog";
import OpenTicketStatusDetail from "../OpenTicketStatusDetail";
import { IColumn } from "../../../../../../contracts/IColumn";

const IntervalList = forwardRef((props: any, ref) => {
  const { data, detailData, onChangeItem, widgetInfo, globalFilterInfo } = props;  
  const [columnToShow, setColumnToShow] = useState<any>([]);    
  const columnCount = 8; // Number of dynamic columns to generate
  const [multiSortMeta, setMultiSortMeta] = useState<any>([{field: "Name", order:1}]);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [selectedField, setSelectedField] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<any>(false);
  let newColumns : any = [];
  const [dynamicColumn, setDynamicColumn] = useState<any>([]);
  const columns: IColumn[] = [];
  
  useEffect(() => {        
    generateColumns();    
  }, [data, columnCount]);

  const generateColumns = () => {    
    const columnTemplate: IColumn = { 
        id: "",
        label:"",
        align: "center",
        visible: true,
        sortable: true,
        resizeable: true,
        minWidth: 100
    };
            
    const nameColumn : IColumn = { 
        id: "Name",
        label: "Name",
        minWidth: 400,
        align: "left",
        visible: true,
        sortable: true,
        resizeable: true
    };
    columns.push(nameColumn);

    const columnData = generateDynamicColumns(widgetInfo?.intervalWidgetType);
    for (const columnName in columnData) {
      if (columnData.hasOwnProperty(columnName)) {
        newColumns.push(columnName);
        setDynamicColumn(newColumns);

        const dynamicColumns : IColumn = { 
          ...columnTemplate,
          id: columnName,
          label: columnName,
          customTemplate: (e) => linkCustomTemplate(e, columnName)
        };
        columns.push(dynamicColumns);
      }
    }
        
    const totalColumn = { 
        id: "Total",
        label: "Total",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'Total')
    };
    columns.push(totalColumn);

    setColumnToShow(columns);
  };

  useEffect(()=>{
    let multiSortMeta = widgetInfo.multiSortMeta !== undefined 
      && widgetInfo.multiSortMeta !== null ? JSON.parse(widgetInfo.multiSortMeta) : [{field: "Name", order:1}]
    setMultiSortMeta(multiSortMeta)
  },[widgetInfo.multiSortMeta]);

  const onSaveColReorder = (item) => {
    let newDragIndex = item.dragIndex - 1;
        let newDropIndex = item.dropIndex - 1;
        var element = columnToShow[newDragIndex];
        columnToShow.splice(newDragIndex, 1);
        columnToShow.splice(newDropIndex, 0, element);

        let reorderColumns = columnToShow.map((item) => {
            return item.id;
        });
        let updatedWidgetInfo = {...widgetInfo, selectedColumns: JSON.stringify(reorderColumns)};
        onChangeItem(updatedWidgetInfo);
  };

  const onSort = (e) => {
    setMultiSortMeta(e.multiSortMeta)
    let updatedWidgetInfo = {...widgetInfo, "multiSortMeta": JSON.stringify(e.multiSortMeta)}
    onChangeItem(updatedWidgetInfo)
  }

  const linkCustomTemplate = (e, field) => {
    let cdata = e.data
    return (cdata[field] > 0 ? <a className="" onClick={() => {showRequestDetails(cdata,field)}}>{cdata[field]}</a> : '-')
  }

  const showRequestDetails = (currentData, currentField) => {
    setSelectedValue(currentData);
    setSelectedField(currentField);
    setShowDialog(true);
  }

  const onHide = () =>{
    setShowDialog(false)
  }

  const TicketStatusDetailContent = (rdata) => {
    let updatedMasterData = checkUndefinedAndNull(rdata) ? rdata : []
    if(checkUndefinedAndNull(selectedValue)){
      let groupedFieldValues = selectedValue.id.split(':')
      let groupedFields = selectedValue.idFields.split(':')

      groupedFields.forEach((item,i) => {
        let fieldValue = groupedFieldValues[i]
        updatedMasterData = updatedMasterData.filter(a=>a[item] === fieldValue)        
      });      
    }

    if(checkUndefinedAndNull(selectedField) && selectedField !== 'Total'){
      updatedMasterData = updatedMasterData.filter(a=>a.HoursBeforeDeadline === selectedField);        
    }
    else{
      updatedMasterData = updatedMasterData.filter(a => {        
        return a.HoursBeforeDeadline && dynamicColumn.includes(a.HoursBeforeDeadline);
      });
    }

    return(<>
      <Dialog visible={showDialog} 
        modal 
        header="Email Case Detail" 
        resizable
        style={{ width: '50rem' }} 
        onHide={() => onHide()}
      >
        <OpenTicketStatusDetail data={updatedMasterData}/>
      </Dialog>
    </>)
  }

  return (
    <>
        <div className="AgentMarketlist">
        <TreeTable
                  value={data}
                  onSort={onSort}
                  sortMode="multiple"
                  globalFilter={checkUndefinedAndNull(globalFilterInfo) ? globalFilterInfo['global'].value : ""}
                  filterMode = {checkUndefinedAndNull(globalFilterInfo) ? globalFilterInfo['global'].matchMode : ""}
                  multiSortMeta={multiSortMeta}
                  resizableColumns
                  removableSort
                  reorderableColumns
                  columnResizeMode="expand"
                  onColReorder={onSaveColReorder}
                  scrollable
                  scrollHeight={widgetInfo.dynamicHeight}
              >
              <Column expander style={{ width: "4em", justifyContent: "center" }} />
              {columnToShow.map((column, index) => {
                  return (
                  <Column
                      key={column.id}
                      hidden={!column.visible}
                      sortable={column.sortable !== undefined ? column.sortable : false}
                      resizeable={
                        column.resizeable !== undefined ? column.resizeable : false
                      }
                      field={column.id}
                      header={column.label}
                      body={column.customTemplate}
                  ></Column>
                  );
              })}
              </TreeTable>
        </div>
        {TicketStatusDetailContent(detailData)}
    </>
  );
});

export default IntervalList;