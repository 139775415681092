
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Breadcrumbs from "../../../../../components/Breadcrumb/Breadcrumb";
import { useEffect, useState } from "react";
import { GenericConstants, newExportToExcelCostCenterFieldsArr,newExportToExcelMasterPageFieldsArr,oldExportToExcelCostCenterFieldsArr,oldExportToExcelMasterPageFieldsArr } from "../../../constants/HCPConstant";
import { Tab } from "@mui/material";
import { useHCPService } from "../../../services/HCPService";
import PrwcBasicButton from "../../../../UI/widgets/custom/buttons/PrwcBasicButton";
import { HCPMasterPagesTabValue, OperationType } from "../../../../../enums/common.enum";
import PrwcSidebar from "../../../../UI/widgets/custom/PrwcSidebar";
import { useHCPContext } from "../../../contextProviders/HCPContextProvider";
import MasterPagesList from "../../presentations/MasterPagesList/MasterPagesList";
import { useUserContext } from "../../../../../contextProviderFiles/UserContextProvider";
import { useCommonService } from "../../../../../services/CommonService";
import ManageMasterPages from "../../presentations/ManageMasterPages";
import { checkUndefinedAndNull } from "../../../services/helper";
import GlobalSearchFilter from "../../presentations/GlobalSearchFilter";
import "./MasterPages.css";
import { bindReportFields, exportToCSV } from '../../../services/helper';
import dateFormat from "dateformat";
import { Button } from "primereact/button";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";

const MasterPages = () => {
    const { setLoader, loggedUserInfo } = useUserContext();
    const [allBreadCrumbs, setAllBreadCrumbs] = useState<any>([]);
    const { mappingMasterData, setMappingMasterData } = useHCPContext();
    const hcpService = useHCPService();
    const [operationType, setOperationType] = useState(OperationType.ADD);
    const [isSideBarVisible, setIsSideBarVisible] = useState(false);
    const [AddRowData, setAddRowData] = useState<any>(null);
    const commonService = useCommonService();
    const [isDeleteLoader, setIsDeleteLoader] = useState(false);
    const [globalFilters, setGlobalFilters] = useState(undefined);
    const [masterpageFilteredData, setMasterPageFilteredData] = useState<any>([]);

    const getCurrentTabInfo = (value: string) => {
        return GenericConstants.Tabs.find((item) => item.value === value);
    };
    const [currentTabInfo, setCurrentTabInfo] = useState<any>(
        getCurrentTabInfo(hcpService.getAdminMasterActiveTabIndex())
    );

    useEffect(() => {
        setAllBreadCrumbs([
            {
                title: "P & F",
                link: "",
            },

            {
                title: "HeadCountPlanner",
                link: "/hcp/headcountplanner",
            },

            {
                title: "MasterPages",
                link: "",
            },
        ]);
    }, []);

    useEffect(() => {
        fetchMasterPagesListData();
    }, [currentTabInfo]);

    const fetchMasterPagesListData = () => {
        let reqObj = {
            mappingMasterId: currentTabInfo?.value,
            userId: loggedUserInfo.userID,
        };
        setLoader(true);
        hcpService
            .getMasterPagesDetails(reqObj)
            .then((res) => {
                var result = res.data;
                if (result.isSuccess) {
                    setMappingMasterData(result.listResults);
                    setLoader(false);
                } else {
                    setMappingMasterData([]);
                    setLoader(false);
                }
            })
            .catch((err) => {
                setLoader(false);
                commonService.showToast("error", err);
            });
    };
    const handleAddMaster = () => {
        setOperationType(OperationType.ADD);
        var defaultData = {
            id: 0,
            mappingMasterId: 0,
            name: "",
            description: "",
            costCenterNo: "",
        };
        setAddRowData(defaultData);
        setIsSideBarVisible(true);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTabInfo(getCurrentTabInfo(newValue.toString()));
        hcpService.setAdminMasterActiveTabIndex(newValue);
    };

    const handleEditMaster = (rowData) => {
        setOperationType(OperationType.EDIT);
        setAddRowData(rowData);
        setIsSideBarVisible(true);
    };

    const onSaveSubmit = (rowData) => {
        setAddRowData([]);
        setAddRowData(rowData);
        onFinalSaveSubmit(rowData);
    };
    const onGlobalFilterCallback = (filters) => {
        setGlobalFilters(filters);
    };

    const getGlobalFilterContent = () => {
        return (<div className="search-widget-box">
            <GlobalSearchFilter
                onGlobalFilterCallback={onGlobalFilterCallback}
            />
        </div>)
    }

    const onFinalSaveSubmit = (rowData) => {
        setLoader(true);
        const reqObj = {
            "id": checkUndefinedAndNull(rowData.id) ? rowData.id : 0,
            "mappingMasterId": parseInt(currentTabInfo?.value) || 0,
            "name": checkUndefinedAndNull(rowData.name) ? rowData.name : "",
            "description": checkUndefinedAndNull(rowData.description) ? rowData.description : "",
            "costCenterNo": checkUndefinedAndNull(rowData.costCenterNo) ? rowData.costCenterNo : "",
            "userId": loggedUserInfo.userID,
        };

        hcpService
            .manageMasterPages(reqObj)
            .then((res) => {
                var result = res.data;
                if (result.isSuccess) {
                    setLoader(false);
                    setIsSideBarVisible(false);
                    fetchMasterPagesListData();
                    commonService.showToast("success", result.errorMessage);
                } else {
                    setLoader(false);
                    commonService.showToast("error", result.errorMessage);
                }
            })
            .catch((err) => {
                setLoader(false);
                commonService.showToast("error", err);
            });
    };
    const exportToExcel = () => {
        setLoader(true);

        var date = new Date();
        var curDate = dateFormat(date, "yyyy_mm_dd");
        var fileName = "MasterPages_" + curDate;
        var masterDataExport: any = [];
        var oldFieldArray: any = [],
            newFieldArray: any = [];
        if (currentTabInfo?.value === HCPMasterPagesTabValue.Workgroup || currentTabInfo?.value === HCPMasterPagesTabValue.ContactType ||currentTabInfo?.value === HCPMasterPagesTabValue.WebCountry ||currentTabInfo?.value === HCPMasterPagesTabValue.ForecastGroup) {
            oldFieldArray = oldExportToExcelMasterPageFieldsArr;
            newFieldArray = newExportToExcelMasterPageFieldsArr;
        } else {
            oldFieldArray = oldExportToExcelCostCenterFieldsArr;
            newFieldArray = newExportToExcelCostCenterFieldsArr;
        }
        if (masterpageFilteredData.length > 0)
            masterDataExport = bindReportFields(masterpageFilteredData, oldFieldArray, newFieldArray);
        else
            masterDataExport = bindReportFields(mappingMasterData, oldFieldArray, newFieldArray);
        exportToCSV(masterDataExport, fileName);
        setLoader(false);
    };

    const onValueChange = (e) => {
        if (mappingMasterData?.length === e.length)
            setMasterPageFilteredData([]);
        else
            setMasterPageFilteredData(e);
    }
    const onDeleteChange = (data) => {
        let requestObj = {
            Id: data.id,
            MappingMasterId: currentTabInfo?.value,
        };
        const deleteReq = hcpService.deleteMasterPagesDetails(requestObj);
        deleteReq
            .then((res) => {
                var result = res.data;
                if (result.isSuccess) {
                    fetchMasterPagesListData();
                    setIsDeleteLoader(false);
                    commonService.showToast("success", result.errorMessage);
                } else {
                    setIsDeleteLoader(false);
                    commonService.showToast("error", result.errorMessage);
                }
            })
            .catch((err) => {
                setIsDeleteLoader(false);
                commonService.showToast("error", err);
            });
    };
    const HeaderContent = () => {
        return (
            <div className="cf">
                <div className="admin-lobbuttn">
                    <Button className='alert-butn export-alert-butn' onClick={() => exportToExcel()}>
                        <DownloadForOfflineOutlinedIcon />
                    </Button>
                    {currentTabInfo?.value !== HCPMasterPagesTabValue.CostCenter && (
                    <>
                    <PrwcBasicButton
                        labelKey={currentTabInfo?.addLabelKey}
                        color="primary"
                        variant="contained"
                        classObj={{}}
                        onClick={handleAddMaster}
                        sx={{ textAlign: "right" }}
                    ></PrwcBasicButton>
                    <PrwcSidebar
                        headerTitle={
                            operationType === OperationType.ADD
                                ? currentTabInfo?.addLabelKey
                                : currentTabInfo?.editLabelKey
                        }
                        isSideBarVisible={isSideBarVisible}
                        position="right"
                        showCloseIcon={true}
                        className="p-sidebar-md"
                        onHide={() => setIsSideBarVisible(false)}
                    >
                        <ManageMasterPages
                            operationType={operationType}
                            data={AddRowData}
                            onSaveSubmit={onSaveSubmit}
                            mappingMasterId={currentTabInfo?.value}
                        />
                    </PrwcSidebar>
                    </>
                    )}
                </div>
            </div>
        );
    };

    const TabContent = () => {
        return (
            <div className="tab-content masterPage">
                <TabContext value={currentTabInfo?.value}>
                    <div className="tab-master" >
                        <TabList onChange={handleTabChange} aria-label="">
                            {GenericConstants.Tabs.map((item, index) => {
                                return (
                                    <Tab key={index} label={item.headerKey} value={item.value} />
                                );
                            })}
                        </TabList>
                    </div>
                    <TabPanel value={currentTabInfo?.value}>
                        <MasterPagesList
                            data={mappingMasterData}
                            onDeleteChange={onDeleteChange}
                            isDeleteLoader={isDeleteLoader}
                            onEdit={handleEditMaster}
                            mappingMasterId={currentTabInfo?.value}
                            globalFilters={globalFilters}
                            onValueChange={onValueChange}
                        />
                    </TabPanel>
                </TabContext>
            </div>
        );
    };

    return (
        <>
            <div className="bread-crums-box">
                <Breadcrumbs allBreadCrumbs={allBreadCrumbs} />
            </div>
            <div className="white-board">
                {getGlobalFilterContent()}
                {HeaderContent()}
                {TabContent()}
            </div>
        </>
    );
};
export default MasterPages;

