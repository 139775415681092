import React, { Suspense } from "react";
import "./index.css";
import "./black-theme.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import LoadingPage from "../src/components/LoadingPage"
import ReactDOM from "react-dom/client"
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <BrowserRouter>
    <Suspense fallback={<LoadingPage />}>
      <App />
    </Suspense>
  </BrowserRouter>
)
reportWebVitals()
