import React, { createContext, useContext } from "react";
import { io } from "socket.io-client";
import { useAxiosContext } from "../../../contextProviderFiles/AxiosContextProvider";
import {
  getAgentNamesUrl,
  getAgentStausUrl,
  getContactStatusUrl,
  getRoutingProfileUrl,
  getFilterSettingsUrl,
  RTDConstants,
  getWidgetsUrl,
  saveWidgetsUrl,
  getWidgetTypesUrl,
  saveFilterSettingUrl,
  deleteFilterSettingUrl,
  saveRTDashboardUrl,
  getRTDashboardUrl,
  deleteRTDashboardUrl,
  getStaffingQueuesUrl,
  getMarketsAndQueuesUrl,
  isFavouriteDashboardUrl,
  getQueueMappingsUrl,
  getRolesUrl,
  shareDashboardUrl,
  GetshareDashboardUrl,
  getUsersDashboardShareRoleUrl,
  getShareDashboardCollaboratorsUrl,
  deleteShareDashboardCollaboratorUrl,
  updateSharedCollaboratorPermissionUrl,
  saveConditionalFormatsUrl,
  deleteConditionalFormatUrl, 
  getConditionalFormatingUrl,
  getRulesByConditionalFormatIdUrl,
  compareValues,
  StatusStartTimestamp,
  getTeamLeadNOrgUrl,
  OnHoldTimestamp
} from "../constants/RTDConstants"
import { StaffingHelpersData } from "./staffingService"
import { checkUndefinedAndNullEmpty } from "./helper"
import Settings from "../utils/settings"


let operationsAllowed: any = {};
const RTDServiceContext = createContext(operationsAllowed);

export const RTDServiceProvider = (props) => {
  const { token, axiosObj } = useAxiosContext();
  let socket;

  const initialSocketConnection = (iSocket) => {
    let serviceUrl = process.env.REACT_APP_RTD_URL !== undefined
        ? process.env.REACT_APP_RTD_URL : ""
    
    if (iSocket === undefined) {
      iSocket = io(serviceUrl, {
        withCredentials: true,
        extraHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
      console.log(`Connecting socket...`)
    } 
    return iSocket;
  }

  const disconnectSocket = (socket) => {
    console.log("Disconnecting socket...")

    if (socket) {
      socket.off("agent-status-data");
      socket.off("status");
      socket.off("market");
      socket.off("domains_loaded");
      socket.off("Queue_loaded");
      socket.off("StaffingData");
      socket.off("StaffingQueues");
      socket.disconnect();
    }
  }

  const unsubscribeAgentStaffingEvent = () => {
    if (socket) {
      socket.off("StaffingData");
    }
  };

  const getAgentStaffingQueues = () => {
    return axiosObj.get(getStaffingQueuesUrl);
  }

  const emitAgentStaffingData = (): any => {
    socket.emit("getAgentStaffingData");
  };

  const updateAgentStaffing = (filterObj, cb) => {
    if (!socket) return true;
    socket.on("StaffingData", (currentData) => {
      console.log("staffing updated called");
      const updatedData = StaffingHelpersData(
        currentData,
        filterObj?.filterValues
      );
      return cb(null, updatedData);
    });
  }

  const getColor = (AgentStatus: string, ContactState: string) => {
    var statusClass = "transparent";

    switch (AgentStatus) {
      case "Phone":
        switch (ContactState) {
          case "":
            statusClass = "EMPTY";
            break;
          default:
            statusClass = ContactState;
        }
        break;
      default:
        statusClass = AgentStatus;
        break;
    }

    return statusClass.replace(/ /g, "_").replace("/", "_");
  };

  const timeTillNowDistance = (agent, onHoldTime?): number => {
    let distance;
    if ([RTDConstants.Connected, RTDConstants.OnHold].includes(agent.ContactState)) {
      const time = onHoldTime ?? new Date(agent.ConnectedToAgentTimestamp).getTime();
      distance = new Date().getTime() - time;
    } else
      distance = new Date().getTime() - new Date(agent.EventTimestamp).getTime();

    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    const d = new Date(Date.UTC(1970, 0, 1, hours, minutes, seconds, 0));
    return d.getTime()
  };

  const timeTillNow = (agent, onHoldTime?): string => {
    let distance;
    if (
      agent.ContactState === RTDConstants.Connected ||
      agent.ContactState === RTDConstants.OnHold
    ) {
      var time = onHoldTime
        ? onHoldTime
        : new Date(agent.ConnectedToAgentTimestamp).getTime();
      distance = new Date().getTime() - time;
    } else
      distance =
        new Date().getTime() - new Date(agent.EventTimestamp).getTime();

    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    var d = new Date(Date.UTC(1970, 0, 1, hours, minutes, seconds, 0));
    return d.toLocaleTimeString("en-GB", { timeZone: "UTC" });
  };

  const getRoutingProfiles = () => {
    return axiosObj.get(getRoutingProfileUrl);
  };

  const getAgentStatuses = () => {
    return axiosObj.get(getAgentStausUrl);
  };

  const getContactStatuses = () => {
    return axiosObj.get(getContactStatusUrl);
  };

  const getAgentNames = () => {
    return axiosObj.get(getAgentNamesUrl);
  };

  const getFilterSettings = (request) => {
    return axiosObj.post(getFilterSettingsUrl, request);
  };

  const saveFilterSetting = (request) => {
    return axiosObj.post(saveFilterSettingUrl, request);
  };

  const deleteFilterSetting = (request) => {
    return axiosObj.post(deleteFilterSettingUrl, request);
  };

  const getWidgetTypes = (request) => {
    return axiosObj.post(getWidgetTypesUrl, request);
  };
  const getWidgets = async (request) => {
    return axiosObj.post(getWidgetsUrl, request);
  };
  const saveWidgets = (request) => {
    return axiosObj.post(saveWidgetsUrl, request);
  };
  const getAllDashboards = (request) => {
    return axiosObj.post(getRTDashboardUrl, request);
  };
  const saveRTDashboard = (request) => {
    return axiosObj.post(saveRTDashboardUrl, request);
  };

  const deleteRTDashboard = (request) => {
    return axiosObj.post(deleteRTDashboardUrl, request);
  };

  const getMarketsAndQueues = (request) => {
    return axiosObj.get(getMarketsAndQueuesUrl, request);
  };

  const isFavouriteDashboard = (request) => {
    return axiosObj.post(isFavouriteDashboardUrl, request);
  };

  const getQueueMappings = (request) => {
    return axiosObj.post(getQueueMappingsUrl, request);
  };

  const getRoles = (request) => {
    return axiosObj.post(getRolesUrl, request);
  };
  const shareDashboard = (request) => {
    return axiosObj.post(shareDashboardUrl, request);
  };
  const getShareDashboard = (request) => {
    return axiosObj.post(GetshareDashboardUrl, request);
  };
  const getUsersDashboardShareRole = (request) => {
    return axiosObj.post(getUsersDashboardShareRoleUrl, request);
  };
  const getShareDashboardCollaborators = (request) => {
    return axiosObj.post(getShareDashboardCollaboratorsUrl, request);
  };
  const deleteShareDashboardCollaborator = (request) => {
    return axiosObj.post(deleteShareDashboardCollaboratorUrl, request);
  };
  const updateSharedCollaboratorPermission = (request) => {
    return axiosObj.post(updateSharedCollaboratorPermissionUrl, request);
  };
  const saveConditionalFormats = (request) => {
    return axiosObj.post(saveConditionalFormatsUrl, request);
  };
  const deleteConditionalFormat = (request) => {
    return axiosObj.post(deleteConditionalFormatUrl, request);
  };
  
  const getConditionalFormating = (request) => {
    return axiosObj.post(getConditionalFormatingUrl, request);
  };
  const getRulesByConditionalFormatId = (request) => {
    return axiosObj.post(getRulesByConditionalFormatIdUrl, request);
  };
  const getTeamLeadNOrgs = (request) => {
    return axiosObj.post(getTeamLeadNOrgUrl, request);
  };

  const applyConditionalFormating = (rules, obj, column) => {
    let valObj = obj.data !== undefined ? obj.data : obj
    let columnName = column.id
    let comparisonResult = false;
    let bgColor = ''
    let foreColor = ''
    let fontsize = 12
    let specificRules = rules.filter(a=>a.column === columnName)
    if(specificRules.length > 0){
      specificRules.map(item => {
        //let previousConditionColumn: string | null = null;
        let result = true
        const { backcolor, color, fontSize, conditions} = item
        let conditionList = JSON.parse(conditions)
        conditionList.sort((a, b) => {
          if (a.conditionColumn < b.conditionColumn) {
            return -1;
          } else if (a.conditionColumn > b.conditionColumn) {
            return 1;
          } else {
            return 0;
          }
        })
        conditionList.forEach((item, i) => {
          const { conditionColumn, operator, value, logicalOp } = item
          let targetValue = value
          let sourceValue = valObj[conditionColumn]
          let valueResult
          if(conditionColumn === StatusStartTimestamp){
            var distance: number = new Date().getTime() - new Date(obj.EventTimestamp).getTime()
            valueResult = compareValues(distance,operator,Settings.getStatusThresholdsTime(value))
          }else{
            valueResult = compareValues(sourceValue,operator,targetValue)
          }

          if(checkUndefinedAndNullEmpty(logicalOp))
          {
            switch(logicalOp)
            {
              case 'and': 
                result = result && valueResult
                break
              case 'or' :
                result = result || valueResult
                break 
            }
          }
          else{
            result = result && valueResult
          }
        })

        if(result){
          bgColor = backcolor
          foreColor = color
          fontsize = fontSize
        }
        return comparisonResult = comparisonResult || result
      })
      return comparisonResult ? (
          <div style={{backgroundColor:  bgColor, color: foreColor, fontSize: fontsize}}>
            {column.customTemplate !== undefined ? column.customTemplate(obj): valObj[columnName] }
          </div>)
        : column.customTemplate !== undefined ? column.customTemplate(obj): (<div>{valObj[columnName] }</div>)
    }
    else{
      return column.customTemplate !== undefined ? column.customTemplate(obj): (<div>{valObj[columnName]}</div>)
    }
  }
  
  
  operationsAllowed = {
    disconnectSocket,
    timeTillNow,
    getColor,
    getRoutingProfiles,
    getAgentStatuses,
    getContactStatuses,
    getAgentNames,
    getWidgets,
    saveWidgets,
    getWidgetTypes,
    getFilterSettings,
    saveFilterSetting,
    deleteFilterSetting,
    saveRTDashboard,
    getAllDashboards,
    deleteRTDashboard,
    emitAgentStaffingData,
    updateAgentStaffing,
    unsubscribeAgentStaffingEvent,
    getAgentStaffingQueues,
    initialSocketConnection,
    getMarketsAndQueues,
    isFavouriteDashboard,
    getQueueMappings,
    getRoles,
    shareDashboard,
    getShareDashboard,
    getUsersDashboardShareRole,
    getShareDashboardCollaborators,
    deleteShareDashboardCollaborator,
    updateSharedCollaboratorPermission,
    saveConditionalFormats,
    deleteConditionalFormat, 
    getConditionalFormating,
    getRulesByConditionalFormatId,
    applyConditionalFormating,
    getTeamLeadNOrgs,
    timeTillNowDistance
  };

  return (
    <RTDServiceContext.Provider value={operationsAllowed}>
      {props.children}
    </RTDServiceContext.Provider>
  );
};

export const useRTDService = () => {
  return useContext(RTDServiceContext);
};
