import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import { forwardRef, useEffect, useState } from "react";
import { IColumn } from "../../../../../../contracts/IColumn";
import { GetColumnToCustomTemplate, GetColumnToShow, checkUndefinedAndNull, getChangeColumnHeader } from "../../../../services/helper";
import { useRTDService } from "../../../../services/RTDService";
import EmailCaseDetail from "../EmailCaseDetail/EmailCaseDetail";
import { Dialog } from "primereact/dialog";

const EmailAnalysisList = (props: any) => {
    const { data, detailData, onChangeItem, widgetInfo, globalFilterInfo } = props
    const rtdService = useRTDService()
    const columns: IColumn[] = [
      {
        id: "Name",
        label: "Domain",
        minWidth: 400,
        align: "left",
        visible: true,
        sortable: true,
        resizeable: true
      },
      {
        id: "<8",
        label: "<8",
        visible: true,
        align: "center",
        sortable: true,
        resizeable: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'<8')
      },
      {
        id: "8-16",
        label: "8-16",
        visible: true,
        align: "center",
        sortable: true,
        resizeable: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'8-16')
      },
      {
        id: "16-22",
        label: "16-22",
        visible: true,
        align: "right",
        sortable: true,
        resizeable: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'16-22')
      },
      {
        id: "22-24",
        label: "22-24",
        visible: true,
        align: "right",
        sortable: true,
        resizeable: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'22-24')
      },
      {
        id: "24-48",
        label: "24-48",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'24-48')
      },
      {
        id: "48-72",
        label: "48-72",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'48-72')
      },
      {
        id: "72-120",
        label: "72-120",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'72-120')
      },
      {
        id: "120-240",
        label: "120-240",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'120-240')
      },
      {
        id: ">240",
        label: ">240",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'>240')
      },
      {
        id: "Total",
        label: "Total",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'Total')
      }
    ]
    const [showDialog, setShowDialog] = useState<any>(false)
    const [columnToShow, setColumnToShow] = useState<any>(columns);
    //const [formattedColumns, setFormattedColumns] = useState<any>(columns);
    const [multiSortMeta, setMultiSortMeta] = useState<any>([{field: "QueueName", order:1}])
    const [selectedValue, setSelectedValue] = useState<any>(null)
    const [selectedField, setSelectedField] = useState<any>(null)
    
    useEffect(()=>{
        let multiSortMeta = widgetInfo.multiSortMeta !== undefined && widgetInfo.multiSortMeta !== null ? JSON.parse(widgetInfo.multiSortMeta) : [{field: "QueueName", order:1}]
        setMultiSortMeta(multiSortMeta)
    },[widgetInfo.multiSortMeta])
  
    useEffect(() => {
      let updatedColumns = GetColumnToCustomTemplate(rtdService,columns, widgetInfo.appliedCFRules);
      //setFormattedColumns(updatedColumns)
      let filterColumns = GetColumnToShow(updatedColumns, widgetInfo.selectedColumns);
      let groupColumns = getChangeColumnHeader(widgetInfo,filterColumns,'Name')
      setColumnToShow(groupColumns)
    }, [widgetInfo.groupField,widgetInfo.selectedColumns,widgetInfo.appliedCFRules])

    const linkCustomTemplate = (e,field) => {
      let cdata = e.data
      return (cdata[field] > 0 ? <a className="" onClick={() => {showCaseDetails(cdata,field)}}>{cdata[field]}</a>: '-')
    }

    const showCaseDetails = (currentData, currentField) => {
      setSelectedValue(currentData)
      setSelectedField(currentField)
      setShowDialog(true)
    }

    const onHide = () =>{
      setShowDialog(false)
    }

    const onSaveColReorder = (item) => {
        let newDragIndex = item.dragIndex - 1;
        let newDropIndex = item.dropIndex - 1;
        var element = columnToShow[newDragIndex];
        columnToShow.splice(newDragIndex, 1);
        columnToShow.splice(newDropIndex, 0, element);

        let reorderColumns = columnToShow.map((item) => {
            return item.id;
        });
        let updatedWidgetInfo = {...widgetInfo, selectedColumns: JSON.stringify(reorderColumns)}
        onChangeItem(updatedWidgetInfo)
    }

    const onSort = (e) => {
        setMultiSortMeta(e.multiSortMeta)
        let updatedWidgetInfo = {...widgetInfo, "multiSortMeta": JSON.stringify(e.multiSortMeta)}
        onChangeItem(updatedWidgetInfo)
    }

    const caseDetailContent = (data) => {
      let updatedCaseData =  checkUndefinedAndNull(data) ? data : []
      if(checkUndefinedAndNull(selectedValue)){
        let groupedFieldValues = selectedValue.id.split(':')
        let groupedFields = selectedValue.idFields.split(':')

        groupedFields.forEach((item,i) => {
          let fieldValue = groupedFieldValues[i]
          updatedCaseData = updatedCaseData.filter(a=>a[item] === fieldValue)
        });
      }

      if(selectedField !== 'Total')
        updatedCaseData = updatedCaseData.filter(a=>a.AgeLabel === selectedField)

      
      return(<>
          <Dialog visible={showDialog} 
            modal 
            header="Email Case Detail" 
            resizable
            style={{ width: '50rem' }} 
            onHide={() => onHide()}
          >
            <EmailCaseDetail data={updatedCaseData}/>
          </Dialog>
      </>)
    }

    return (
      <>
        <div className="EmailCaseAgelist">
            <TreeTable
                value={data}
                globalFilter={globalFilterInfo !== undefined ? globalFilterInfo['global'].value : ""}
                filterMode = {globalFilterInfo !== undefined ? globalFilterInfo['global'].matchMode : ""}
                onSort={onSort}
                sortMode="multiple"
                multiSortMeta={multiSortMeta}
                resizableColumns
                reorderableColumns
                removableSort
                columnResizeMode="expand"
                onColReorder={onSaveColReorder}
                scrollable
                scrollHeight={widgetInfo.dynamicHeight}
            >
              <Column expander style={{ width: "4em", justifyContent: "center" }} />
              {columnToShow.map((column, index) => {
                  return (
                    <Column
                        key={index}
                        hidden={!column.visible}
                        sortable={column.sortable !== undefined ? column.sortable : false}
                        resizeable={
                        column.resizeable !== undefined ? column.resizeable : false
                        }
                        field={column.id}
                        header={column.label}
                        body={column.customTemplate}
                    ></Column>
                  );
              })}
            </TreeTable>
        </div>
        {caseDetailContent(detailData)}
      </>
    )
}
export default EmailAnalysisList