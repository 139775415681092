import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import { forwardRef, useEffect, useState } from "react";
import { IColumn } from "../../../../../../contracts/IColumn";
import "./MarketList.css";
import { GetColumnToCustomTemplate, GetColumnToShow, checkUndefinedAndNull, getChangeColumnHeader } from "../../../../services/helper";
import {
  MarketHelpersOldesttime,
  MarketHelpersSLAThreshold,
} from "../../../../services/marketservice";
import { useRTDService } from "../../../../services/RTDService";
const MarketList = forwardRef((props: any, ref) => {
  const { data, onChangeItem, widgetInfo, globalFilterInfo } = props

  const rtdService = useRTDService()

  const columns: IColumn[] = [
    {
      id: "QueueName",
      label: "Domain",
      minWidth: 400,
      align: "left",
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "timeZone",
      label: "Timezone",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100,
    },
    {
      id: "CONTACTS_IN_QUEUE",
      label: "Queue",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100,
    },
    {
      id: "CONTACTS_HANDLED",
      label: "Handled",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100,
    },
    {
      id: "CONTACTS_ABANDONED",
      label: "Abandoned",
      visible: true,
      align: "right",
      sortable: true,
      resizeable: true,
      minWidth: 100,
    },
    {
      id: "ABANDONED_RATE",
      label: "Aband. Rate",
      sortable: true,
      resizeable: true,
      visible: true,
      minWidth: 100,
      customTemplate: (e) => `${e.data.ABANDONED_RATE?.toFixed(1)} %`,
    },
    {
      id: "CONTACTS_QUEUED",
      label: "Offered",
      visible: true,
      align: "right",
      sortable: true,
      resizeable: true,
      minWidth: 100,
    },
    {
      id: "OLDEST_CONTACT_AGE",
      label: "QTime",
      visible: true,
      sortable: true,
      minWidth: 100,
      resizeable: true,
      customTemplate: (e) => MarketHelpersOldesttime(e.data.OLDEST_CONTACT_AGE),
    },
    {
      id: "SERVICE_LEVEL",
      label: "SL",
      visible: true,
      sortable: true,
      minWidth: 100,
      resizeable: true,
      customTemplate: (e) => `${e.data.SERVICE_LEVEL?.toFixed(1)} %`,
      defaultFormat: (e) => {
        let value = e.data.SERVICE_LEVEL;
        return (
          <div className={MarketHelpersSLAThreshold(value)}>
            {value?.toFixed(1)}%
          </div>
        );
      },
    },
  ];

  const [columnToShow, setColumnToShow] = useState<any>(columns);
  //const [formattedColumns, setFormattedColumns] = useState<any>(columns);
  const [multiSortMeta, setMultiSortMeta] = useState<any>([{field: "QueueName", order:1}]);

  useEffect(()=>{
    let multiSortMeta = widgetInfo.multiSortMeta !== undefined && widgetInfo.multiSortMeta !== null ? JSON.parse(widgetInfo.multiSortMeta) : [{field: "QueueName", order:1}]
    setMultiSortMeta(multiSortMeta)
  },[widgetInfo.multiSortMeta])

  // useEffect(() => {
  //   let filterColumns = GetColumnToShow(formattedColumns, widgetInfo.selectedColumns)
  //   let groupColumns = getChangeColumnHeader(widgetInfo,filterColumns)
  //   setColumnToShow(groupColumns)
  // },[widgetInfo.groupField,widgetInfo.selectedColumns])

  useEffect(() => {
    let updatedColumns = GetColumnToCustomTemplate(rtdService,columns, widgetInfo.appliedCFRules);
    //setFormattedColumns(updatedColumns)
    let filterColumns = GetColumnToShow(updatedColumns, widgetInfo.selectedColumns)
    let groupColumns = getChangeColumnHeader(widgetInfo,filterColumns)
    setColumnToShow(groupColumns);
  }, [widgetInfo.groupField,widgetInfo.selectedColumns,widgetInfo.appliedCFRules])

  
  const onSaveColReorder = (item) => {
    let newDragIndex = item.dragIndex - 1;
    let newDropIndex = item.dropIndex - 1;
    var element = columnToShow[newDragIndex];
    columnToShow.splice(newDragIndex, 1);
    columnToShow.splice(newDropIndex, 0, element);

    //onColReorder(columnToShow);
    let reorderColumns = columnToShow.map((item) => {
      return item.id;
    });
    let updatedWidgetInfo = {...widgetInfo, selectedColumns: JSON.stringify(reorderColumns)}
    onChangeItem(updatedWidgetInfo)
  };

  const onSort = (e) => {
    //setSortField(e.sortField);
    //setSortOrder(e.sortOrder);
    setMultiSortMeta(e.multiSortMeta)
    let updatedWidgetInfo = {...widgetInfo, "multiSortMeta": JSON.stringify(e.multiSortMeta)}//, "sortOrder": e.sortOrder, "sortField" : e.sortField}
    onChangeItem(updatedWidgetInfo)
  }

  return (
    <div className="AgentMarketlist">
      <TreeTable
        value={data}
        //sortField={sortField}
        //sortOrder={sortOrder}       
        globalFilter={checkUndefinedAndNull(globalFilterInfo) ? globalFilterInfo['global'].value : ""}
        filterMode = {checkUndefinedAndNull(globalFilterInfo) ? globalFilterInfo['global'].matchMode : ""}
        onSort={onSort}
        sortMode="multiple"
        multiSortMeta={multiSortMeta}
        resizableColumns
        reorderableColumns
        removableSort
        columnResizeMode="expand"
        onColReorder={onSaveColReorder}
        scrollable
        scrollHeight={widgetInfo.dynamicHeight}
        // style={{maxHeight: widgetInfo.dynamicHeight}}
      >
        <Column expander style={{ width: "4em", justifyContent: "center" }} />
        {columnToShow.map((column, index) => {
          return (
            <Column
              key={index}
              hidden={!column.visible}
              sortable={column.sortable !== undefined ? column.sortable : false}
              resizeable={
                column.resizeable !== undefined ? column.resizeable : false
              }
              field={column.id}
              header={column.label}
              body={column.customTemplate}
            ></Column>
          );
        })}
      </TreeTable>
    </div>
  );
})

export default MarketList;
