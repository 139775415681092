
import { Button } from "@mui/material";
import * as React from "react";

const PrwcBasicButton = (props: any) => {
  const { onClick, labelKey, classObj, color = 'default', disabled = false, size = 'medium', variant = 'text' } = props
  //const { onClick, labelKey, className, type = "button", icon, tooltipText, tooltipOptions={position: 'bottom'} } = props;

  return (
    <>
      <Button 
        onClick= {onClick}
        color= {color}
        disabled = {disabled}
        size = {size}
        variant={ variant }
        classes = { classObj }
      >{labelKey}</Button>
    </>
  );
};

export default PrwcBasicButton;
