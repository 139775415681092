import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { HeadCountAlertFilterTabConstants, StatusOptions } from "../../../constants/HCPConstant";
import PickListOption from "../PickListOption";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useCommonService } from "../../../../../services/CommonService";
import { checkUndefinedAndNull, checkUndefinedAndNullEmpty } from "../../../../RTD/services/helper";
import { useUserContext } from "../../../../../contextProviderFiles/UserContextProvider";
import { useHCPService } from "../../../services/HCPService";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const AlertsFilterOption = forwardRef((props: any, ref) => {

    const [value, setValue] = useState(0);
    const { data, onSaveFilter, hideStatusTab } = props
    const { loggedUserInfo } = useUserContext();
    const [targetEmployeeName, setTargetEmployeeName] = useState<any>([]);
    const [targetSite, setTargetSite] = useState<any>([]);
    const [targetStatus, setTargetStatus] = useState<any>([]);
    const [targetAttributeType, setTargetAttributeType] = useState<any>([]);
    const [employeeName, setEmployeeName] = useState<any>([]);
    const [site, setSite] = useState<any>([]);
    const [status, setStatus] = useState<any>([]);
    const [attributeType, setAttributeType] = useState<any>([]);
    const commonService = useCommonService();
    const hcpService = useHCPService();

    useImperativeHandle(ref, () => ({
        saveAsFilter(data) {
            onSaveAsFilter(data)
        },
        clearAllParameter() {
            onClearAllParameters()
        },
        runFilter() {
            return onRunFilter()
        }
    }));

    useEffect(() => {
        hcpService.getAlertsFilterCriteriaData()
            .then((res) => {
                bindFilterTabData(res.data)
            })
            .catch((err) => {
                commonService.showToast("error", err);
            });
    }, [data])

    const bindFilterTabData = (filterCriteriaData) => {
        getSite()

        let allEmployeesName = filterCriteriaData.headcountDetails
        let allAttributes = filterCriteriaData.attributes
        let allStatus = StatusOptions

        if (checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues)) {
            //Employees
            let targetEmployeeName = data.filterValues[0].employeeName
            setTargetEmployeeName(targetEmployeeName)
            let sourceEmployees = allEmployeesName.filter(item => {
                return targetEmployeeName.filter(i => { return i.name === item.name }).length <= 0;
            });
            setEmployeeName(sourceEmployees)

            // attributes
            let targetAttributes = data.filterValues[0].attributes
            setTargetAttributeType(targetAttributes)
            let sourceAttributesType = allAttributes.filter(item => {
                return targetAttributes.filter(i => { return i.name === item.value }).length <= 0;
            })
            setAttributeType(sourceAttributesType)

            // status
            let targetStatus = data.filterValues[0].status
            setTargetStatus(targetStatus)
            let sourceStatus = allStatus.filter(item => {
                return targetStatus.filter(i => { return i.id === item.id }).length <= 0;
            })
            setStatus(sourceStatus)

        } else {
            setEmployeeName(allEmployeesName)
            setAttributeType(allAttributes)
            setStatus(allStatus)
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getSourceDataById = (id) => {
        switch (id) {
            case 0:
                return site
            case 1:
                return employeeName
            case 2:
                return attributeType
            case 3:
                return status
        }
    }

    const getTargetDataById = (id) => {
        switch (id) {
            case 0:
                return targetSite
            case 1:
                return targetEmployeeName
            case 2:
                return targetAttributeType
            case 3:
                return targetStatus
        }
    }
    const onChange = (event) => {
        switch (value) {
            case 0:
                setSite(event.source)
                setTargetSite(event.target)
                break;
            case 1:
                setEmployeeName(event.source)
                setTargetEmployeeName(event.target)
                break;
            case 2:
                setAttributeType(event.source)
                setTargetAttributeType(event.target)
                break;
            case 3:
                setStatus(event.source)
                setTargetStatus(event.target)
                break;
        }
    }
    const getSite = () => {
        commonService.getCentersForDropDown().then((res) => {
            if (checkUndefinedAndNullEmpty(data) && checkUndefinedAndNullEmpty(data.filterValues) &&
                checkUndefinedAndNullEmpty(data.filterValues[0].site)) {
                let allData = res.data.centers;
                let targetData = data.filterValues[0].site;
                setTargetSite(targetData);
                let sourceData = allData.filter(item => {
                    return targetData.filter(i => { return i.id === item.id }).length <= 0;
                });
                setSite(sourceData);
            }
            else
                setSite(res.data.centers);
        })
            .catch((err) => {
                commonService.showToast("error", err);
            })
    }
    const onClearAllParameters = () => {

        let clearSite = site.concat(targetSite).sort((a, b) => a.id - b.id)
        setSite(clearSite)
        setTargetSite([])

        let clearEmployeeName = employeeName.concat(targetEmployeeName).sort((a, b) => a.id - b.id)
        setEmployeeName(clearEmployeeName)
        setTargetEmployeeName([])

        let clearAttributeType = attributeType.concat(targetAttributeType).sort((a, b) => a.id - b.id)
        setAttributeType(clearAttributeType)
        setTargetAttributeType([])

        let clearStatus = status.concat(targetStatus).sort((a, b) => a.id - b.id)
        setStatus(clearStatus)
        setTargetStatus([])

        setValue(0)
    }
    const onSaveAsFilter = (filterData) => {

        let filterValues = [{
            site: targetSite,
            attributes: targetAttributeType,
            employeeName: targetEmployeeName,
            status: targetStatus
        }];

        let savefilterObj = {
            ...filterData,
            "isAlertFilter": true,
            "userName": loggedUserInfo.userName,
            "userId": loggedUserInfo.userID,
            "filterValues": filterValues
        };
        onSaveFilter(savefilterObj);
    }
    const onRunFilter = () => {
        let filterValues = [{
            site: targetSite,
            attributes: targetAttributeType,
            employeeName: targetEmployeeName,
            status: targetStatus
        }]
        setValue(0)
        return { "filterValues": filterValues };
    }

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                    display: "flex",
                    height: "auto",
                }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: "divider" }}
                >
                    {HeadCountAlertFilterTabConstants.map((item, index) => (                      
                        (!hideStatusTab || item.id !== 3) && (
                            <Tab key={item.id} label={item.label} {...a11yProps(item.id)} />
                        )
                    ))}                   
                </Tabs>
                {
                    HeadCountAlertFilterTabConstants
                        .map((item, index) => {
                            let sourceData = getSourceDataById(item.id)
                            let targetData = getTargetDataById(item.id)
                            return (
                                <TabPanel value={value} index={item.id}>
                                    <PickListOption sourceData={sourceData} targetData={targetData}
                                        onChange={onChange}
                                        filterText={item.label} />
                                </TabPanel>
                            )
                        })}
            </Box>
            <div className="filter-box">
                <p className="defaultt">Filter:</p>
                {
                    HeadCountAlertFilterTabConstants
                        .map((item, index) => {
                            let targetData = getTargetDataById(item.id)
                            return (<>
                                {targetData.length > 0 && (
                                    <p>
                                        {item.label} <span>{targetData.length}</span> Selected,
                                    </p>
                                )}
                            </>)
                        })
                }
            </div>
        </>
    )
});
export default AlertsFilterOption;