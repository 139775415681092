import React, { createContext, useContext } from "react";
import { useAxiosContext } from "../../../contextProviderFiles/AxiosContextProvider";

import {getDNADomainMappingUrl, manageDNADomainMappingUrl, deleteDNADomainMappingUrl, getDnaLobOrganizationsForDropDownUrl, 
    dNADomainMappingApprovalUrl, getDNACommentUrl, saveDNACommentUrl, getDNADropdownsUrl} from "../constants/DNAConstants";
import { dnaQuery, dnaStore } from "../state";

let operationsAllowed: any = {};
const DNAServiceContext = createContext(operationsAllowed);

export const DNAServiceProvider = (props) => {
    const { axiosObj } = useAxiosContext();

    const getAdminMasterActiveTabIndex = () => {
        return dnaQuery.getValue().adminPage.activeTabValue;
    }
    
    const setAdminMasterActiveTabIndex = (activeTabValue: string) => {
        dnaStore.update({adminPage: {activeTabValue: activeTabValue}});
    }

    const getDNADomainMappingMaster = async (request) => {        
        return axiosObj.post(getDNADomainMappingUrl, request);
    };
    const manageDNADomainMapping = (request) =>{
        return axiosObj.post(manageDNADomainMappingUrl, request);
    };
    const deleteDNADomainMapping = (request) => {        
        return axiosObj.post(deleteDNADomainMappingUrl, request);
    };
    const getDnaLobOrganizationsForDropDown = (request) => {        
        return axiosObj.get(getDnaLobOrganizationsForDropDownUrl, request);
    };
    const dNADomainMappingApproval = (request) => {
        return axiosObj.post(dNADomainMappingApprovalUrl, request);
    };
    const getDNAComments = async (request) => {
        return axiosObj.post(getDNACommentUrl, request);
    };
    const saveDNAComments = async (request) => {
        return axiosObj.post(saveDNACommentUrl, request);
    };
    const getDNADropdownDetails = async (request) => {
        return axiosObj.post(getDNADropdownsUrl, request);
    };

    operationsAllowed = {
        getAdminMasterActiveTabIndex,
        setAdminMasterActiveTabIndex,
        getDNADomainMappingMaster,
        manageDNADomainMapping,
        deleteDNADomainMapping,
        getDnaLobOrganizationsForDropDown,
        dNADomainMappingApproval,
        getDNAComments,
        saveDNAComments,
        getDNADropdownDetails
    };
    return (
        <DNAServiceContext.Provider value={operationsAllowed}>
            {props.children}
        </DNAServiceContext.Provider>
    );
};

export const useDNAService = () => {
    return useContext(DNAServiceContext);
};
