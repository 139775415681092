import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./PieChart.css";

const PieChart = forwardRef((props:any,ref) => {
  const { data } = props;
  const [chart, setChart] = useState<any>(null)

  const options: Highcharts.Options = {
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Count",
        type: "pie",
        data: data,
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.y}",
        },
      },
    ],
  }

  useImperativeHandle(ref, () => ({
    reflowChart(){
      if (chart) {
        chart.reflow(false);
      }
    }
  }))

  return (
    <div id="testChart" className="PieChart">
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { width: "100%", height: "100%" } }}
        options={options}
        callback={chart => setChart(chart)}
      />
    </div>
  );
})

export default PieChart;
