import React from "react";
import {  Outlet } from 'react-router-dom'
import { WFHContextProvider } from "./contextProviders/WFHContextProvider";
import { WFHServiceProvider } from "./services/WFHService";
const WFORoute = () => {  
    return(
      <WFHServiceProvider>
        <WFHContextProvider>
            <Outlet />
        </WFHContextProvider>
      </WFHServiceProvider>
    );
}

export default WFORoute;