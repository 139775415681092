import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import React, { forwardRef, useEffect, useState } from 'react';
import { IColumn } from '../../../../../../contracts/IColumn';
import { GetColumnToCustomTemplate, GetColumnToShow, getChangeColumnHeader } from '../../../../services/helper';
import './VisitorChatStatsList.css';
import { useRTDService } from '../../../../services/RTDService';

const VisitorChatStatsList = forwardRef((props: any, ref) => {
  const { data, onChangeItem, widgetInfo, globalFilterInfo } = props;
  const rtdService = useRTDService()
  const columns: IColumn[] = [
    {
      id: "QueueName",
      label: "Developer",
      minWidth: 400,
      align: "left",
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "timeZone",
      label: "Timezone",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100,
    },
    {
      id: "OMC",
      label: "Agent Message Count",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100
    },
    {
      id: "VMC",
      label: "Visitor Message Count",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100
    },
    {
      id: "ARTO",
      label: "Agent Average Response Time",
      visible: true,
      align: "right",
      sortable: true,
      resizeable: true,
      minWidth: 100,
      customTemplate: (e) => `${e.data.ARTO?.toFixed()}`
    },
    {
      id: "ARTV",
      label: "Visitor Average Response Time",
      sortable: true,
      resizeable: true,
      visible: true,
      minWidth: 100,
      customTemplate: (e) => `${e.data.ARTV?.toFixed()}`
    },
    {
      id: "MRTO",
      label: "Agent Max Response Time",
      sortable: true,
      resizeable: true,
      visible: true,
      minWidth: 100
    },
    {
      id: "MRTV",
      label: "Visitor Max Response Time",
      visible: true,
      align: "center",
      sortable: true,
      resizeable: true,
      minWidth: 100     
    }    
  ]
  const [columnToShow, setColumnToShow] = useState<any>(columns);
  const [multiSortMeta, setMultiSortMeta] = useState<any>([{field: "name", order:1}]);
  
  useEffect(()=>{
      let multiSortMeta = widgetInfo.multiSortMeta !== undefined && widgetInfo.multiSortMeta !== null ? JSON.parse(widgetInfo.multiSortMeta) : [{field: "name", order:1}]
      setMultiSortMeta(multiSortMeta)
  },[widgetInfo.multiSortMeta])
  
 
  useEffect(() => {
    let updatedColumns = GetColumnToCustomTemplate(rtdService,columns, widgetInfo.appliedCFRules);
    //setFormattedColumns(updatedColumns)
    let filterColumns = GetColumnToShow(updatedColumns, widgetInfo.selectedColumns);
    let groupColumns = getChangeColumnHeader(widgetInfo,filterColumns)
    setColumnToShow(groupColumns)
  }, [widgetInfo.groupField,widgetInfo.selectedColumns,widgetInfo.appliedCFRules])

  const onSaveColReorder = (item) => {
      let newDragIndex = item.dragIndex - 1;
      let newDropIndex = item.dropIndex - 1;
      var element = columnToShow[newDragIndex];
      columnToShow.splice(newDragIndex, 1);
      columnToShow.splice(newDropIndex, 0, element);

      let reorderColumns = columnToShow.map((item) => {
          return item.id;
      });
      let updatedWidgetInfo = {...widgetInfo, selectedColumns: JSON.stringify(reorderColumns)}
      onChangeItem(updatedWidgetInfo)
  };

  const onSort = (e) => {
      setMultiSortMeta(e.multiSortMeta)
      let updatedWidgetInfo = {...widgetInfo, "multiSortMeta": JSON.stringify(e.multiSortMeta)}
      onChangeItem(updatedWidgetInfo)
  };

    return (
      <div className="AgentMarketlist">
          <TreeTable
              value={data}
              globalFilter={globalFilterInfo !== undefined ? globalFilterInfo['global'].value : ""}
              filterMode = {globalFilterInfo !== undefined ? globalFilterInfo['global'].matchMode : ""} 
              onSort={onSort}
              sortMode="multiple"
              multiSortMeta={multiSortMeta}
              resizableColumns
              reorderableColumns
              removableSort
              columnResizeMode="expand"
              onColReorder={onSaveColReorder}
              scrollable
              scrollHeight={widgetInfo.dynamicHeight}
          >
          <Column expander style={{ width: "4em", justifyContent: "center" }} />
          {columnToShow.map((column, index) => {
              return (
              <Column
                  key={index}
                  hidden={!column.visible}
                  sortable={column.sortable !== undefined ? column.sortable : false}
                  resizeable={
                  column.resizeable !== undefined ? column.resizeable : false
                  }
                  field={column.id}
                  header={column.label}
                  body={column.customTemplate}
              ></Column>
              );
          })}
          </TreeTable>
      </div>
      );
})

export default VisitorChatStatsList;
