export const apiUrl = "admin/";
export const jobschedulerApiUrl = "js/";
export const getSchedulesApiUrl = jobschedulerApiUrl+"schedules";
export const getOvertimeSchedulesApiUrl = jobschedulerApiUrl+"overtimeschedules";
export const getTunisPayrollApiUrl = jobschedulerApiUrl+"tunispayrollservice";
export const getTunisOvertimePayrollApiUrl = jobschedulerApiUrl+"tunisovertimespayrollservice"; 
export const getVerintActivityUrl = apiUrl + "verintactivity/getverintactivities";
export const editVerintActivityUrl = apiUrl + "verintactivity/updateverintactivities";
export const getAgentSkillsUrl = apiUrl + "Agentskills/GetAgentSkills";
export const getVerintSchedulesUrl = apiUrl + "VerintSchedules/GetVerintSchedules";
export const getVerintOvertimeSchedulesUrl = apiUrl + "VerintSchedules/GetVerintOTSchedules";
export const getQueuesDropDownUrl = jobschedulerApiUrl + "getqueues"
export const getGroupsDropDownUrl = jobschedulerApiUrl + "getgroups"
export const getStaffingDataUrl = jobschedulerApiUrl+ "getstaffingsdata";
export const manageGroupUrl = jobschedulerApiUrl+ "managegroups";
export const deleteGroupUrl = jobschedulerApiUrl+ "deletegroups";

export const country = ["Montego Bay","Tunis","Berlin","Manila"];   
export const location = ["Montego Bay (UTC-5)","Tunis (UTC+1)","Berlin (UTC+2)","Manila (UTC+8)"]; 
export const newExportToExcelFieldsArr = ["Payroll ID", "Verint Emp ID", "Employee Name", "Activity ID", "Activity Name", "Start Time", "End Time","On Date","Organization Name","Is Paid","Is Time-Off","Is TimeOff with Allotment"];
export const oldExportToExcelFieldsArr = ["payrollID", "verintEmpID", "employeeName", "activityId", "activityName", "startTime", "endTime","onDate","organizationName","isPaid","isTimeOff","isTimeOffWithAllotment"];

export const newExportToExcelAgentSkillsFieldsArr = ["User Id", "User Name", "Organization", "Skill ID", "Skill Name", "Start Date", "End Date","Termination Date","Priority","Proficiency","ReserveLevel"];
export const oldExportToExcelAgentSkillsFieldsArr = ["employeeId", "employeeName", "organization", "skillId", "skillName", "startDate", "endDate","terminationDate","priority","proficiency","reserveLevel"];

export const PayrollConstants = {
    Active: "Active",
    Attrited: "Attrited"    
};
