import React from "react";
import "./App.css";
import "../node_modules/primereact/resources/primereact.min.css";
import "../node_modules/primeicons/primeicons.css";
import '../node_modules/antd/dist/reset.css';
import "../node_modules/primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { AppRoutes } from "./AppRoutes";
import { AuthContextProvider } from "./contextProviderFiles/AuthContextProvider";
import { CommonServiceProvider } from "./services/CommonService";
import { AxiosContextProvider } from "./contextProviderFiles/AxiosContextProvider";

function App() {
  return (
    <div>
      <AuthContextProvider>
        <AxiosContextProvider>
          <CommonServiceProvider>
            <AppRoutes />
          </CommonServiceProvider>
        </AxiosContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
