import React,{ createContext, useContext, useState } from "react";

let acmInfo: any = {};

const acmContext = createContext(acmInfo);

export const ACMContextProvider = (props: any) => {
    const [roleRequestData, setRoleRequestData] = useState<any>([]); 
    const [centers, setCenters] = useState<any>([]);
    const [users, setUsers] = useState<any>([]);

    let defaultManageRole = { 
        roleId: 0 , 
        name: "", 
        centerId: 0,
        center: "", 
        description:"", 
        isDefault: false, 
        assignPrivileges: [] as any[] 
    };

    acmInfo = {      
        defaultManageRole,
        roleRequestData,
        setRoleRequestData,
        centers,
        setCenters,
        users,
        setUsers
    }

    return (<>
        <acmContext.Provider value={acmInfo}>
            {props.children}
        </acmContext.Provider>
    </>);
}

export const useACMContext = () => {
    return useContext(acmContext);
}