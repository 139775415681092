import { TextField } from "@mui/material";
import * as React from "react";

const PrwInputTextArea = (props: any) => {
  const { value, label, onChange, variant, rows, placeHolder, autoFocus } = props;    
  return (
    <div>
      <TextField
        className={`p-inputtext-sm p-d-block ${
          props?.rawErrors?.length > 0 ? "p-invalid" : null
        } ${props.classNames}` }
        label= {label}
        rows={3}
        multiline
        value={value !== undefined ? value : ""}
        onChange={(e: any) => onChange(e.target.value)}
        placeholder={placeHolder}
        sx={{ width: "100%" }}   
        variant= {variant}
        autoFocus
      />
    </div>
  );
};

export default PrwInputTextArea;
