import React, {useState} from "react";
import { RadioButton } from "primereact/radiobutton";

const PrwRadioButton = (props: any) => {
  const { options, value, required, disabled, readonly, onChange, id} = props;  
  //const name = Math.random().toString();
  options["enumOptions"] = props.uiSchema !== undefined ? props.uiSchema.enumOptions || [] : options.enumOptions;
  // const selectedItem = props.uiSchema !== undefined ? props.uiSchema.selectedType || [] : options.selectedType;
  const selectedItem = options.selectedType;
  //const [selectedType, setSelectedType] = useState(selectedItem);
  
  const { enumOptions, enumDisabled, inline } = options;
  
  const onItemChange =(e)=>{    
    //setSelectedType(e.value);    
    onChange(e.target.value);
  };

  return (
    <div className={inline ? "mt-1 grid grid-flow-col" : ""} id={id}>
      {enumOptions.map((option: any, index: number) => {        
        //const checked = option.value === selectedType.value;
        const itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;
        const disabledCls = disabled || itemDisabled || readonly ? "disabled" : "";
        const radio = (
          <>
            <RadioButton 
              inputId={option.value} 
              name="payrollType" 
              value={option} 
              onChange={onItemChange}  
              checked={selectedItem === option?.value}
              disabled={option.value === 'R'} 
            />
            <label htmlFor={option.value} className = "ml-1 text-xs font-medium"> {option.name} </label>                        
          </>
        );
        return (
          <div key={index} className={`flex items-center forverient commoncssradio ${disabledCls}`}>
            {radio}
          </div>
        );
      })}
    </div>
  );
};

export default PrwRadioButton;
