
import { Column } from "primereact/column";
import { useUserContext } from "../../../../../contextProviderFiles/UserContextProvider";
import { IColumn } from "../../../../../contracts/IColumn";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DataTable } from "primereact";
import dateFormat from "dateformat";
import { Link, Tooltip } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import NxModalDialog from "../../../../../components/NxModalDialog/NxModalDialog";
import { HCPMasterPagesTabValue } from "../../../../../enums/common.enum";

var columns: IColumn[];
const MasterPagesList = (props) => {
    const {
        data,
        onEdit,
        onDeleteChange,
        isDeleteLoader,
        mappingMasterId,
        onValueChange,
        globalFilters
    } = props;
    const { loader } = useUserContext();
    const [currentData, setCurrentData] = useState<any>([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const onDelete = (rowData) => {
        setCurrentData(rowData);
        setDeleteDialogOpen(true);
    };

    columns = [
        {
            id: "id",
            label: "Id",
            minWidth: 50,
            visible: false,
        },
        {
            id: mappingMasterId === "2" ? "costCenterNo" : "name",
            label: mappingMasterId === "2" ? "Cost Center No" : "Name",
            minWidth: 100,
            sortable: true,
            visible: true,
        },
        {
            id: "description",
            label: "Description",
            minWidth: 50,
            sortable: true,
            visible: true,
        },
        {
            id: "createdBy",
            label: "Created By",
            minWidth: 50,
            sortable: true,
            visible: true,
        },
        {
            id: "updatedBy",
            label: "Last Updated By",
            minWidth: 50,
            sortable: true,
            visible: true,
        },
        {
            id: "updatedDate",
            label: "Last Updated Date",
            customTemplate: (value: any) => {
                return (<p>{dateFormat(value.updatedDate, "yyyy-mm-dd")}</p>);
            },
            minWidth: 50,
            sortable: true,
            visible: true,
        },
        {
            id: "id",
            label: "Action",
            minWidth: 100,
            align: "right",
            visible: mappingMasterId === "2" ? false : true,
            sortable: false,
            customTemplate: (value: any) => {
                return (
                    (mappingMasterId !== HCPMasterPagesTabValue.CostCenter &&
                        <div className="action-buttons">
                            <Link
                                underline="none"
                                onClick={() => onEdit(value)}
                                variant="button"
                            >
                                <Tooltip title={"Edit"} placement="top" arrow>
                                    <DriveFileRenameOutlineOutlinedIcon />
                                </Tooltip>
                            </Link>

                            <Link
                                underline="none"
                                onClick={() => onDelete(value)}
                                variant="button"
                            >
                                <Tooltip title={"Delete"} placement="top" arrow>
                                    <DeleteOutlineOutlinedIcon
                                        sx={{ color: "rgb(239, 83, 80)" }}
                                    />
                                </Tooltip>
                            </Link>

                        </div>
                    )
                );
            },
        },
    ];
    const deleteModalDialogContent = () => {
        return (
            <NxModalDialog
                id="delete"
                titleName="Delete?"
                bodyContent={
                    <div className="text-popup">
                        Are you sure you want to delete {
                            <b>
                                {mappingMasterId !== 2
                                    ? currentData.name
                                    : currentData.costCenterNo}

                            </b>}?
                    </div>
                }
                footerContent={
                    <LoadingButton
                        variant="contained"
                        color="error"
                        loading={isDeleteLoader}
                        className="footerbutton"
                        sx={{
                            py: 1,
                            px: 2,
                            fontSize: "15px",
                            letterSpacing: "0.05em",
                            lineHeight: "22px",
                            my: 1,
                            mx: 1,
                        }}
                        autoFocus
                        onClick={() => {
                            setDeleteDialogOpen(false);
                            onDeleteChange(currentData);
                        }}
                    >
                        Delete
                    </LoadingButton>
                }
                onClose={() => {
                    setDeleteDialogOpen(false);
                }}
                open={deleteDialogOpen}
                titleContent={<></>}
            />
        );
    };


    return (
        <div>
            <DataTable 
                value={data} 
                paginator
                rows={10}
                filters={globalFilters}
                paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10,50, 100, 200]}
                onValueChange={onValueChange}>

                {columns.map((column, index) => {
                    return (
                        <Column
                            key={index}
                            hidden={!column.visible}
                            field={column.id}
                            header={column.label}
                            sortable={column.sortable}
                            body={column.customTemplate}
                        ></Column>
                    );
                })}
            </DataTable>
            {deleteModalDialogContent()}
        </div>
    );
}
export default MasterPagesList;


