import React from "react";
import { Outlet } from 'react-router-dom'

import { ACMContextProvider } from "./contextProviders/ACMContextProvider";
import { ACMServiceProvider } from "./services/ACMService";

const ACMRoute = () =>{
    return(
      <ACMServiceProvider>
        <ACMContextProvider>
          <Outlet />
        </ACMContextProvider>
      </ACMServiceProvider>
    );
}

export default ACMRoute;