import Box from '@mui/material/Box';
import React, { FC, Fragment, useEffect, useState } from 'react';
import DynamicForm from '../../../../Core/components/DynamicForm/DynamicForm';
import Validators from '../../../../UI/helpers/validator';
import NoLabelFieldTemplate from '../../../../UI/templates/fields/NoLabelFieldTemplate';
import VerticalFormTemplate from '../../../../UI/templates/objects/VerticalFormTemplate';
import PrwcBasicButton from '../../../../UI/widgets/custom/buttons/PrwcBasicButton';
import './ManageRolePrivilege.css';

const PrivilgeFieldTemplate = (props) => {
  const {
    classNames,
    label,
    children
  } = props;

  return (<>
     <Box className="privilege-box" sx={{ mb: 2 }}>
          {label && (<Box className="modal-divider">{label}</Box>)}
          {children}         
      </Box>
  </>);
}


const ManageRolePrivilege = (props) => {
  const { data, onSaveSubmit, centers, privileges } = props;
  const submitButtonRef: any = React.useRef(null);
  const [formData, setFormData] = React.useState(data);  
  let selectedPrivileges: any = []
  const onChange = (formData: any) => {
    setFormData(formData);
  };

  const onSubmit = () => {
    if(formData.selectedPrivileges === undefined){
      getSelectedPrivilege(privileges);
      formData.selectedPrivileges = selectedPrivileges;
    }
    onSaveSubmit(formData);
  };
  
  const onError = (data: any) => {
    console.log("on error ---");
    console.log(data);
  };

  const getSelectedPrivilege = (items) => {
    items.forEach((item) => {
      if (item.selected || item.partialSelected) {
        if (selectedPrivileges.filter((i) => i === item.id).length === 0) {
          selectedPrivileges.push(item.id);
        }
      } else {
        selectedPrivileges.filter((i) => i !== item.id).map((a) => a);
      }

      if (item.branches.length > 0) getSelectedPrivilege(item.branches);
    });
  };

  const handleOnSubmit = () => {
    // It is to submit form programmatically.
    submitButtonRef.current.click();
  };

  const formButtons = () => {
    return (
      <button ref={submitButtonRef} type="submit" className="hidden p-col-12" />
    );
  };

  const schema: any = {
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      centerId: {
        type: "string",
        title: "Center",
      },
      description: {
        type: "string",
        title: "Description",
      },
      selectedPrivileges:{
        type: "string",
        title: "Privilege"
      }
    },
    required: ["name"],
  };

  const validationSchema: any = {
    name: {
      validators: [
        {
          validate: (value: string) => {
            return !Validators.isStringEmpty(value);
          },
          messageKey: "Name is required",
        },
      ],
    },
    centerId: {
      validators: [
        {
          validate: (value: string) => {
            return !Validators.isStringEmpty(value);
          },
          messageKey: "Center is required",
        },
      ],
    },
    description: {
      validators: [
        {
          validate: (value: string) => {
            return Validators.isMaxLengthValid(value, 100);
          },
          messageKey: "Description should not more than 100 characters",
        },
      ],
    },
  };

  const uiSchema: any = {
    "ui:ObjectFieldTemplate": VerticalFormTemplate,
    name: {
      "ui:widget": "prwInputText",
      "ui:FieldTemplate": NoLabelFieldTemplate,
      classNames: "textbox",
      "ui:emptyValue": "",
    },
    centerId: {
      "ui:widget": "prwDropdownBasic",
      "ui:FieldTemplate": NoLabelFieldTemplate,
      classNames: "textbox",
      "ui:emptyValue": "",     
      "ui:options": {
        placeholder : "Center",
        dropdownOptions: centers,
        optionLabel:"Select Center",
        optionValue:"0"
      }
    },
    description: {
      "ui:widget": "prwInputTextArea",
      "ui:FieldTemplate": NoLabelFieldTemplate,
      classNames: "description",
      "ui:emptyValue": "",
    },
    selectedPrivileges: {
      "ui:widget": "prwTreeView",
      "ui:FieldTemplate": PrivilgeFieldTemplate,
      "ui:options": {
        treeOptions: privileges
      }
    }
  };


  return (
    <>
      <Box className="group" sx={{ mt: 6 }}>
        <div className="">
          <DynamicForm
            onChange={onChange}
            formData={formData}
            onSubmit={onSubmit}
            onError={onError}
            schema={schema}
            uiSchema={uiSchema}
            validationSchema={validationSchema}
            formButtons={() => formButtons()}
          ></DynamicForm>
        </div>
        <div className="rightsidebutn">
          <PrwcBasicButton
            onClick={handleOnSubmit}
            color="primary"
            variant="contained"
            labelKey="Save"
            classObj={{}}
            //className="w-24 h-8 my-1 bg-blue-700 border-blue-700 p-button-sm"
          ></PrwcBasicButton>
        </div>
      </Box>
    </>
  );
}
export default ManageRolePrivilege;
