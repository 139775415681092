import { Button } from "@mui/material";
import * as React from "react";
import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { ImageCropper } from "../../../../../constants/CommonConstant";
import PrwcBasicButton from "../buttons/PrwcBasicButton";
import PrwcRoundedButton from "../buttons/PrwcRoundedButton";
import PrwcDialog from "../PrwcDialog";
import PrwcSlider from "../PrwcSlider";

const PrwcImageCrop = React.memo((props: any) => {
  const { isVisible, onHide, image, onImageCropped } = props;
  // const [image, setImage] = useState();
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [aspect] = useState(1);
  const [rotation, setRotation] = useState(0);

  const onCropChange = (crop: any) => {
    console.log("on crop change ----");
    setCrop(crop);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onZoomChange = (zoom: number) => {
    console.log("on zoom change ---");
    if (zoom > 0) {
      setZoom(zoom);
    }
  };

  const onRotate = (value: number) => {
    setRotation((prevValue) => prevValue + value);
  };

  const getCroppedImage = async () => {
    const croppedImage: any = await getCroppedImg(
      image,
      croppedAreaPixels,
      rotation
    );

    onImageCropped(croppedImage);
  };

  const renderFooter = () => {
    return (
      <div>
        <PrwcBasicButton
          labelKey="Cancel"
          //icon="pi pi-times"
          onClick={onHide}
          className={{}}//"p-button-text p-button-sm"
        ></PrwcBasicButton>
        {/* <PrwcBasicButton
          type="button"
          onClick={getCroppedImage}
          labelKey="generic.action.done"
          icon="pi pi-check"
          className="p-button-success p-button-sm"
        ></PrwcBasicButton> */}
      </div>
    );
  };

  const createImage = (url: any) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
  };

  /**
   * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
   * @param {File} image - Image File url
   * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
   * @param {number} rotation - optional rotation parameter
   */
  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: any,
    rotation = 0
  ) => {
    const image: any = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    // As Base64 string
    return canvas.toDataURL("image/jpeg");

    // As a blob
    // return new Promise(resolve => {
    //   canvas.toBlob(file => {
    //     resolve(URL.createObjectURL(file))
    //   }, 'image/jpeg')
    // })
  };

  return (
    <div>
      <PrwcDialog
        isVisible={isVisible}
        onHide={onHide}
        footer={renderFooter}
        header={"User Profile Photo"}
      >
        {image && (
          <div>
            <div className="relative w-full h-80">
              <Cropper
                rotation={rotation}
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                cropShape="round"
                showGrid={false}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                style={{ cropAreaStyle: { color: "white", opacity: "0.5" } }}
              />
            </div>
            <div className="flex items-center my-4">
              <div className="mx-4">

                <PrwcRoundedButton
                  onClick={() =>
                    onRotate(ImageCropper.rotation.reverse)
                  }
                  icon="pi pi-replay"
                  className="p-button-rounded p-button-outlined"
                ></PrwcRoundedButton>
              </div>
              <div className="flex-1 ">
                <PrwcSlider
                  value={zoom}
                  onValueChange={(value: number) => onZoomChange(value)}
                ></PrwcSlider>
              </div>
              <div className="mx-4">
                <PrwcRoundedButton
                  onClick={() =>
                    onRotate(ImageCropper.rotation.forward)
                  }
                  icon="pi pi-refresh"
                  className="p-button-rounded p-button-outlined"
                ></PrwcRoundedButton>
              </div>
            </div>
          </div>
        )}
      </PrwcDialog>
    </div>
  );
});

export default PrwcImageCrop;
