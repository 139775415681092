import { forwardRef } from "react";

const DynamicComponent = forwardRef((props: any, ref) => {
  const { component, ...restProps } = props;
  const Component = component;

  return <Component ref={ref} {...restProps}></Component>;
})

export default DynamicComponent;
