import { useEffect, useState } from "react";
import DynamicComponent from "../../../../../Core/components/DynamicComponent";
import { WidgetComponentConstants } from "../../../../constants/RTDConstants";
import { processEmailAnalysisData } from "../../../../services/chatEmailService";
import { useRTDWidgetDataContext } from "../../../../contextProviders/RTDWidgetDataContextProvider";
import { checkUndefinedAndNullLength } from "../../../../services/helper";
import PageLoader from "../../../../../../components/PageLoader";

const EmailAnalysis = (props: any) => {
    const { widgetInfo, onChangeItem, filterInfo, globalFilterInfo,clientSocket } = props
    const [emailCaseAgeData, setEmailCaseAgeData] = useState<any>(undefined)
    const [caseDetailData, setCaseDetailData] = useState<any>([])
    const { emailData } = useRTDWidgetDataContext()

    useEffect(() => {
        if(checkUndefinedAndNullLength(emailData)){
          let combinedData = processEmailAnalysisData(emailData,filterInfo,widgetInfo)
          emailAnalysisDataCallback(null,combinedData)
        }
    }, [clientSocket,emailData,filterInfo,widgetInfo.groupField, widgetInfo.subLevelGroupFields])

    const emailAnalysisDataCallback = (err, currentData) => {            
        setEmailCaseAgeData(currentData.processData)
        setCaseDetailData(currentData.rowData)
    }

    const getWidgetItemByType = () => {
        let currentWidgetComponent: any = WidgetComponentConstants.find(
          (item) => item.widgetId === widgetInfo.widgetType
        );
        let currentWidgetItem = currentWidgetComponent?.items?.find(
          (item) => item.id === widgetInfo.viewItemId
        );
        let currentWidgetType = currentWidgetItem?.viewTypes?.find(
          (item) => item.id === widgetInfo.viewTypeId
        );
    
        return (
          <DynamicComponent
            component={currentWidgetType?.viewCompoent}
            data={emailCaseAgeData}
            widgetInfo={widgetInfo}
            onChangeItem={onChangeItem}
            detailData = {caseDetailData}
            globalFilterInfo={globalFilterInfo}
          />
        )
      }
    
    return (
        <>
          {emailCaseAgeData && (<div
              className="role-content"
              style={{ overflow: "auto", height: "auto", position: "relative" }}
            >
                {getWidgetItemByType()}
          </div>) }
          {!emailCaseAgeData && <PageLoader />}
        </>
    );
}
export default EmailAnalysis
