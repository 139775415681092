import { createContext, useContext, useState } from "react";

let forecastInfo: any = {};

const forecastContext = createContext(forecastInfo);

export const ForecastContextProvider = (props: any) => {
    const [allForecastRequestData, setAllForecastRequestData] = useState<any>([]); 
    const [UploadForecastData, setUploadForecastData] = useState<any>([]);
    const [campaignMasterData, setCampaignMasterData] = useState<any>([]);
    const [forecastMappingFilterData, setForecastMappingFilterData] = useState<any>([]);
    
    forecastInfo = {      
        allForecastRequestData,
        setAllForecastRequestData,
        UploadForecastData,
        setUploadForecastData,
        campaignMasterData,
        setCampaignMasterData,
        forecastMappingFilterData,
        setForecastMappingFilterData
    }
    return (<>
        <forecastContext.Provider value={forecastInfo}>
            {props.children}
        </forecastContext.Provider>
    </>);
}

export const useForecastContext = () => {
    return useContext(forecastContext);
}