import "./PickListOption.css";
import { PickList } from "primereact/picklist";

function PickListOption(props) {
  const { sourceData, targetData, onChange, filterText } = props
  const itemTemplate = (item) => {
    return (
      <>
        <div className="field-checkbox">
          <label htmlFor={item.index}>{item.value}</label>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="picklist-filter">
        <div className="card">        
            <PickList
              source={sourceData}
              target={targetData}
              itemTemplate={itemTemplate}
              sourceHeader="Available"
              targetHeader="Selected"
              sourceStyle={{ height: "220px" }}
              targetStyle={{ height: "220px" }}
              onChange={onChange}
              dataKey="value"
              filterBy="value"
              showSourceControls={false}
              showTargetControls={false}
              sourceFilterPlaceholder={"Search by " + filterText}
              targetFilterPlaceholder={"Search by " + filterText}
            />        
        </div>
      </div>
    </>
  );
}

export default PickListOption;
