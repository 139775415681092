import { Button, Tooltip } from "@mui/material"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import dateFormat from "dateformat"
import * as XLSX from "xlsx"
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { IColumn } from "../../../../../../contracts/IColumn"

const EmailCaseDetail = (props) => {
    const { data } = props

    const columns: IColumn[] = [
      {
        id: "Origin",
        label: "Case Origin",
        minWidth: 350,
        visible: true,
        sortable: true,
        resizeable: true
      },
      {
        id: "AgentName",
        label: "Case Owner",
        minWidth: 350,
        visible: true,
        sortable: true,
        resizeable: true
      },
      {
        id: "CaseNumber",
        label: "Case Number",
        minWidth: 350,
        visible: true,
        sortable: true,
        resizeable: true
      },
      {
        id: "ContactName",
        label: "Contact Name",
        minWidth: 350,
        visible: true,
        sortable: true,
        resizeable: true
      },
      {
        id: "Subject",
        label: "Subject",
        minWidth: 350,
        visible: true,
        sortable: true,
        resizeable: true
      },
      {
        id: "Status",
        label: "Status",
        minWidth: 350,
        visible: true,
        sortable: true,
        resizeable: true
      },
      {
        id: "CreatedDate",
        label: "Date Opened",
        defaultFormat: (value) => { 
            return dateFormat(value.CreatedDate, "dd-mm-yyyy") 
        },
        minWidth: 350,
        visible: true,
        sortable: true,
        resizeable: true
      },
      {
        id: "Case_Age__c",
        label: "Case Age",
        minWidth: 350,
        visible: true,
        sortable: true,
        resizeable: true
      },
      {
        id: "LastModifiedDate",
        label: "Last Modified Date",
        defaultFormat: (value) => { 
          return dateFormat(value.LastModifiedDate, "dd-mm-yyyy") 
        },
        minWidth: 350,
        visible: true,
        sortable: true,
        resizeable: true
      }
    ]
  
    const exportToExcel = () => {
        var fileName = 'OpenTicketStatusFile';
  
        const ws = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, ws, "Employee Report");
        XLSX.writeFile(workbook, fileName + ".xlsx");
    }
  
    return (
      <>
        <div className="uploadbutn common-iconbutton">
          <Tooltip title={"Export to Excel"} placement="top" arrow>
              <Button onClick={() => exportToExcel()}>
                <FileUploadOutlinedIcon />
              </Button>
          </Tooltip>
        </div>
        <div className="ticketdatatable">
          <DataTable 
            value={data}
            paginator 
            size="small"
            rows={10} 
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            resizableColumns 
            showGridlines 
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
          >
              {columns.map((column, index) => {
                return (
                  <Column
                    key={index}
                    sortable={column.sortable !== undefined ? column.sortable : false}
                    resizeable={
                      column.resizeable !== undefined ? column.resizeable : false
                    }
                    field={column.id}
                    header={column.label}
                    body={column.defaultFormat}
                  ></Column>
                );
              })}
          </DataTable>
        </div>
      </>
    )
}

export default EmailCaseDetail