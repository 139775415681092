import React,{ createContext, useContext } from "react"
import { deleteWFHDetailUrl, getUserScheduleUrl, 
    getWFHDetailUrl, manageWFHDetailUrl, getWFHByIdUrl, exportToExcelWFHUrl,
    getWFOShiftReportDataUrl, getWFOUserReportDataUrl } from '../constants/WFHConstant'
import { useAxiosContext } from "../../../contextProviderFiles/AxiosContextProvider";

let operationsAllowed: any = {};
const WFHServiceContext = createContext(operationsAllowed);

export const WFHServiceProvider = (props) => {
    const { axiosObj } = useAxiosContext();

    const getUserSchedules = (request) => {
        return axiosObj.post(getUserScheduleUrl,request);
    }    

    const getWFHDetails = (request) => {
        return axiosObj.post(getWFHDetailUrl,request);
    };

    const getWFHById = (request) => {
        return axiosObj.post(getWFHByIdUrl,request);
    };

    const manageWFHDetail = (request) => {
        return axiosObj.post(manageWFHDetailUrl,request);
    };

    const deleteWFHDetail = (request) => {
        return axiosObj.post(deleteWFHDetailUrl,request);
    };

    const exportToExcelWFHDetails = (request) => {
        return axiosObj.post(exportToExcelWFHUrl,request);
    };   

    const getWFOShiftReportData = (request) => {
        return axiosObj.post(getWFOShiftReportDataUrl, request);
    };

    const getWFOUserReportData = (request) => {                
        return axiosObj.post(getWFOUserReportDataUrl, request);
    };
    
    operationsAllowed = {
        getWFHDetails,
        manageWFHDetail,
        deleteWFHDetail,
        getUserSchedules,
        getWFHById,
        exportToExcelWFHDetails,
        getWFOShiftReportData,
        getWFOUserReportData
    };

    return (
        <WFHServiceContext.Provider value={operationsAllowed}>
            {props.children}
        </WFHServiceContext.Provider>
    );
}

export const useWFHService = () => {
    return useContext(WFHServiceContext);
}