import { Query } from '@datorama/akita';
import { HCPState } from './hcp.model';
import { hcpStore, HCPStore } from './hcp.store';

export class HCPQuery extends Query<HCPState> {
  constructor(protected store: HCPStore) {
    super(store);
  }
}

export const hcpQuery = new HCPQuery(hcpStore);