import { createContext, useContext, useState } from "react";

let timeoffInfo: any = {};

const timeoffContext = createContext(timeoffInfo);

export const TimeOffContextProvider = (props: any) => {
    const [TimeOffRequestData, setTimeOffRequestData] = useState<any>([]); 
    const [UploadTimeOffRequestData, setUploadTimeOffRequestData] = useState<any>([]); 

    timeoffInfo = {      
        TimeOffRequestData,
        setTimeOffRequestData,
        UploadTimeOffRequestData,
        setUploadTimeOffRequestData
    }
    return (<>
        <timeoffContext.Provider value={timeoffInfo}>
            {props.children}
        </timeoffContext.Provider>
    </>);
}

export const useTimeOffContext = () => {
    return useContext(timeoffContext);
}