export interface AdminMasterPage {
    activeTabValue: string;
}
  
export interface HCPState {
    adminMasterPage: AdminMasterPage
}

export function createInitialHCPState(): HCPState {
    return {
        adminMasterPage: {
            activeTabValue: "1"
        }
    }
}