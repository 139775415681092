import { forwardRef, useEffect, useState} from "react";
import { MarketHelpersTrieData, emitAgentMarketData, updateMarketData } from "../../../../services/marketservice";
import "./AgentMarket.css";
import DynamicComponent from "../../../../../Core/components/DynamicComponent";
import { WidgetComponentConstants } from "../../../../constants/RTDConstants";
import { checkUndefinedAndNullLength } from "../../../../services/helper";
import { useRTDWidgetDataContext } from "../../../../contextProviders/RTDWidgetDataContextProvider";
import PageLoader from "../../../../../../components/PageLoader";

const AgentMarket = forwardRef((props: any, ref) => {
  const { widgetInfo, onChangeItem, filterInfo, globalFilterInfo, clientSocket} = props
  const { phoneData } = useRTDWidgetDataContext()
  const [marketListData, setMarketListData] = useState<any>(undefined)
  //const [clientSocket,setClientSocket] = useState<any>(undefined)
  //const queryClient = useQueryClient()
  
  // const { 
  //   initialSocketConnection
  // } = useRTDService();

  // useEffect(()=>{
  //   let socket = initialSocketConnection(clientSocket)
  //   setClientSocket(socket) 
  //   return () => {
  //     unsubscribeMarketEvent(clientSocket)
  //     if (clientSocket) {
  //       console.log("Disconnecting socket...");
  //       clientSocket.disconnect()
  //     }
  //   };
  // },[clientSocket])

  useEffect(() => {
      if(checkUndefinedAndNullLength(phoneData)){
        let combinedData = MarketHelpersTrieData(phoneData,widgetInfo.groupField,filterInfo?.filterValues)
        phoneAnalysisDataCallback(null,combinedData)
      }
      // if(clientSocket){
      //   let chachedData = queryClient.getQueryData(["phoneAnalysis"])
      //   if(checkUndefinedAndNull(chachedData)){
      //     let combinedData = MarketHelpersTrieData(chachedData,widgetInfo.groupField,filterInfo?.filterValues)
      //     phoneAnalysisDataCallback(null,combinedData)
      //   } else {
      //     emitAgentMarketData(clientSocket,queryClient,null)
      //   }

      //   updateMarketData(clientSocket,widgetInfo.groupField, filterInfo,phoneAnalysisDataCallback,queryClient)
      // }
  }, [clientSocket,phoneData,filterInfo,widgetInfo.groupField])

  const phoneAnalysisDataCallback = (err, currentData) => {            
    setMarketListData(currentData)
  }

  const getWidgetItemByType = () => {
    let currentWidgetComponent: any = WidgetComponentConstants.find(
      (item) => item.widgetId === widgetInfo.widgetType
    );
    let currentWidgetItem = currentWidgetComponent?.items?.find(
      (item) => item.id === widgetInfo.viewItemId
    );
    let currentWidgetType = currentWidgetItem?.viewTypes?.find(
      (item) => item.id === widgetInfo.viewTypeId
    );

    return (
      <DynamicComponent
        component={currentWidgetType?.viewCompoent}
        data={marketListData}
        widgetInfo={widgetInfo}
        onChangeItem={onChangeItem}
        globalFilterInfo={globalFilterInfo}
      />
    )
  }
  
  return (
    <>
      {marketListData && (<div
        className="role-content"
        style={{ overflow: "auto", height: "auto", position: "relative" }}
      >
        {getWidgetItemByType()}
      </div>)}
      {!marketListData && <PageLoader />}
    </>
  );
})

export default AgentMarket;
