import React, { createContext, useContext } from "react";
import { useAxiosContext } from "../../../contextProviderFiles/AxiosContextProvider";
import {
  deleteRoleUrl,
  getPrivilegeUrl,
  getRoleByIdUrl,
  getRolesUrl,
  manageRoleUrl,
  setAsDefaultUrl,
  getBaseUsersUrl,
  getUserRolesUrl,
  assignRoleToUserUrl,
  copyRoleToUserUrl,
  assignDefaultAccessUrl
} from "../constants/ACMConstant";

let operationsAllowed: any = {};
const ACMServiceContext = createContext(operationsAllowed);

export const ACMServiceProvider = (props) => {
  const { axiosObj } = useAxiosContext();

  const getRoles = (request) => {
    return axiosObj.post(getRolesUrl, request);
  };

  const getRoleById = (request) => {
    return axiosObj.post(getRoleByIdUrl, request);
  };

  const deleteRole = (request) => {
    return axiosObj.post(deleteRoleUrl, request);
  };

  const manageRole = (request) => {
    return axiosObj.post(manageRoleUrl, request);
  };

  const getPrivileges = (request) => {
    return axiosObj.post(getPrivilegeUrl, request);
  };

  const setAsDefaultRole = (request) => {
    return axiosObj.post(setAsDefaultUrl, request);
  };

  const getAllBaseUsers = (request) => {
    return axiosObj.post(getBaseUsersUrl, request);
  };

  const getUserRoles = (request) => {
    return axiosObj.post(getUserRolesUrl, request);
  };

  const assignRolesToUser = (request) => {
    return axiosObj.post(assignRoleToUserUrl, request);
  };

  const copyRolesToUser = (request) => {
    return axiosObj.post(copyRoleToUserUrl, request);
  };

  const assignDefaultAccess = (request) => {    
    return axiosObj.post(assignDefaultAccessUrl, request);
  };

  operationsAllowed = {
    getRoles,
    getRoleById,
    deleteRole,
    manageRole,
    getPrivileges,
    setAsDefaultRole,
    getAllBaseUsers,
    getUserRoles,
    assignRolesToUser,
    copyRolesToUser,
    assignDefaultAccess
  };

  return (
    <ACMServiceContext.Provider value={operationsAllowed}>
      {props.children}
    </ACMServiceContext.Provider>
  );
};

export const useACMService = () => {
  return useContext(ACMServiceContext);
};
