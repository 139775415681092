
import { Column } from "primereact/column";
import { useUserContext } from "../../../../../contextProviderFiles/UserContextProvider";
import { IColumn } from "../../../../../contracts/IColumn";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DataTable } from "primereact";
import dateFormat from "dateformat";
import { Link, Tooltip } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import NxModalDialog from "../../../../../components/NxModalDialog/NxModalDialog";
import { HCPMappingTabValue } from "../../../../../enums/common.enum";

var columns: IColumn[];
const MappingList = (props) => {
    const {
        data,
        onEdit,
        onDeleteChange,
        isDeleteLoader,
        mappingMasterId,
        globalFilters,
        onValueChange
    } = props;
    const { loader,setLoader } = useUserContext();
    const [currentData, setCurrentData] = useState<any>([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const onDelete = (rowData) => {
        setCurrentData(rowData);
        setDeleteDialogOpen(true);
    };
    columns = [
        {
            id: "id",
            label: "Id",
            minWidth: 50,
            visible: false,
        },
        // {
        //     id: "costCenterMapping",
        //     label: "Cost Center Mapping",
        //     minWidth: 50,
        //     visible: true,
        // },
        {
            id: "costCenterNo",
            label: "Cost Center No",
            minWidth: 100,
            sortable: true,
            visible: true,
        },

        {
            id: "workGroupId",
            label: "WorkGroup",
            minWidth: 50,
            sortable: true,
            visible: mappingMasterId === HCPMappingTabValue.Workgroup,
        },
        {
            id: "webCountryId",
            label: "Web Country",
            minWidth: 50,
            sortable: true,
            visible: mappingMasterId === HCPMappingTabValue.Workgroup,
        },
        {
            id: "forecastGroupId",
            label: "Forecast Group",
            minWidth: 50,
            sortable: true,
            visible: mappingMasterId === HCPMappingTabValue.Workgroup,
        },
        {
            id: "contactTypeId",
            label: "Contact Type",
            minWidth: 50,
            sortable: true,
            visible: mappingMasterId === HCPMappingTabValue.Workgroup,
        },
        {
            id: "staffingPriority",
            label: "Staffing Priority",
            minWidth: 50,
            sortable: true,
            visible: mappingMasterId === HCPMappingTabValue.Workgroup,
        },
        {
            id: "site",
            label: "Site",
            minWidth: 50,
            sortable: true,
            visible: mappingMasterId === HCPMappingTabValue.CostCenter,
        },
        {
            id: "level",
            label: "Level",
            minWidth: 50,
            sortable: true,
            visible: mappingMasterId === HCPMappingTabValue.CostCenter,
        },
        {
            id: "organization",
            label: "Organization",
            minWidth: 50,
            sortable: true,
            visible: mappingMasterId === HCPMappingTabValue.CostCenter,
        },
        {
            id: "planningEntity",
            label: "Planning Entity",
            minWidth: 50,
            sortable: true,
            visible: mappingMasterId === HCPMappingTabValue.CostCenter,
        },
        {
            id: "costCenterType",
            label: "Cost Center Type",
            minWidth: 50,
            sortable: true,
            visible: mappingMasterId === HCPMappingTabValue.CostCenter,
        },
        {
            id: "updatedBy",
            label: "Last Updated By",
            minWidth: 50,
            sortable: true,
            visible: true,
        },
        {
            id: "updatedDate",
            label: "Last Updated Date",
            customTemplate: (value: any) => {
                return (<p>{dateFormat(value.updatedDate, "yyyy-mm-dd")}</p>);
            },
            minWidth: 50,
            sortable: true,
            visible: true,
        },
        {
            id: "id",
            label: "Action",
            minWidth: 100,
            align: "right",
            visible: true,
            sortable: false,
            customTemplate: (value: any) => {
                return (
                    <div className="action-buttons">
                        <Link
                            underline="none"
                            onClick={() => onEdit(value)}
                            variant="button"
                        >
                            <Tooltip title={"Edit"} placement="top" arrow>
                                <DriveFileRenameOutlineOutlinedIcon />
                            </Tooltip>
                        </Link>

                        <Link
                            underline="none"
                            onClick={() => onDelete(value)}
                            variant="button"
                        >
                            <Tooltip title={"Delete"} placement="top" arrow>
                                <DeleteOutlineOutlinedIcon
                                    sx={{ color: "rgb(239, 83, 80)" }}
                                />
                            </Tooltip>
                        </Link>

                    </div>
                );
            },
        },
    ];   

    const deleteModalDialogContent = () => {
        return (
            <NxModalDialog
                id="delete"
                titleName="Delete?"
                bodyContent={
                    <div className="text-popup">
                        Are you sure you want to delete {
                            <b>
                                {mappingMasterId !== 2
                                    ? currentData.workGroup
                                    : currentData.costCenterNo}

                            </b>}?
                    </div>
                }
                footerContent={
                    <LoadingButton
                        variant="contained"
                        color="error"
                        loading={isDeleteLoader}
                        className="footerbutton"
                        sx={{
                            py: 1,
                            px: 2,
                            fontSize: "15px",
                            letterSpacing: "0.05em",
                            lineHeight: "22px",
                            my: 1,
                            mx: 1,
                        }}
                        autoFocus
                        onClick={() => {
                            setDeleteDialogOpen(false);
                            onDeleteChange(currentData);
                        }}
                    >
                        Delete
                    </LoadingButton>
                }
                onClose={() => {
                    setDeleteDialogOpen(false);
                }}
                open={deleteDialogOpen}
                titleContent={<></>}
            />
        );
    };
    return (
        <div>
            <DataTable value={data} paginator
                rows={10}
                filters={globalFilters}
                paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10,50, 100, 200]}
                onValueChange={onValueChange}>

                {columns.map((column, index) => {
                    return (
                        <Column
                            key={index}
                            hidden={!column.visible}
                            field={column.id}
                            header={column.label}
                            sortable={column.sortable}
                            style={{ width: '50px' }}
                            body={column.customTemplate}
                        ></Column>
                    );
                })}
            </DataTable>
            {deleteModalDialogContent()}
        </div>
    );
}
export default MappingList;


