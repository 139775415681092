import { Dialog } from "primereact/dialog";
import * as React from "react";
import "./PrwcDialog.scss";

const PrwcDialog = (props: any) => {
  const { isVisible, onHide, modal = false, footer, header } = props;
  return (
    <div>
      <Dialog
        footer={footer()}
        header={header}
        visible={isVisible}
        modal={modal}
        className="w-full p-4 lg:w-1/2 lg:p-0"
        onHide={() => onHide()}
      >
        <div> {props.children}</div>
      </Dialog>
    </div>
  );
};

export default PrwcDialog;
