export interface AdminPage {
    activeTabValue: string;
}
  
export interface DNAState {
    adminPage: AdminPage
}

export function createInitialDNAState(): DNAState {
    return {
        adminPage: {
            activeTabValue: "1"
        }
    }
}