import React,{ createContext, useContext, useState } from "react";

let wfhInfo: any = {};

const wfhContext = createContext(wfhInfo);

export const WFHContextProvider = (props: any) => {        
    const [WFHRequestData, setWFHRequestData] = useState<any>([]);
    const [WFHShiftReportRequestData, setWFHShiftReportRequestData] = useState<any>([]);
    const [WFHUserReportRequestData, setWFHUserReportRequestData] = useState<any>([]);
    
    wfhInfo = {      
        WFHRequestData,
        setWFHRequestData,
        WFHShiftReportRequestData,
        setWFHShiftReportRequestData,
        WFHUserReportRequestData,
        setWFHUserReportRequestData
    }

    return (<>
        <wfhContext.Provider value={wfhInfo}>
            {props.children}
        </wfhContext.Provider>
    </>);
}

export const useWFHContext = () => {
    return useContext(wfhContext);
}
