import { ToggleButton } from "primereact/togglebutton";
import * as React from "react";

const PrwToggleButton = (props: any) => {
  let { options, ...restProps } = props;

  // Defaut Values
  options["onLabel"] = options["onLabel"] ?? "Yes";
  options["offLabel"] = options["offLabel"] ?? "No";
  options["onIcon"] = options["onIcon"] ?? "pi pi-check";
  options["offIcon"] = options["offIcon"] ?? "pi pi-times";

  return (
    <div>
      <ToggleButton
        onLabel={options.onLabel}
        offLabel={options.offLabel}
        onIcon={options.onIcon}
        offIcon={options.offIcon}
        checked={restProps.value}
        onChange={(e: any) => {
          restProps.onChange(e.value);
        }}
        className="px-2 py-1"
      />
    </div>
  );
};

export default PrwToggleButton;
