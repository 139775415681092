import './AlertsFilter.css';
import { Dialog } from "primereact/dialog";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import filtericon from "../../../../../assests/images/filter-img.png";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Modal } from "antd";
import { confirmDialog } from 'primereact/confirmdialog';
import { useState, useRef, useEffect } from 'react';
import DynamicComponent from "../../../../Core/components/DynamicComponent";
import AlertsFilterOption from '../AlertsFilterOption';
import SaveUsFilter from "../SaveUsFilter";
import { parseJSONToObject } from "../../../../../services/helper";
import { checkUndefinedAndNull, checkUndefinedAndNullLength } from "../../../services/helper";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { useHCPService } from "../../../services/HCPService";
import { useCommonService } from "../../../../../services/CommonService";

const AlterFilter = (props) => {
    const { onSaveFilterChange, saveFilters, onDeleteFilterSetting, onChangeCallback,hideStatusTab } = props
    const [displayBasic, setDisplayBasic] = useState<boolean>(false);
    const [saveAs, setSaveAs] = useState<boolean>(false);
    const [appliedFilter, setAppliedFilter] = useState<any>([]);
    const [selectedSaveFilter, setSelectedSaveFilter] = useState<any>(appliedFilter);
    const ref = useRef<any>();
    const [showTab, setShowTab] = useState<boolean>(true);
    const [showAfilter, setShowAfilter] = useState<boolean>(false);
    const hcpService = useHCPService();
    const commonService = useCommonService();

    useEffect(() => {
        if (checkUndefinedAndNull(appliedFilter))
            setSelectedSaveFilter(appliedFilter);
    }, [appliedFilter])

    const onHide = () => {
        setAppliedFilter("");
        setDisplayBasic(false);
    };  
    const editFilter = (item) => {
        let updatedItem = { ...item, 'filterValues': JSON.parse(item.filterValues) }
        setSelectedSaveFilter(updatedItem);
        setAppliedFilter(updatedItem);
        if (checkUndefinedAndNullLength(updatedItem.filterValues) && checkUndefinedAndNullLength(updatedItem.filterValues[0].advanceFilters)) {           
            setShowTab(false);           
        } else           
            setShowTab(true);                 
        setDisplayBasic(true);
    };
    const onDelete = (item) => {
        confirmDialog({
            message: "Do you want to delete " + item.filterName + " filter?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            className: "deletedialoug",
            accept: () => deleteFilter(item),
        });
    };
    const deleteFilter = (item) => {
        onDeleteFilterSetting(item);
    };
    const savefilterListContent = (item, i) => {
        return (
            <li
                key={i}
                className={
                    selectedSaveFilter !== undefined &&
                        selectedSaveFilter !== null &&
                        selectedSaveFilter.id === item.id
                        ? "active"
                        : ""
                }
            >
                {/* <PanoramaFishEyeIcon /> */}
                {selectedSaveFilter !== undefined &&
                    selectedSaveFilter !== null &&
                    selectedSaveFilter.id === item.id ? (
                    <RadioButtonCheckedIcon />
                ) : (
                    <RadioButtonUncheckedIcon />
                )}
                <span
                    onClick={() => {
                        onApplyFilter(item);
                    }}
                >
                    {item.filterName}
                </span>
                <div className="filteredit-deletebutn">
                    <div className="edit-delete-butn">
                        <EditIcon
                            onClick={() => {
                                editFilter(item);
                            }}
                        />
                        <DeleteIcon
                            onClick={() => onDelete(item)}
                            sx={{ color: "red !important" }}
                        />
                    </div>
                </div>
            </li>
        );
    };
    const onApplyFilter = (data) => {
        let updatedData = { ...data, 'filterValues': parseJSONToObject(data?.filterValues) }
        setDisplayBasic(false);
        setSelectedSaveFilter(updatedData);
        if (checkUndefinedAndNull(data))
            setAppliedFilter(null)
        else
            setAppliedFilter(updatedData)
         
        if (onChangeCallback !== undefined) {
            var request = {filterId: data?.id,filterValues:data?.filterValues };
            onChangeCallback(request);
        }
    };
    const onCancelSaveFilter = () => {
        setSaveAs(false);
    }
    const addNewFilter = () => {        
        setAppliedFilter("");
        setSelectedSaveFilter(null);       
        setShowTab(true);
        setDisplayBasic(true);

          if(ref.current !== undefined)
              ref.current.clearAllParameter();
    };
    const onSaveFilterSetting = (filterData) => {
        let req = { filterDetail: JSON.stringify(filterData) }
        hcpService
            .saveFilterSetting(req)
            .then((response) => {
                let result = response.data;
                if (result.isSuccess) {
                    let updatedFilterData = { ...filterData, 'id': result.filterId }
                    setSelectedSaveFilter(updatedFilterData)
                    onSaveFilterChange(updatedFilterData)
                    setAppliedFilter(updatedFilterData)
                    commonService.showToast("success", result.errorMessage)
                } else {
                    commonService.showToast("error", result.errorMessage);
                }
            })
            .catch((err) => {
                commonService.showToast("error", err);
            });
    };
    const onSaveFilter = (filterData) => {
        setSaveAs(false);
        setSelectedSaveFilter(filterData);
        onSaveFilterSetting(filterData);
    };
    const getWidgetFilterByType = () => {
        return (
            <DynamicComponent
                ref={ref}
                onApplyFilter={onApplyFilter}
                onSaveFilter={onSaveFilter}
                component={AlertsFilterOption}
                onHide={onHide}
                data={selectedSaveFilter}
                hideStatusTab={hideStatusTab}
            />
        );
    };
    const filterListContent = () => {
        return (
            <div className="alertsavefilters">
                <ul>
                    <li onClick={addNewFilter}>
                        <PostAddIcon />
                        Add New
                    </li>
                    <li
                        className="divider"
                        onClick={() => {
                            onApplyFilter(null);                        
                        }}
                    >
                        <ClearAllIcon />
                        Clear Filter
                    </li>
                    {saveFilters != null && (
                        <>
                            {saveFilters != null && (
                                <li className="personal-filter-option">
                                    <ul>
                                        {saveFilters
                                            .map((item, i) => {
                                                return savefilterListContent(item, i);
                                            })}
                                    </ul>
                                </li>
                            )}
                        </>
                    )}
                </ul>
            </div>
        );
    };
    const runFilter = () => {
        let data = ref.current.runFilter();
        let updatedSelectedFilter = {
            ...selectedSaveFilter,
            filterValues: data.filterValues,
        };
        onApplyFilter(updatedSelectedFilter);
    };
    const footerContent = () => {
        return (
            <>
                <div className="filter-footer cf">
                    <div className="clearfilter">
                        <div
                            onClick={() => {
                                ref.current.clearAllParameter();
                            }}
                        >
                            <ClearAllIcon /> Clear All Parameters
                        </div>
                    </div>
                    <div className="buttnsfilter">
                        <div className="common-iconbutton">
                            <Button
                                variant="contained"
                                className="secondary"
                                onClick={() => {
                                    if (showAfilter) {
                                        ref.current.validatingForm()
                                            .then(isValid => {
                                                if (isValid) {
                                                    setSaveAs(true);
                                                }
                                            })
                                    } else {
                                        setSaveAs(true);
                                    }
                                }}
                            >
                                Save As
                            </Button>
                            {selectedSaveFilter !== null &&
                                selectedSaveFilter !== undefined &&
                                selectedSaveFilter.id !== undefined && (
                                    <Button
                                        variant="contained"
                                        className="secondary"
                                        onClick={() => {
                                            if (showAfilter) {
                                                ref.current.validatingForm()
                                                    .then(isValid => {
                                                        if (isValid) {
                                                            ref.current.saveAsFilter(selectedSaveFilter);
                                                        }
                                                    })
                                            } else {
                                                ref.current.saveAsFilter(selectedSaveFilter);
                                            }
                                        }}
                                    >
                                        Save
                                    </Button>
                                )}
                            <Button variant="contained" className="secondary" onClick={onHide}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={() => {
                                if (showAfilter) {
                                    ref.current.validatingForm()
                                        .then(isValid => {
                                            if (isValid) {
                                                runFilter()
                                            }
                                        })
                                } else {
                                    runFilter()
                                }
                            }}>
                                Run
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            <div className="HCPAlertFilter">
                <img src={filtericon} alt="" />
                {filterListContent()}                
                <Modal
                    title="Filter"
                    open={displayBasic}
                    width={900}
                    onCancel={onHide}
                    footer={footerContent()}                    
                    className="filter-popup cf"
                >  
                 {showTab && (
                        <div className="popup-content">{getWidgetFilterByType()}</div>
                    )}        
                </Modal>
                <Dialog
                    header="Filter Name"
                    visible={saveAs}
                    style={{ width: "25vw" }}
                    onHide={() => {
                        setSaveAs(false);
                    }}
                >
                     <SaveUsFilter
                        onSubmit={(filterObj) => {
                            ref.current.saveAsFilter(filterObj);
                        }}
                        onCancel={onCancelSaveFilter}
                    />                   
                </Dialog>               
            </div>
        </>
    )
}
export default AlterFilter;