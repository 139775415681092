export const apigatewayurl = "wfo/";
export const getWFHDetailUrl = apigatewayurl + "WFODetail/GetAll";
export const getWFHByIdUrl = apigatewayurl + "WFODetail/GetById";
export const manageWFHDetailUrl = apigatewayurl + "WFODetail/Manage";
export const deleteWFHDetailUrl = apigatewayurl + "WFODetail/Delete";
export const getUserScheduleUrl = apigatewayurl + "Schedule/GetSchedules";
export const exportToExcelWFHUrl = apigatewayurl + "WFODetail/ExportToExcel";
export const getWFOShiftReportDataUrl = apigatewayurl + "Reports/GetShiftReport";
export const getWFOUserReportDataUrl = apigatewayurl + "Reports/GetUserReport";
export const newReportFieldsArr = ["Schedule Date", "On WFO"];
export const oldReportFieldsArr = ["scheduleDate", "onWFO"];
export const newExportToExcelFieldsArr = ["User Id", "Employee Name", "Schedule Date", "Start Time", "End Time", "Activity Name", "Applied Date Time"];
export const oldExportToExcelFieldsArr = ["userId", "employeeName", "scheduleDate", "startTime", "endTime", "activityName", "appliedDate"];