import React, { createContext, useContext } from "react";
import { useAxiosContext } from "../../../contextProviderFiles/AxiosContextProvider";

import { getEmployeeReportUrl, getBlueprintReportUrl, sendExcelReportToBoxUrl } from "../constants/ReportConstants";

let operationsAllowed: any = {};
const ReportServiceContext = createContext(operationsAllowed);

export const ReportServiceProvider = (props) => {
    const { axiosObj } = useAxiosContext();

    const getEmployeeReportDetails = (request) => {                
        return axiosObj.post(getEmployeeReportUrl, request);
    };

    const getBlueprintReportDetails = (request) => {                
        return axiosObj.post(getBlueprintReportUrl, request);
    };

    const sendExcelReportToBox = (request) => {                
        return axiosObj.post(sendExcelReportToBoxUrl, request);
    };
   
    operationsAllowed = {
        getEmployeeReportDetails,
        getBlueprintReportDetails,
        sendExcelReportToBox
    };
    return (
        <ReportServiceContext.Provider value={operationsAllowed}>
            {props.children}
        </ReportServiceContext.Provider>
    );
};

export const useReportService = () => {
    return useContext(ReportServiceContext);
};