import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { forwardRef, useEffect, useState } from "react";
import "./AgentStatusList.css";
import { GetColumnToCustomTemplate, GetColumnToShow } from "../../../../services/helper";
import { useRTDService } from "../../../../services/RTDService";
const AgentStatusList = forwardRef((props: any,ref) => {
  const { data, onChangeItem, widgetInfo, columns, rowHeaderGroupTemplate, globalFilterInfo } = props;
  const rtdService = useRTDService()
  const [expandedRows, setExpandedRows] = useState<any>([])
  const [hideGrouping, setHideGrouping] = useState<any>(widgetInfo.isHideGrouping)
  const [multiSortMeta, setMultiSortMeta] = useState<any>([{field: "RoutingProfile", order:1}]);
  const [formattedColumns, setFormattedColumns] = useState<any>(columns)
  const [columnToShow, setColumnToShow] = useState<any>(formattedColumns)

  useEffect(()=>{
    let multiSortMeta = widgetInfo.multiSortMeta !== undefined && widgetInfo.multiSortMeta !== null ? JSON.parse(widgetInfo.multiSortMeta) : [{field: "RoutingProfile", order:1}]
    setMultiSortMeta(multiSortMeta)
  },[widgetInfo.multiSortMeta])

  useEffect(() => {
    setHideGrouping(widgetInfo.isHideGrouping)
  }, [widgetInfo.isHideGrouping])

  useEffect(() => {
    let updatedColumns = GetColumnToCustomTemplate(rtdService,columns, widgetInfo.appliedCFRules);
    setFormattedColumns(updatedColumns)
    let filterColumns = GetColumnToShow(updatedColumns, widgetInfo.selectedColumns);
    setColumnToShow(filterColumns);
  }, [widgetInfo.selectedColumns,widgetInfo.appliedCFRules])

  const onSaveColReorder = (item) => {
    let filterColumns = GetColumnToShow(formattedColumns, widgetInfo.selectedColumns);

    var element = filterColumns[item.dragIndex];
    filterColumns.splice(item.dragIndex, 1);
    filterColumns.splice(item.dropIndex, 0, element);
    let reorderColumns = filterColumns.map((item) => {
      return item.id;
    });
    let updatedWidgetInfo = {...widgetInfo, selectedColumns: JSON.stringify(reorderColumns)}
    onChangeItem(updatedWidgetInfo)
  };
  
  const onSort = (e) => {
    setMultiSortMeta(e.multiSortMeta)

    let updatedWidgetInfo = {...widgetInfo, "multiSortMeta": JSON.stringify(e.multiSortMeta)}
    onChangeItem(updatedWidgetInfo)
  };

  return (
    <div className="AgentStatusList">
      { hideGrouping ? (
        <DataTable
            value={data}
            filters={globalFilterInfo}
            globalFilterFields={['FullName', 'RoutingProfile', 'AgentStatus', 'ContactState','Username','PreviousAgentStatus', 'CapacityPercentage']}
            responsiveLayout="scroll"
            multiSortMeta={multiSortMeta}
            sortMode="multiple"
            onSort={onSort}
            resizableColumns
            removableSort
            size="small"
            reorderableColumns
            onColReorder={onSaveColReorder}
            columnResizeMode="fit"
            scrollHeight={widgetInfo.dynamicHeight}
          >
          {columnToShow.map((column, index) => {
            return (
              <Column
                key={index}
                sortable={column.sortable !== undefined ? column.sortable : false}
                resizeable={
                  column.resizeable !== undefined ? column.resizeable : false
                }
                field={column.id}
                header={column.label}
                body={column.customTemplate}
              ></Column>
            );
          })}
        </DataTable>  
      ) :(
        <DataTable
          value={data}
          filters={globalFilterInfo}
          globalFilterFields={['FullName', 'RoutingProfile', 'AgentStatus', 'ContactState','Username','PreviousAgentStatus', 'CapacityPercentage']}
          multiSortMeta={multiSortMeta}
          sortMode="multiple"
          onSort={onSort}
          responsiveLayout="scroll"
          expandableRowGroups          
          expandedRows={expandedRows}
          resizableColumns
          reorderableColumns
          onColReorder={onSaveColReorder}
          columnResizeMode="fit"
          onRowToggle={(e) => setExpandedRows(e.data)}
          scrollable
          size="small"
          scrollHeight={widgetInfo.dynamicHeight}
          rowGroupMode="subheader"
          groupRowsBy="RoutingProfile"
          rowGroupHeaderTemplate={(e) => rowHeaderGroupTemplate(e)}
          rowGroupFooterTemplate={() => {
            return <></>;
          }}
        >
          {columnToShow.map((column, index) => {
            return (
              <Column
                key={index}
                sortable={column.sortable !== undefined ? column.sortable : false}
                resizeable={
                  column.resizeable !== undefined ? column.resizeable : false
                }
                field={column.id}
                header={column.label}
                body={column.customTemplate}
              ></Column>
            );
          })}
        </DataTable>   
      )}    
    </div>
  );
})

export default AgentStatusList;
