import * as React from "react";
import { Fragment } from "react";
import "../VerticalFormTemplate/VericalFormTemplate.css";

const VerticalFormTemplate = (props: any) => {
  const { uiSchema } = props;
  return (
    <>
      {props.title && (
        <div
          className={`my-2 ${
            uiSchema?.["ui:options"]?.["label"]?.["classNames"] ??
            "font-semibold"
          }`}
        >
          {props.title}
        </div>
      )}
      <div className={`${uiSchema?.["ui:options"]?.["body"]?.["classNames"]}`}>
        <div className="p-form">
          {props.properties.map((element: any) => (
            <Fragment key={element.name}>{element.content}</Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default VerticalFormTemplate;
