import { REGEX_IMAGE_EXTENSIONS } from "../../../constants/CommonConstant";

export default class Validators {
  static isStringEmpty = (value: any) => {
    if (value) {
      return false;
    }

    return true;
  };

  static isValidLength = (value: string, length: number) => {
    if (value && length) {
      return value.trim()?.length === length;
    }

    return true;
  };

  static isMaxLengthValid = (value: string, maxLength: number) => {
    if (value && maxLength) {
      return value.trim()?.length <= maxLength;
    }

    return true;
  };

  static isNumberEmpty = (value: any) => {
    if (value) {
      return false;
    }

    return true;
  };

  static isBooleanEmpty = (value: any) => {
    if (value) {
      return false;
    }

    return true;
  };

  static isRegexPatternValid = (pattern: any, value: any) => {
    if (pattern && value) {
      return pattern.test(value);
    }

    return true;
  };

//   static isPersonalEmail = (value: any) => {
//     if (value) {
//       let afterAtTheRate: string = value.substring(
//         value.lastIndexOf("@") + 1,
//         value.lastIndexOf(".")
//       );
//       afterAtTheRate = afterAtTheRate ? afterAtTheRate.toLowerCase() : "";

//       if (
//         afterAtTheRate &&
//         (afterAtTheRate === Email.GMAIL ||
//           afterAtTheRate === Email.OUTLOOK ||
//           afterAtTheRate === Email.LIVE ||
//           afterAtTheRate === Email.YAHOO)
//       ) {
//         return true;
//       }
//     }

//     return false;
//   };

  static isValidFile = async (file: File, validExtensions: any) => {
    if (file) {
      if (!file.name.toLowerCase().match(validExtensions)) {
        return false;
      }

      return true;
    }

    return false;
  };


  static isValidImage = async (image: File) => {
    if (image) {
      if (!image.name.toLowerCase().match(REGEX_IMAGE_EXTENSIONS)) {
        return false;
      }

      const isValid = await isValidImageContent(image);
      return isValid;
    }

    return false;
  };

//   static isValidPhoneNumber = (phoneNumber: string) => {
//     if (phoneNumber) {
//       return isValidPhoneNumber(phoneNumber);
//     }

//     return true;
//   }
}

const isValidImageContent = (image: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => {
        resolve(false);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(image);
  });
};
