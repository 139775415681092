import { Box, Checkbox, FormControlLabel, 
 } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Tree, TreeBranch } from "../../../../../components/NxTreeView/types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

import "./PrwTreeView.css";

interface TreeItemProps {
  id: string;
  name: string;
  isSelected: boolean | undefined;
  isPartialSelected: boolean | undefined;
  children: ReadonlyArray<JSX.Element>;
  onItemSelectCallback: (e: React.MouseEvent<HTMLInputElement>) => void;
}

const TreeItem = (props: TreeItemProps) => {
  const {
    onItemSelectCallback,
    name,
    isSelected,
    isPartialSelected,
    children,
  } = props;

  const [isOpen, setIsOpen] = useState(isSelected);

  const onItemClick = (e) => {
    onItemSelectCallback(e);
  };

  return (
    <>
      <li>
        {children.length > 0 && (
          <span
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </span>
        )}
        {isPartialSelected ? (
          <FormControlLabel
            control={
              <IndeterminateCheckBoxOutlinedIcon
                onClick={onItemClick}
                sx={{
                  width: "1.7rem",
                  height: "1.7rem",
                  marginRight: "8.5px",
                  marginLeft: "7.5px",
                  marginTop: "1px",
                  marginBottom: "1px",
                  opacity: 0.6,
                }}
              />
            }
            label={name}
          />
        ) : isSelected || !isPartialSelected ? (
          <FormControlLabel
            control={
              <Checkbox
                className="treecheckbox"
                checked={isSelected}
                onChange={onItemClick}
              />
            }
            label={name}
          />
        ) : (
          <FormControlLabel
            control={
              <IndeterminateCheckBoxOutlinedIcon
                onClick={onItemClick}
                sx={{
                  width: "2rem",
                  height: "2rem",
                  marginRight: "5px",
                  marginLeft: "5px",
                  marginTop: "1.1px",
                  marginBottom: "1px",
                }}
              />
            }
            label={name}
          />
        )}
      </li>
      {isOpen && children}
    </>
  );
};

const PrwTreeView = (props: any) => {
  let { options, ...restProps } = props;
  const [treeData, setTreeData] = useState<any>(options.treeOptions);

  let selectedPrivileges: any = [];
  useEffect(() => {
    setTreeData(treeData);
  },[treeData]);


  const onSubPartialSelection = (parentItem, childItems, currentBranch) => {
    let updatedItems = childItems.map((el) => {
      let updatedItem = {
        ...el,
        ["branches"]: onSubPartialSelection(el, el.branches, currentBranch),
      };

      return {
        ...updatedItem,
        ["selected"]:
          updatedItem.id === currentBranch.id
            ? currentBranch.selected
            : parentItem.id === currentBranch.id
            ? parentItem.selected
            : updatedItem.branches.length > 0
            ? updatedItem.branches.filter((a) => a.selected).length ===
              updatedItem.branches.length
            : updatedItem.selected,
        ["partialSelected"]:
          updatedItem.branches.filter((a) => a.selected).length > 0 &&
          updatedItem.branches.length >
            updatedItem.branches.filter((a) => a.selected).length, //el.id === currentBranch.parentId ? el.branches.filter(a => a.selected).length > 0 : el.partialSelected
      };
    });

    return updatedItems;
  };

  const onPartialSelection = (items, currentBranch) => {
    let updatedItems = items.map((el) => {
      let updatedItem = {
        ...el,
        ["branches"]: onSubPartialSelection(el, el.branches, currentBranch),
      };

      return {
        ...updatedItem,
        ["selected"]:
          updatedItem.id === currentBranch.id
            ? currentBranch.selected
            : updatedItem.branches.length > 0
            ? updatedItem.branches.filter((a) => a.selected).length ===
              updatedItem.branches.length
            : updatedItem.selected,
        ["partialSelected"]:
          updatedItem.branches.filter((a) => a.selected || a.partialSelected)
            .length > 0 &&
          updatedItem.branches.length >
            updatedItem.branches.filter((a) => a.selected)
              .length, //el.id === currentBranch.parentId ? el.branches.filter(a => a.selected).length > 0 : el.partialSelected
      };
    });

    setTreeData(updatedItems);   
  };

  const TreeItemSelect = (e, branch) => {
    branch.selected = e.target.checked;    

    if (branch.branches !== undefined && branch.branches.length > 0) {
      branch.branches.map((el) => TreeItemSelect(e, el));
    }

    onPartialSelection(treeData, branch);
  };

  const getSelectedPrivilege = (items) => {
    items.forEach((item) => {
      if (item.selected || item.partialSelected) {
        if (selectedPrivileges.filter((i) => i === item.id).length === 0) {
          selectedPrivileges.push(item.id);
        }
      } else {
        selectedPrivileges.filter((i) => i !== item.id).map((a) => a);
      }

      if (item.branches.length > 0) getSelectedPrivilege(item.branches);
    });
  };

  const createTree = (branch: TreeBranch) =>
    branch.branches && (
      <TreeItem
        id={branch.id}
        key={branch.id}
        
        onItemSelectCallback={(e: any) => {
          TreeItemSelect(e, branch);
          getSelectedPrivilege(treeData);
          restProps.onChange(selectedPrivileges);         
        
          //if (onSelectCallback !== undefined) onSelectCallback(branch);
        }}
        isSelected={branch.selected}
        isPartialSelected={branch.partialSelected}
        name={branch.name}
      >
        {branch.branches.map((branch: TreeBranch) => {
          return (
            <ul key={branch.id} className="nested active">
              <Fragment>{createTree(branch)}</Fragment>
            </ul>
          );
        })}
      </TreeItem>
    );

  return (
    <Box className="privilege-text">
      { treeData && treeData.map((branch: TreeBranch, i: any) => (
        <ul key={i}>{createTree(branch)}</ul>
      ))}
    </Box>
  );
};

export default PrwTreeView;
