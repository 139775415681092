export enum OperationType {
    ADD = "ADD",
    EDIT= "EDIT",
    CLONE = "CLONE"
}

export enum AdminMasterTabValue {
    Domain = "1",
    Language= "2",
    ContactType= "3",
    LOB = "4"    
}

export enum DNAAdminTabValue {    
    LOB = "1",
    QUEUE = "2",
    Activity = "3"
}
export enum HCPMasterPagesTabValue {    
    Workgroup = "1",
    CostCenter = "2",
    ForecastGroup="3",
    ContactType="4",
    WebCountry="5"    
}
export enum HCPMappingTabValue {    
    Workgroup = "1",
    CostCenter = "2"   
}
export enum ShareDashboardRoleValue
{
    Previewer=1,
    Editor=2
}
export enum HCPAlertTabValue {    
    New= 1,
    Update = 2,
    Terminated=3,
    NotExists=4    
}
export enum HCPAlertStatus {    
    Pending = 1,
    Approve = 2,
    Reject = 3,
    Terminated = 4,
    NotExists=5      
}