import { createContext, useContext, useState } from "react";

let reportInfo: any = {};

const reportContext = createContext(reportInfo);

export const ReportContextProvider = (props: any) => {
    const [allEmployeeRequestData, setAllEmployeeRequestData] = useState<any>([]);
    const [allBlueprintRequestData, setAllBlueprintRequestData] = useState<any>([]);
    
    reportInfo = {      
        allEmployeeRequestData,
        setAllEmployeeRequestData,
        allBlueprintRequestData,
        setAllBlueprintRequestData
    }
    return (<>
        <reportContext.Provider value={reportInfo}>
            {props.children}
        </reportContext.Provider>
    </>);
}

export const useReportContext = () => {
    return useContext(reportContext);
}