import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useUserContext } from '../../../../../../contextProviderFiles/UserContextProvider';
import PickListOption from '../../PickListOption';
import './EmailChatFilterOption.css'
import { useRTDService } from '../../../../services/RTDService';
import { useCommonService } from '../../../../../../services/CommonService';
import { EmailAgeStatuses, HierarchyLevel1, MarketAnalysisTabConstants, WidgetViewItemConstants } from '../../../../constants/RTDConstants';
import { checkUndefinedAndNull } from '../../../../services/helper';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const EmailChatFilterOption = forwardRef((props:any,ref) => {
  const { onSaveFilter, data, viewItemId } = props
  const { loggedUserInfo } = useUserContext();
  const rtdService = useRTDService()
  const commonService = useCommonService()
  const [queues, setQueues] = useState<any>([]);
  const [domains, setDomains] = useState<any>([]);
  const [verticals, setVerticals] = useState<any>([]);
  const [planingEntities, setPlaningEntities] = useState<any>([]);
  const [forecastGroups, setForecastGroups] = useState<any>([]);
  const [targetQueues, setTargetQueues] = useState<any>([]);
  const [targetDomains, setTargetDomains] = useState<any>([]);
  const [targetVerticals, setTargetVerticals] = useState<any>([]);
  const [targetPlaningEntities, setTargetPlaningEntities] = useState<any>([]);
  const [targetForecastGroups, setTargetForecastGroups] = useState<any>([]);
  const [targetHierarchyLevel1s, setTargetHierarchyLevel1s] = useState<any>([]);
  const [hierarchyLevel1s, setHierarchyLevel1s] = useState<any>([]);
  const [targetEmailAgeStatuses, setTargetEmailAgeStatuses] = useState<any>([]);
  const [emailAgeStatuses, setEmailAgeStatuses] = useState<any>([]);
  const [value, setValue] = useState(0);

  useImperativeHandle(ref, () => ({
    saveAsFilter(data) {
      onSaveAsFilter(data)
    },
    clearAllParameter() {
      onClearAllParameters()
    },
    runFilter() {
      return onRunFilter()
    }
  }));

  useEffect(() => {
    let filterType
    switch (viewItemId) {
      case WidgetViewItemConstants.MarketAnalysisView:
        filterType = 'PHONE'
        break
      case WidgetViewItemConstants.ChatAnalysisView:
        filterType = 'CHAT'
        break
      case WidgetViewItemConstants.ChatAgeAnalysisView:
        filterType = 'CHAT'
        break
      case WidgetViewItemConstants.VisitorChatStatView:
        filterType = 'CHAT'
        break
      // case WidgetViewItemConstants.EmailQueueAnalysisView:
      //   filterType = 'EMAIL'
      //   break
      // case WidgetViewItemConstants.EmailAgeAnalysisView:
      //   filterType = 'EMAIL'
      //   break
      case WidgetViewItemConstants.EmailAnalysisView:
        filterType = 'EMAIL'
        break
    }

    setHierarchyLevel1s(HierarchyLevel1)
    getHierarchyLevels()

    setEmailAgeStatuses(EmailAgeStatuses)
    getEmailAgeStatuses()
    
    let reqObj = { type: filterType }
    rtdService.getQueueMappings(reqObj)
      .then((res) => {
        let result = res.data
        if (result.isSuccess) {
          getDomains(result.domains)
          getQueues(result.queues)
          getVerticals(result.verticals)
          getForecastGroups(result.forecastGroups)
          getPlaningEntities(result.planingEntities)
        }
        else {
          getDomains(result.domains)
          getQueues(result.contactTypes)
          getVerticals(result.verticals)
          getForecastGroups(result.forecastGroups)
          getPlaningEntities(result.planingEntities)
        }
      })
      .catch(err => {
        commonService.showToast("error", err);
      })
  }, [data?.filterValues]); // eslint-disable-line react-hooks/exhaustive-deps  

const getDomains = (mData) => { 
    if(checkUndefinedAndNull(mData) && checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues) 
      && checkUndefinedAndNull(data.filterValues[0].domains))
    {
      let targetData = data.filterValues[0].domains;
      let allData = mData;        
      setTargetDomains(targetData);
      let sourceData = allData.filter(item => {
         return targetData.filter(i => { return i.value === item.value }).length <= 0;
      });
      setDomains(sourceData);    
    }
    else{
      setDomains(mData ?? [])
      setTargetDomains([])
    }
}

const getQueues = (qData) => {
    if(checkUndefinedAndNull(qData) && checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues)
      && checkUndefinedAndNull(data.filterValues[0].queues))
    {
      let allData = qData
      let targetData = data.filterValues[0].queues
      setTargetQueues(targetData)
      let sourceData = allData.filter(item => {
         return targetData.filter(i => { return i.value === item.value }).length <= 0
      })
      setQueues(sourceData) 
    }
    else{
      setQueues(qData ?? [])
      setTargetQueues([])
    }     
}

const getForecastGroups = (mData) => { 
  if(checkUndefinedAndNull(mData) && checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues)
     && checkUndefinedAndNull(data.filterValues[0].forecastGroups))
  {
    let targetData = data.filterValues[0].forecastGroups;
    let allData = mData;        
    setTargetForecastGroups(targetData);
    let sourceData = allData.filter(item => {
       return targetData.filter(i => { return i.value === item.value }).length <= 0;
    });
    setForecastGroups(sourceData);    
  }
  else{
    setForecastGroups(mData ?? [])
    setTargetForecastGroups([])
  }
}

const getVerticals = (qData) => {
    if(checkUndefinedAndNull(qData) && checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues)
      && checkUndefinedAndNull(data.filterValues[0].verticals))
    {
      let allData = qData;
      let targetData = data.filterValues[0].verticals;
      setTargetVerticals(targetData);
      let sourceData = allData.filter(item => {
        return targetData.filter(i => { return i.value === item.value }).length <= 0;
      });
      setVerticals(sourceData);    
    }
    else{
      setVerticals(qData ?? [])
      setTargetVerticals([])
    }     
}

const getPlaningEntities = (qData) => {
  if(checkUndefinedAndNull(qData) && checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues)
      && checkUndefinedAndNull(data.filterValues[0].planingEntities))
  {
    let allData = qData;
    let targetData = data.filterValues[0].planingEntities;
    setTargetPlaningEntities(targetData);
    let sourceData = allData.filter(item => {
      return targetData.filter(i => { return i.value === item.value }).length <= 0;
    });
    setPlaningEntities(sourceData);    
  }
  else{
    setPlaningEntities(qData ?? [])
    setTargetPlaningEntities([])
  }     
}

const getHierarchyLevels = () => {
  if(checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues) 
    && checkUndefinedAndNull(data.filterValues[0].hierarchyLevel1s))
  {
    let targetData = data.filterValues[0].hierarchyLevel1s
    setTargetHierarchyLevel1s(targetData)
    let sourceData = HierarchyLevel1.filter(item => {
      return targetData.filter(i => { return i.value === item.value }).length <= 0
    })
    setHierarchyLevel1s(sourceData);   
  }
  else{
    setHierarchyLevel1s(HierarchyLevel1 ?? [])
    setTargetHierarchyLevel1s([])
  }     
}

const getEmailAgeStatuses = () => {
  if(checkUndefinedAndNull(data) && checkUndefinedAndNull(data.filterValues) 
    && checkUndefinedAndNull(data.filterValues[0].emailAgeStatuses))
  {
    let targetData = data.filterValues[0].emailAgeStatuses
    setTargetEmailAgeStatuses(targetData)
    let sourceData = EmailAgeStatuses.filter(item => {
      return targetData.filter(i => { return i.value === item.value }).length <= 0
    })
    setEmailAgeStatuses(sourceData);   
  }
  else{
    setEmailAgeStatuses(EmailAgeStatuses ?? [])
    setTargetEmailAgeStatuses([])
  }     
}

const onSaveAsFilter = (filterData) => {
    //setSaveAs(false);

    let filterValues = [{
      domains: targetDomains,
      queues: targetQueues,
      verticals: targetVerticals,
      forecastGroups: targetForecastGroups,
      planingEntities: targetPlaningEntities,
      hierarchyLevel1s: targetHierarchyLevel1s,
      emailAgeStatuses: targetEmailAgeStatuses
    }];

    let savefilterObj = {
      ...filterData,
      "userId": loggedUserInfo.userID,
      "filterValues": filterValues
    };

    onSaveFilter(savefilterObj);
}

const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setValue(newValue);
};

const onChange = (event) => {
  switch (value) {
    case 0:
      setQueues(event.source)
      setTargetQueues(event.target)
      break
    case 1:
      setDomains(event.source)
      setTargetDomains(event.target)
      break
    case 2:
      setVerticals(event.source)
      setTargetVerticals(event.target)
      break
    case 3:
      setPlaningEntities(event.source)
      setTargetPlaningEntities(event.target)
      break
    case 4:
      setForecastGroups(event.source)
      setTargetForecastGroups(event.target)
      break
    case 5:
      setHierarchyLevel1s(event.source)
      setTargetHierarchyLevel1s(event.target)
      break
    case 6:
      setEmailAgeStatuses(event.source)
      setTargetEmailAgeStatuses(event.target)
      break
  }
}

  const onClearAllParameters = () => {
    let clearQueues = queues.concat(targetQueues).sort((a, b) => a.id - b.id)
    setQueues(clearQueues)
    let clearDomains = domains.concat(targetDomains).sort((a, b) => a.id - b.id)
    setDomains(clearDomains)
    let clearVertical = verticals.concat(targetVerticals).sort((a, b) => a.id - b.id)
    setVerticals(clearVertical)
    let clearForecast = forecastGroups.concat(targetForecastGroups).sort((a, b) => a.id - b.id)
    setForecastGroups(clearForecast)
    let clearPlanEntity = planingEntities.concat(targetPlaningEntities).sort((a, b) => a.id - b.id)
    setPlaningEntities(clearPlanEntity)
    let clearHierarchyLevel1 = hierarchyLevel1s.concat(targetHierarchyLevel1s).sort((a, b) => a.id - b.id)
    setHierarchyLevel1s(clearHierarchyLevel1)
    let clearEmailAgeStatuses = emailAgeStatuses.concat(targetEmailAgeStatuses).sort((a, b) => a.id - b.id)
    setEmailAgeStatuses(clearEmailAgeStatuses)

    setTargetQueues([])
    setTargetDomains([])
    setTargetVerticals([])
    setTargetPlaningEntities([])
    setTargetForecastGroups([])
    setTargetHierarchyLevel1s([])
    setEmailAgeStatuses([])
    setValue(0)
  }

  const onRunFilter = () => {
    let filterValues = [{
      domains: targetDomains,
      queues: targetQueues,
      verticals: targetVerticals,
      forecastGroups: targetForecastGroups,
      planingEntities: targetPlaningEntities,
      hierarchyLevel1s: targetHierarchyLevel1s,
      emailAgeStatuses: targetEmailAgeStatuses 
    }];

    setValue(0)
    
    return { "filterValues": filterValues }
    //onApplyFilter({"filterValues" : filterValues});
  }

  const getSourceDataById = (id) => {
    switch(id)
    {
      case 0: 
        return queues
      case 1: 
        return domains
      case 2: 
        return verticals
      case 3:
        return planingEntities
      case 4:
        return forecastGroups
      case 5: 
        return hierarchyLevel1s
      case 6: 
        return emailAgeStatuses
    }
  }

  const getTargetDataById = (id) => {
    switch(id)
    {
      case 0: 
        return targetQueues
      case 1: 
        return targetDomains
      case 2: 
        return targetVerticals
      case 3:
        return targetPlaningEntities
      case 4:
        return targetForecastGroups
      case 5: 
        return targetHierarchyLevel1s
      case 6: 
        return targetEmailAgeStatuses
    }
  }

  return (<>
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: "auto",
      }}
    >
      {/* <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab label="Queue" {...a11yProps(0)} />
        <Tab label="Domain" {...a11yProps(1)} />
        <Tab label="Business Vertical" {...a11yProps(2)} />
        <Tab label="Planning Entity" {...a11yProps(3)} />
        <Tab label="Forecast Group" {...a11yProps(4)} />
        <Tab label="Hierarhy Level 1" {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <PickListOption sourceData={queues} targetData={targetQueues} onChange={onChange} filterText={"Queue"} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PickListOption sourceData={domains} targetData={targetDomains} onChange={onChange} filterText={"Domain"} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PickListOption sourceData={verticals} targetData={targetVerticals} onChange={onChange} filterText={"Business Vertical"} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PickListOption sourceData={planingEntities} targetData={targetPlaningEntities} onChange={onChange} filterText={"Planning Entity"} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <PickListOption sourceData={forecastGroups} targetData={targetForecastGroups} onChange={onChange} filterText={"Forecast Group"} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <PickListOption sourceData={hierarchyLevel1s} targetData={targetHierarchyLevel1s} onChange={onChange} filterText={"Hierarhy Level 1"} />
      </TabPanel> */}

        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {
            MarketAnalysisTabConstants
            .filter(a=> a.allowedWidgets.length === 0 || a.allowedWidgets.some((tag) => tag === viewItemId))
            .map((item,index) => {
              return (<Tab label={item.label} {...a11yProps(item.id)} />)
            })
          }
        </Tabs>
        {
          MarketAnalysisTabConstants
          .filter(a=> a.allowedWidgets.length === 0 || a.allowedWidgets.some((tag) => tag === viewItemId))
          .map((item,index) => {
            let sourceData = getSourceDataById(item.id)
            let targetData = getTargetDataById(item.id)
            return (
              <TabPanel value={value} index={item.id}>
                <PickListOption sourceData={sourceData} targetData={targetData} 
                  onChange={onChange} 
                  filterText = {item.label} />
              </TabPanel>
            )
        })}
    </Box>
    <Box className="filter-box">
      <p className="defaultt">Filter:</p>
      {
        MarketAnalysisTabConstants
        .filter(a=> a.allowedWidgets.length === 0 || a.allowedWidgets.some((tag) => tag === viewItemId))
        .map((item,index) => {
          let targetData = getTargetDataById(item.id)
          return (<>
            {targetData.length > 0 && (
            <p>
              {item.label} <span>{targetData.length}</span> Selected,
            </p>
            )}
          </>)
        })
      }
      {/* {targetQueues.length > 0 && (
        <p>
          Queues <span>{targetQueues.length}</span> Selected,
        </p>
      )}
      {targetDomains.length > 0 && (
        <p>
          Domains <span>{targetDomains.length}</span> Selected,
        </p>
      )}
      {targetVerticals.length > 0 && (
        <p>
          Business verticals <span>{targetVerticals.length}</span> Selected,
        </p>
      )}
      {targetPlaningEntities.length > 0 && (
        <p>
          Planning entites <span>{targetPlaningEntities.length}</span> Selected,
        </p>
      )}
      {targetForecastGroups.length > 0 && (
        <p>
          Forecast groups <span>{targetForecastGroups.length}</span> Selected,
        </p>
      )}
      {targetHierarchyLevel1s.length > 0 && (
        <p>
          Heirarchey level <span>{targetHierarchyLevel1s.length}</span> Selected,
        </p>
      )} */}
    </Box>
  </>);
})

export default EmailChatFilterOption;


