import { createContext, useContext, useEffect, useState } from "react";

let rtdWidgetData: any = {};

const RTDWidgetDataContext = createContext(rtdWidgetData);

export const RTDWidgetDataContextProvider = (props: any) => {
    const [agentStatusData, setAgentStatusData] = useState<any>([])
    const [agentStatusSummaryData, setAgentStatusSummaryData] = useState<any>([])
    const [phoneData, setPhoneData] = useState<any>([])
    const [emailData, setEmailData] = useState<any>([])
    const [openTicketData, setTicketData] = useState<any>([])
    const [chatAnalysisData, setChatAnalysisData] = useState<any>([])
    const [chatAgeData, setChatAgeData] = useState<any>([])
    const [visitorChatStatsData, setVisitorChatStatsData] = useState<any>([])

    useEffect(() => { 
        return () => {
            console.log(`widget data context unmounted`)
            setAgentStatusData([])
            setAgentStatusSummaryData([])
            setPhoneData([])
            setEmailData([])
            setTicketData([])
            setChatAnalysisData([])
            setChatAgeData([])
            setVisitorChatStatsData([])
        }
    },[])

    const updateAgentSummaryData = (clientSocket) => {
        if(!clientSocket) return true
        clientSocket.on("agent-summary-data", (currentSData: any) => {
            console.log('Agent summary data called')
            setAgentStatusSummaryData(currentSData)
        }) 
    }

    const updateAgentStatusData = (clientSocket) => {
        if(!clientSocket) return true
        clientSocket.on("agent-status-data", (currentSData: any) => {
            console.log('Agent status data called')
            setAgentStatusData(currentSData)
        })
    }

    const updateChatAgeData = (clientSocket) => {
        if(!clientSocket) return true
        clientSocket.on("chatAgeData", (currentData: any) => {
            console.log("chat age data called")
            setChatAgeData(currentData)
        })
    }

    const updateVisitorChatStatsData = (clientSocket) => {
        if(!clientSocket) return true
        clientSocket.on("visitorChatStatsData", (currentData: any) => {
            console.log("visitor chat stats data called")
            setVisitorChatStatsData(currentData)
        })
    }

    const updateChatAnalysisData = (clientSocket) => {
        if(!clientSocket) return true
        clientSocket.on("chatAnalysisData", (currentData: any) => {
            console.log("chat status data called")
            setChatAnalysisData(currentData)
        })
    }

    const updateEmailData = (clientSocket) => {
        if(!clientSocket) return true
        clientSocket.on("emailAnalyticData", (currentData: any) => {
            console.log("email data called")
            setEmailData(currentData)
        })
    }

    const updatePhoneData = (clientSocket) => {
        if(!clientSocket) return true
        clientSocket.on("market", (currentData: any) => {
            console.log("phone data called")
            setPhoneData(currentData)
        })
    }

    const updateCareDesignData = (clientSocket) => {
        if(!clientSocket) return true
        clientSocket.on("designRequestData", (currentData: any) => {
            console.log("design request data called")
            setTicketData(currentData)
        })
    }
    
    rtdWidgetData = { 
        agentStatusData,
        agentStatusSummaryData,
        phoneData,
        emailData,
        openTicketData,
        chatAnalysisData,
        chatAgeData,
        visitorChatStatsData,
        updateAgentStatusData,
        updateAgentSummaryData,
        updateVisitorChatStatsData,
        updateChatAgeData,
        updateChatAnalysisData,
        updatePhoneData,
        updateCareDesignData,
        updateEmailData
    }
    return (<>
        <RTDWidgetDataContext.Provider value={rtdWidgetData}>
            {props.children}
        </RTDWidgetDataContext.Provider>
    </>);
}

export const useRTDWidgetDataContext = () => {
    return useContext(RTDWidgetDataContext);
}