import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import { ReportContextProvider } from "./contextProviders/ReportContextProvider";
import { ReportServiceProvider } from "./services/ReportService";
const ReportRoute = () => {
  return (
    <ReportServiceProvider>
      <ReportContextProvider>
        <Outlet />
      </ReportContextProvider>
    </ReportServiceProvider>
  );
};

export default ReportRoute;
