import { createContext, useContext, useState } from "react";

let RTDInfo: any = {};

const RTDContext = createContext(RTDInfo);

export const RTDContextProvider = (props: any) => {
  const [allShareCollaboratorsRequestData, setAllShareCollaboratorsRequestData] = useState<any>(
    []
  );

  RTDInfo = {
    allShareCollaboratorsRequestData,
    setAllShareCollaboratorsRequestData,
  };
  return (
    <>
      <RTDContext.Provider value={RTDInfo}>
        {props.children}
      </RTDContext.Provider>
    </>
  );
};

export const useRTDContext = () => {
  return useContext(RTDContext);
};
