import { Button } from "primereact/button";
import * as React from "react";
import "./PrwcRoundedButton.scss";

const PrwcRoundedButton = (props: any) => {
  const { onClick, labelKey, className, type = "button", icon, tooltipText, tooltipOptions={position: 'bottom'} } = props;

  return (
    <span className="prw-rounded-button">
      <Button
        icon={icon}
        label={labelKey}
        onClick={onClick}
        type={type}
        className={className}
        tooltip={tooltipText}
        tooltipOptions={tooltipOptions}
      />
    </span>
  );
};

export default PrwcRoundedButton;
