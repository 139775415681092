import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import { ForecastContextProvider } from "./contextProviders/ForecastContextProvider";
import { ForecastServiceProvider } from "./services/ForecastService";
const ForecastRoute = () => {
  return (
    <ForecastServiceProvider>
      <ForecastContextProvider>
        <Outlet />
      </ForecastContextProvider>
    </ForecastServiceProvider>
  );
};

export default ForecastRoute;
