import { Store, StoreConfig } from '@datorama/akita';
import { createInitialForecastState, ForecastState } from './forecast.model';

@StoreConfig({ name: 'forecast', resettable: true })
export class ForecastStore extends Store<ForecastState> {
  constructor() {
    super(createInitialForecastState());
  }
}

export const forecastStore = new ForecastStore();