import { Outlet } from "react-router-dom";
import { RTDContextProvider } from "./contextProviders/RTDContextProvider";
import { RTDServiceProvider } from "./services/RTDService";

const RTDRoute = () => {
    return(    
        <RTDServiceProvider>
            <RTDContextProvider>
                <Outlet />
            </RTDContextProvider>
        </RTDServiceProvider>   
    );
}

export default RTDRoute;