import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { FilterMatchMode } from "../../../../RTD/constants/RTDConstants";
import { checkUndefinedAndNull } from "../../../services/helper";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";


const GlobalSearchFilter = (props) => {
    const {matchMode, onGlobalFilterCallback} = props;
    
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [globalFilters, setGlobalFilters] = useState({
        global: { value: null, matchMode: checkUndefinedAndNull(matchMode) ? matchMode : FilterMatchMode.CONTAINS }    
    })

    const onGlobalFilterChange = (e) => {    
        const value = e.target.value;
        let _filters = { ...globalFilters };

        _filters['global'].value = value;
        
        setGlobalFilters(_filters);
        setGlobalFilterValue(value);

        onGlobalFilterCallback(globalFilters);
    };
    
    return(
        <>
            <div className="">
                <div className="search-box-common">
                    <TextField
                    id="outlined-basic"
                    value={globalFilterValue}
                    label="Search"
                    onChange={onGlobalFilterChange}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    />
                <div className="search-icon">
                 <SearchIcon />
                </div>
                </div>
                {/* <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder=" Search" /> */}
            </div>
        </>
    );
};
export default GlobalSearchFilter;