import { Menu } from "primereact/menu";
import * as React from "react";
import { useRef, useState } from "react";
import { useCommonService } from "../../../../../services/CommonService";
import Validators from "../../../helpers/validator";
import PrwcImageCrop from "../../custom/PrwcImageCrop";
import "./PrwImageUpload.scss";

const PrwImageUpload = (props: any) => {

  const {height = 12, width = 12} = props;

  const [isImageCropperOpen, setIsImageCropperOpen] = useState(false);
  const [image, setImage] = useState("");
  const menu: any = useRef(null);
  const hiddenFileInput: any = useRef(null);
  const commonService = useCommonService();

  const menuItems = [
    {
      label: "Change Profile Photo",
      icon: "pi pi-camera",
      command: () => {
        handleUploadImage();
      },
    },
    {
      label: "Remove",
      icon: "pi pi-times",
      command: () => {
        props.onChange(null);
      },
    },
  ];

  const handleOnFileUpload = async (event: any) => {

    if (event) { 
      const isValidImage = await Validators.isValidImage(event.target.files[0]);

      if (isValidImage) {
        setImage(URL.createObjectURL(event.target.files[0]));

        // Open Image Cropper
        setIsImageCropperOpen(true);
      } else {
        commonService.showToast("Invalid Image");
      }

         // This is to allow same file selection.
        event.target.value = null;
    
    }
  };

  const onImageCropperClosed = () => {
    setIsImageCropperOpen(false);
  };

  const handleOnImageCropped = (Base64imageURL: string) => {
    props.onChange(Base64imageURL);
    setIsImageCropperOpen(false);
  };

  const handleUploadImage = () => {
    hiddenFileInput.current.click();
  };

  const openMenu = (event: any) => {
    menu.current.toggle(event);
  };

  return (
    <div className="prw-image-upload">
      <input
        id="upload-image"
        type="file"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        accept="image/*"
        onChange={(e: any) => handleOnFileUpload(e)}
      ></input>

      <PrwcImageCrop
        isVisible={isImageCropperOpen}
        onHide={onImageCropperClosed}
        image={image}
        onImageCropped={handleOnImageCropped}
      ></PrwcImageCrop>

      <label className={`flex items-center justify-center w-${width} h-${height} overflow-hidden text-white bg-gray-700 border-gray-700 rounded-full cursor-pointer border-1`}>
        {props.value ? (
          <span>
            <img
              src={props.value}
              alt="Upload"
              onClick={(event) => openMenu(event)}
              aria-controls="popup_menu"
            ></img>
          </span>
        ) : (
          <span
            className="flex items-center justify-center w-full h-full text-2xl pi pi-camera"
            onClick={handleUploadImage}
          ></span>
        )}
      </label>

      {/* IMPORTANT: Do not move this inside any conditional rendering section else 'ref' will be null. E.g. <span> above is rendered conditionally. */}
      <Menu model={menuItems} popup ref={menu} id="popup_menu" className="prw-image-upload"/>
    </div>
  );
};

export default PrwImageUpload;
