import { useEffect, useRef, useState } from "react";
import { QTime, compareValues, timeFormat } from "../../../constants/RTDConstants";
import dayjs from 'dayjs'
import { checkUndefinedAndNullEmpty } from "../../../services/helper";

const Timer = (props) => {
    const { data, rules } = props
    const prevProps = usePrevious(props)
    const [intervalId, setIntervalId] = useState<any>(0)
    const [time, setTime] = useState<any>(timeTillNow(data))

    useEffect(() => {
        if (prevProps) {
            if (data.QTime !== prevProps.data.QTime) {
                const newAgent = data;
        
                clearInterval(intervalId);
        
                let currentIntervalId = setInterval(function () {
                  setTime(timeTillNow(newAgent));
                }, 1000);
        
                setTime(timeTillNow(data));
                setIntervalId(currentIntervalId);
            }
        }
    })

    useEffect(() => {
        let currentIntervalId = setInterval(function () {
          setTime(timeTillNow(data))
        }, 1000);
    
        setIntervalId(currentIntervalId)
    
        return () => {
          clearInterval(intervalId)
        }
    }, []);

    

    if(rules !== null){
      if(rules !== undefined && rules.length > 0){
        let comparisonResult = false;
        let bgColor = ''
        let foreColor = ''
        let fontsize = 12
        let specificRules = rules.filter(a => a.column === QTime)
        if(specificRules.length > 0){
          specificRules.map(item => {
            let result = true
            const { backcolor, color, fontSize, conditions} = item
            let conditionList = JSON.parse(conditions)
    
            conditionList.sort((a, b) => {
              if (a.conditionColumn < b.conditionColumn) {
                return -1;
              } else if (a.conditionColumn > b.conditionColumn) {
                return 1;
              } else {
                return 0;
              }
            })
    
            conditionList.forEach((a) => {
              const { conditionColumn, operator, value, logicalOp } = a;
              let targetValue = value
              let sourceValue = data[conditionColumn]
              let valueResult = false
              if(conditionColumn === QTime){
                const currentUTCTime = Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 
                  new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds(), new Date().getUTCMilliseconds())
                let distance =data?.QTime > 0 ? currentUTCTime - data.QTime : 0
                let distanceInSeconds = Math.floor((distance % (1000 * 60)) / 1000)
                valueResult = compareValues(distanceInSeconds,operator,value)
              } else {
                valueResult = compareValues(sourceValue,operator,targetValue)
              }
              
              if(checkUndefinedAndNullEmpty(logicalOp))
              {
                switch(logicalOp)
                {
                  case 'and': 
                    result = result && valueResult
                    break
                  case 'or' :
                    result = result || valueResult
                    break 
                }
              }
              else{
                result = result && valueResult
              }
            })
            if(result){
              bgColor = backcolor
              foreColor = color
              fontsize = fontSize
            }
            return comparisonResult = comparisonResult || result
          })
          return (
            <>
              <div style={{backgroundColor:  bgColor, color: foreColor, fontSize: fontsize}}>
                <span>{time}</span>
              </div>
            </>
          );
        }
      } 
    }
    
    return (
        <div>
            <span>{time}</span>
        </div>
    )
}
export default Timer

const usePrevious = (value) => {
    const ref = useRef<any>();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  

const timeTillNow = (data) => {
    const currentUTCTime = Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 
      new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds(), new Date().getUTCMilliseconds())
    let difference =data?.QTime > 0 ? currentUTCTime - data.QTime : 0;

    const durationObj = dayjs.duration(difference) 
    let time = durationObj.format(timeFormat) 
    return time
} 

