import * as React from 'react';
import { Fragment } from 'react';

const NoLabelFieldTemplate = (props: any) => {
  const {
    classNames,
    help,
    description,
    errors,
    children
  } = props;

  return (
    <div className={`p-field ${classNames}`}>
      {description}
      {children}
      <Fragment>
        {errors.props.errors &&
          errors.props.errors.map((error: any, index: number) => (
            <Fragment key={index}>
              <small className="p-d-block p-error">{error}</small>
            </Fragment>
          ))}
      </Fragment>
      {help}
    </div>
  );
};

export default NoLabelFieldTemplate;
