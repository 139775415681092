import React, { FC, forwardRef, useEffect, useState } from 'react';
import { Carousel } from 'primereact/carousel'
import "../../../../CSS/carouselGlobalCSS.css"
import BarChart from '../../Charts/BarChart';
import { EmailAnalysisCategories } from '../../../../constants/RTDConstants';

const EmailAgeCard = (props: any) => {
    const { data } = props
    const [chartData, setChartData] = useState<any>([])
    useEffect(() => {
        setChartData(data)
    }, [data])

    const responsiveOptions = [
        {
            breakpoint: '1920px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '1024px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '360px',
            numVisible: 1,
            numScroll: 1
        }
    ]
    
    const productTemplate = (item) => {
        let ageData: any = []
        EmailAnalysisCategories.forEach(category => {
            return ageData.push(item.data[category]);
        }) 
        return (
            <div className="carousel-item email-age">
                <div className="carousel-item-content">
                    <div className="carousel-heading">{item.data.Name}</div>
                    <div className='barchart-box'>
                        <BarChart data={ageData} categories = {EmailAnalysisCategories} />
                    </div>
                </div>
            </div>
        );
    }

    return(
        <>
            <div>
                <div className="carousel-part">
                    <div className="card">
                        {data &&(<Carousel value={chartData} circular 
                                autoplayInterval={5000} 
                                numVisible={1} numScroll={1} 
                                responsiveOptions={responsiveOptions}
                                itemTemplate={productTemplate} />)}
                    </div>
                </div>
            </div>
        </>
        )
}

export default EmailAgeCard;
