import { Query, toBoolean } from '@datorama/akita';
import { SessionState } from './session.model';
import { SessionStore, sessionStore } from './session.store';
export class SessionQuery extends Query<SessionState> {
  constructor(protected store: SessionStore) {
    super(store);
  }

  isLoggedIn(){
      return toBoolean(this.getValue().loggedInUserInfo?.userID !== "");
  }

  getLoggedInUserInfo() {
      return this.getValue().loggedInUserInfo;
  }

  getAgentProfileData() {
    return this.getValue().agentProfileData;
  }
}

export const sessionQuery = new SessionQuery(sessionStore);