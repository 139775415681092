import axios from "axios";
import React,{ createContext, useContext } from "react";
import { useAuthContext } from "./AuthContextProvider";

let axiosInfo: any = {};

const AxiosContext = createContext(axiosInfo);

export const AxiosContextProvider = (props:any) => {
    const authInfo = useAuthContext();   
    let token = authInfo.getAccessToken()
    
    const axiosObj = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    });

    axiosObj.defaults.headers['Content-Type'] = 'application/json';
    axiosObj.defaults.headers.common['Authorization'] = 'Bearer ' + token;
   
    axiosInfo = {
        axiosObj,
        token
    };

    return (<>
        <AxiosContext.Provider value={axiosInfo}>
            {props.children}
        </AxiosContext.Provider>
    </>);
}

export const useAxiosContext = () => {
    return useContext(AxiosContext);
}


