import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

export default function IconBreadcrumbs(props: any) {
  const { allBreadCrumbs } = props;
  const navigate = useNavigate()
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          key={0}
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/dashboard")}
        >
          <HomeIcon
            sx={{ mr: "5px", width: "1.2em", height: "1.2em" }}
            fontSize="inherit"
          />
          Home
        </Link>
        {allBreadCrumbs.map((item, i: any) =>
          item.link !== "" ? (
            <Link
              key={i}
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate(item.link)}
            >
              {item.title}
            </Link>
          ) : (
            <Typography key={i} sx={{ display: "flex", alignItems: "center" }}>
              {item.title}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </div>
  );
}
