import React, { useEffect } from "react";
import DynamicForm from "../../../../Core/components/DynamicForm/DynamicForm";
import { HCPMappingTabValue } from "../../../../../enums/common.enum";
import PrwcBasicButton from "../../../../UI/widgets/custom/buttons/PrwcBasicButton";
import Validators from "../../../../UI/helpers/validator";
import NoLabelFieldTemplate from "../../../../UI/templates/fields/NoLabelFieldTemplate";
import VerticalFormTemplate from "../../../../UI/templates/objects/VerticalFormTemplate";
import "./ManageMappings.css";
import { costCenterTypeOptions, levelOptions, orgOptions, planningEntityOptions, siteOptions } from "../../../constants/HCPConstant";

const ManageMappings = (props) => {
  const { data, onSaveSubmit, mappingMasterId, allDropdowns } = props;
  const submitButtonRef: any = React.useRef(null);
  const [formData, setFormData] = React.useState(data);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const onChange = (formData: any) => {
    const getId = (selectedOption: string | null | undefined, dropdownOptions: any[]) => {
      if (!selectedOption) return 0;         
      const matchingOption = dropdownOptions.find(option => option.name === selectedOption);     
      return matchingOption ? matchingOption.id : 0;
    };
    setFormData({
      ...formData,
      costCenterId: getId(formData.costCenterNo, allDropdowns?.costcenternos),
      workGroup: getId(formData?.workGroupId, allDropdowns?.workgroups),
      webCountry: getId(formData?.webCountryId, allDropdowns?.webcountries),
      forecastGroup: getId(formData?.forecastGroupId, allDropdowns?.forecastgroups),
      contactType: getId(formData?.contactTypeId, allDropdowns?.contacttypes),
      costCenterMappingId: mappingMasterId === HCPMappingTabValue.Workgroup ? getId(formData?.costCenterMapping, allDropdowns?.costcentermappings) : 0     
    });   
  };

  const onSubmit = () => {
    onSaveSubmit(formData);
  };

  const onError = (data: any) => {
    console.log("on error ---");
    console.log(data);
  };

  const formButtons = () => {
    return (
      <button ref={submitButtonRef} type="submit" className="hidden p-col-12" />
    );
  };
  const handleOnSubmit = () => {
    // It is to submit form programmatically.
    submitButtonRef.current.click();
  };

  var schema: any;
  var validationSchema: any;
  var uiSchema: any;

  if (mappingMasterId === HCPMappingTabValue.Workgroup) {
    schema = {
      type: "object",
      properties: {
        // costCenterMapping: {
        //   type: "string",
        //   title: "Cost Center Mapping",
        // },
        costCenterNo: { type: "string", title: "Cost Center No" },
        workGroupId: {
          type: "string",
          title: "Work Group",
        },
        webCountryId: {
          type: "string",
          title: "Web Country",
        },
        forecastGroupId: {
          type: "string",
          title: "Forecast Group",
        },
        contactTypeId: {
          type: "string",
          title: "Contact Type",
        },
        staffingPriority: {
          type: "string",
          title: "Staffing Priority",
        }
      },
      required: [
        "costCenterNo", "forecastGroupId", "workGroupId", "webCountryId", "contactTypeId", "staffingPriority",
      ]
    };

    validationSchema = {
      // costCenterMapping: {
      //   validators: [
      //     {
      //       validate: (value: string) => {
      //         return !Validators.isStringEmpty(value);
      //       },
      //       messageKey: "Cost Center No is required",
      //     },
      //   ],
      // },
      costCenterNo: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Cost Center No is required",
          },
        ],
      },
      forecastGroupId: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Forecast Group is required",
          },
        ],
      },
      workGroupId: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Work Group is required",
          },
        ],
      },
      webCountryId: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Web Country is required",
          },
        ],
      },
      contactTypeId: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Contact Type is required",
          },
        ],
      },
      staffingPriority: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "staffing Priority is required",
          },
        ],
      },
    };
    uiSchema = {
      "ui:ObjectFieldTemplate": VerticalFormTemplate,
      // costCenterMapping: {
      //   "ui:widget": "prwAutoComplete",
      //   "ui:FieldTemplate": NoLabelFieldTemplate,
      //   classNames: "textbox",
      //   "ui:emptyValue": "",
      //   "ui:defaultValue": data?.costCenterMapping,
      //   "ui:options": {
      //     placeholder: "Cost Center Mapping",
      //     dropdownOptions: allDropdowns?.costcentermappings,
      //     optionLabel: "Select Cost Center Mapping",
      //     optionValue: "id",
      //   },
      // },
      costCenterNo: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.costCenterNo,
        "ui:options": {
          placeholder: "Cost Center No",
          dropdownOptions: allDropdowns?.costcenternos,
          optionLabel: "Select Cost Center No",
          optionValue: "id",
        },
      },
      workGroupId: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.workGroupId,
        "ui:options": {
          placeholder: "WorkGroup",
          dropdownOptions: allDropdowns?.workgroups,
          optionLabel: "Select WorkGroup",
          optionValue: "id",
        },
      },
      webCountryId: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.webCountryId,
        "ui:options": {
          placeholder: "webCountry",
          dropdownOptions: allDropdowns?.webcountries,
          optionLabel: "Select Web Country",
          optionValue: "id",
        },
      },
      forecastGroupId: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.forecastGroupId,
        "ui:options": {
          placeholder: "ForecastGroup",
          dropdownOptions: allDropdowns?.forecastgroups,
          optionLabel: "Select Forecast Group",
          optionValue: "id",
        },
      },
      contactTypeId: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.contactTypeId,
        "ui:options": {
          placeholder: "Contact Types",
          dropdownOptions: allDropdowns?.contacttypes,
          optionLabel: "Select Contact Types",
          optionValue: "id",
        },
      },
      staffingPriority: {
        "ui:widget": "prwInputText",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
      },
    };
  }
  else if (mappingMasterId === HCPMappingTabValue.CostCenter) {
    schema = {
      type: "object",
      properties: {
        // costCenterMapping: {
        //   type: "string",
        //   title: "Cost Center Mapping Name",
        // },
        costCenterNo: { type: "string", title: "Cost Center No" },
        site: { type: "string", title: "Site" },
        level: { type: "string", title: "Level" },
        organization: { type: "string", title: "Organization" },
        planningEntity: { type: "string", title: "Planning Entity" },
        costCenterType: { type: "string", title: "Cost Center Type" }
      },
      required: ["costCenterMapping", "costCenterNo", "site", "level", "organization", "planningEntity","costCenterType"],
    };

    validationSchema = {
      // costCenterMapping: {
      //   validators: [
      //     {
      //       validate: (value: string) => {
      //         return !Validators.isStringEmpty(value);
      //       },
      //       messageKey: "Name is required",
      //     },
      //   ],
      // },
      costCenterNo: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Cost Center No is required",
          },
        ],
      },
      site: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Site is required",
          },
        ],
      },
      level: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Level is required",
          },
        ],
      },
      organization: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Organization is required",
          },
        ],
      },
      planningEntity: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Planning Entity is required",
          },
        ],
      },
      costCenterType: {
        validators: [
          {
            validate: (value: string) => {
              return !Validators.isStringEmpty(value);
            },
            messageKey: "Cost Center Type is required",
          },
        ],
      },
    };

    uiSchema = {
      "ui:ObjectFieldTemplate": VerticalFormTemplate,
      // costCenterMapping: {
      //   "ui:widget": "prwInputText",
      //   "ui:FieldTemplate": NoLabelFieldTemplate,
      //   classNames: "textbox",
      //   "ui:emptyValue": "",
      // },
      costCenterNo: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.costCenterNo,
        "ui:options": {
          placeholder: "Cost Center No",
          dropdownOptions: allDropdowns?.costcenternos,
          optionLabel: "Select Cost Center No",
          optionValue: "id",
        },
      },
      site: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.site,
        "ui:options": {
          placeholder: "Site",
          dropdownOptions: siteOptions,
          optionLabel: "Select Site",
          optionValue: "id",
        },
      },
      level: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.level,
        "ui:options": {
          placeholder: "Level",
          dropdownOptions: levelOptions,
          optionLabel: "Select level",
          optionValue: "id",
        },
      },
      organization: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.organization,
        "ui:options": {
          placeholder: "Organization",
          dropdownOptions: orgOptions,
          optionLabel: "Select Organization",
          optionValue: "id",
        },
      },
      planningEntity: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.planningEntity,
        "ui:options": {
          placeholder: "Planning Entity",
          dropdownOptions: planningEntityOptions,
          optionLabel: "Select Planning Entity",
          optionValue: "id",
        },
      },
      costCenterType: {
        "ui:widget": "prwAutoComplete",
        "ui:FieldTemplate": NoLabelFieldTemplate,
        classNames: "textbox",
        "ui:emptyValue": "",
        "ui:defaultValue": data?.costCenterType,
        "ui:options": {
          placeholder: "Cost Center Type",
          dropdownOptions: costCenterTypeOptions,
          optionLabel: "Select Cost Center Type",
          optionValue: "id",
        },
      },
    };
  }

  const dynamicFormContent = () => {
    return (
      <div className="manage-workgrup">
        <DynamicForm
          onChange={onChange}
          formData={formData}
          onSubmit={onSubmit}
          onError={onError}
          schema={schema}
          uiSchema={uiSchema}
          validationSchema={validationSchema}
          formButtons={() => formButtons()}
        ></DynamicForm>
      </div>
    );
  };
  const customButtonsContent = () => {
    return (
      <div className="rightsidebutn" style={{ marginTop: "0 !important" }}>
        <PrwcBasicButton
          onClick={handleOnSubmit}
          onSubmit={onSubmit}
          color="primary"
          variant="contained"
          labelKey={"Save"}
          classObj={{}}
        ></PrwcBasicButton>
      </div>
    );
  };

  return (
    <>
      <div className="group editqueueform">
        {dynamicFormContent()}
        {customButtonsContent()}
      </div>
    </>
  );
}
export default ManageMappings;