import { MenuItem } from "@mui/material";
import Select  from "@mui/material/Select";
import { Dropdown } from "primereact/dropdown";
import * as React from "react";
import "./PrwDropdownBasic.scss";

const PrwDropdownBasic = (props: any) => {
  let { options, placeholder, ...restProps } = props;

  options["optionLabel "] = options["optionLabel"] || "label";
  options["optionValue "] = options["optionValue"] || "value";  
  options["dropdownOptions"] = options["dropdownOptions"] || [];
  options["placeholder"] = options["placeholder"] || placeholder;

  return (
      <Select
        labelId="demo-simple-select-helper-label"
        className={`p-inputtext-sm ${
          props?.rawErrors?.length > 0 ? "p-invalid" : null
        } ${props.className}`}
        value={restProps.value !== undefined ? restProps.value : "0"}
        label={options.placeholder}
        onChange={(e: any) => {
          restProps.onChange(e.target.value);
        }}
        sx={{ width: "100%" }}
      >
        { options.optionLabel !== "label" && options.optionValue !== "value" 
          && (<MenuItem key={options.optionValue} value = {options.optionValue}>{options.optionLabel}</MenuItem>)}
        {options.dropdownOptions.map((item) => {
            return <MenuItem key={item.id} value = {item.id}>{item.name}</MenuItem>
        })}
      </Select>  
  );
};

export default PrwDropdownBasic;
