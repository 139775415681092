import { timeFormat } from "../constants/RTDConstants";
import { checkUndefinedAndNull, filterByMarket } from "./helper";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
interface Item {
    QueueName: string,
    CONTACTS_IN_QUEUE: number,
    CONTACTS_HANDLED_INCOMING: number,
    CONTACTS_HANDLED: number,
    CONTACTS_ABANDONED: number,
    HANDLE_TIME: any,
    ABANDONED_RATE: number,
    CONTACTS_QUEUED: number,
    REEL_OLDEST_CONTACT_AGE?: any,
    OLDEST_CONTACT_AGE: any,
    SERVICE_LEVEL: number,
    timeZone: string
}

export const emitAgentMarketData = (socket, filterInfo): any => {
    // let chachedData = queryClient.getQueryData(["phoneAnalysis"])
    // if(!checkUndefinedAndNull(chachedData))
        socket.emit("getMarketData", filterInfo);
}

export const updateMarketData = (socket, groupField, filterObj, cb, queryClient) => {
    if (!socket) return true;
    socket.on("market", (currentData: any) => {
        console.log("market updated called")
        queryClient.setQueriesData({ queryKey: ["phoneAnalysis"] },currentData)
        const updatedData = MarketHelpersTrieData(
            currentData,
            groupField,
            filterObj?.filterValues
        );
        cb(null, updatedData);
    });
}

export const unsubscribeMarketEvent = (socket) => {
    if (socket) {
        socket.off("market")

        //queryClient.removeQueries({ queryKey: ["phoneAnalysis"]})
    }
}

export const MarketHelpersOldesttime = (OLDEST_CONTACT_AGE: any): string => {
    let value = OLDEST_CONTACT_AGE ? OLDEST_CONTACT_AGE : 0
    const durationObj = dayjs.duration(value * 1000)
    let time = durationObj.format(timeFormat)
    return time;
}

export const MarketHelpersSLAThreshold = (level: number): string => {
    if (level >= 80) return "cellSuccess";
    else if (level < 70) return "cellError";
    else return "cellWarning";
}

// reorder the data
export const MarketHelpersTrieData = (response: any, groupField, filterValues: any = [], secondaryFilterValues: string[] = []) => {
    var HashMap = require('hashmap');
    let customTable = new HashMap();
    let customData: any = [];
    let groupKey = checkUndefinedAndNull(groupField) ? groupField : "Domain"
    
    if (response && response.length) {
        let responseData = response;
        if (filterValues.length > 0) {
            let filterObj = filterValues[0];
            if (checkUndefinedAndNull(filterObj)) {
                responseData = filterByMarket(responseData, filterObj);
            }
        }
        responseData.map((item: Item) => {
            if (customTable.get(item[groupKey]) === undefined) {
                customTable.set(item[groupKey], {
                    id: item[groupKey] !== null ? `Marquet : ${item[groupKey]}` : `Default`,
                    name: item[groupKey] !== null ? `Marquet : ${item[groupKey]}` : `Default`,
                    CONTACTS_IN_QUEUE: 0,
                    CONTACTS_HANDLED_INCOMING: 0,
                    CONTACTS_ABANDONED: 0,
                    CONTACTS_HANDLED: 0,
                    ABANDONED_RATE: 0,
                    CONTACTS_QUEUED: 0,
                    OLDEST_CONTACT_AGE: 0,
                    REEL_OLDEST_CONTACT_AGE: 0,
                    HANDLE_TIME: 0,
                    SERVICE_LEVEL: 0,
                    CALLS_IN_SL: 0,
                    timeZone: '',
                    items: [],
                })
            }
            let marketItem = customTable.get(item[groupKey]);
            let count = marketItem.items.length + 1;
            marketItem.id = item[groupKey] !== null ? `${item[groupKey]}` : `Default`
            marketItem.QueueName = item[groupKey] !== null ? `${item[groupKey]}` : `Default`
            marketItem.CONTACTS_IN_QUEUE = marketItem.CONTACTS_IN_QUEUE + (checkUndefinedAndNull(item.CONTACTS_IN_QUEUE) ? item.CONTACTS_IN_QUEUE : 0);
            marketItem.CONTACTS_HANDLED_INCOMING = marketItem.CONTACTS_HANDLED_INCOMING + (checkUndefinedAndNull(item.CONTACTS_HANDLED_INCOMING) ? item.CONTACTS_HANDLED_INCOMING : 0);
            marketItem.CONTACTS_HANDLED = marketItem.CONTACTS_HANDLED + (checkUndefinedAndNull(item.CONTACTS_HANDLED) ? item.CONTACTS_HANDLED : 0);
            item.ABANDONED_RATE = item.CONTACTS_QUEUED > 0 ? item.CONTACTS_ABANDONED * 100 / item.CONTACTS_QUEUED : 0;
            marketItem.CONTACTS_ABANDONED = marketItem.CONTACTS_ABANDONED + item.CONTACTS_ABANDONED;
            marketItem.CONTACTS_QUEUED /* Offered */ = marketItem.CONTACTS_QUEUED + item.CONTACTS_QUEUED;
            marketItem.ABANDONED_RATE = marketItem.CONTACTS_QUEUED > 0 ? marketItem.CONTACTS_ABANDONED * 100 / marketItem.CONTACTS_QUEUED : 0;
            const OLDEST_CONTACT_AGE = item.OLDEST_CONTACT_AGE ? item.OLDEST_CONTACT_AGE : 0;
            marketItem.REEL_OLDEST_CONTACT_AGE = Math.max(marketItem.REEL_OLDEST_CONTACT_AGE, OLDEST_CONTACT_AGE);
            marketItem.OLDEST_CONTACT_AGE = marketItem.REEL_OLDEST_CONTACT_AGE
            marketItem.CALLS_IN_SL = marketItem.CALLS_IN_SL + (item.SERVICE_LEVEL * item.CONTACTS_QUEUED / 100);
            marketItem.SERVICE_LEVEL = marketItem.CONTACTS_QUEUED === 0 ? 0 : marketItem.CALLS_IN_SL * 100 / marketItem.CONTACTS_QUEUED;
            marketItem.timeZone = item.timeZone
            marketItem.items.push(item);
            customTable.set(item[groupKey], marketItem);
        })
    }

    customTable.forEach((citem, i) => {
        let childItems = citem.items.map((item, idx) => {
            return {
                key: i + "-" + idx.toString(),
                data: item
            }
        })

        let nodeItem = {
            key: i,
            data: citem,
            children: childItems
        }

        customData.push(nodeItem)
    })
    return customData;
}

