import { TextField } from "@mui/material";
import React from "react";
import "../PrwInputText/PrwInputText.css";

const PrwInputText = (props: any) => {
  return (
    <TextField
      label={props.label}
      disabled={props.disabled}
      className={`${props?.rawErrors?.length > 0 ? "p-invalid" : null} ${
        props.classNames
      }`}
      value={props.value || ""}
      onChange={(e: any) => props.onChange(e.target.value)}
      sx={{ width: "100%" }}      
    />
  );
};

export default PrwInputText;
