import { Slider } from 'primereact/slider';
import * as React from 'react';
import "./PrwcSlider.scss";

const PrwcSlider = ({value, onValueChange}: any) => {
  return <div>
    <Slider value={value} onChange={(e) => onValueChange(e.value)}/>
  </div>;
};

export default PrwcSlider;
