import { Store, StoreConfig } from '@datorama/akita';
import { createInitialDNAState, DNAState } from './dna.model';

@StoreConfig({ name: 'dna', resettable: true })
export class DNAStore extends Store<DNAState> {
  constructor() {
    super(createInitialDNAState());
  }
}

export const dnaStore = new DNAStore();