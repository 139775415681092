import React from "react";
import { Box, CircularProgress } from "@mui/material";
import "./PageLoader.css";

const PageLoader = () => (
  <Box className="cover-spin">
    <Box className="loader">
      <CircularProgress />
    </Box>
  </Box>
);

export default PageLoader;
