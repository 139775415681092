import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import { GetColumnToCustomTemplate, GetColumnToShow, checkUndefinedAndNull } from "../../../../services/helper";
import { forwardRef, useEffect, useState } from "react";
import { useRTDService } from "../../../../services/RTDService";
import { IColumn } from "../../../../../../contracts/IColumn";
import OpenTicketStatusDetail from "../OpenTicketStatusDetail";
import { Dialog } from "primereact/dialog";

const OpenTicketList = forwardRef((props: any, ref) => {
    const { data, detailData, onChangeItem, widgetInfo, globalFilterInfo } = props
    const rtdService = useRTDService()
    const columns: IColumn[] = [
      {
        id: "Name",
        label: "Name",
        minWidth: 400,
        align: "left",
        visible: true,
        sortable: true,
        resizeable: true
      },
      {
        id: "Missed",
        label: "Missed",
        visible: true,
        align: "center",
        sortable: true,
        resizeable: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'Missed')
      },
      {
        id: "<3",
        label: "<3",
        visible: true,
        align: "center",
        sortable: true,
        resizeable: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'<3')
      },
      {
        id: "<6",
        label: "<6",
        visible: true,
        align: "center",
        sortable: true,
        resizeable: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'<6')
      },
      {
        id: "<9",
        label: "<9",
        visible: true,
        align: "right",
        sortable: true,
        resizeable: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'<9')
      },
      {
        id: "<12",
        label: "<12",
        visible: true,
        align: "right",
        sortable: true,
        resizeable: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'<12')
      },
      {
        id: "<15",
        label: "<15",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'<15')
      },
      {
        id: "<18",
        label: "<18",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'<18')
      },
      {
        id: ">18",
        label: ">18",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'>18')
      },
      {
        id: "Total",
        label: "Total",
        sortable: true,
        resizeable: true,
        visible: true,
        minWidth: 100,
        customTemplate: (e) => linkCustomTemplate(e,'Total')
      }
    ]
    const [showDialog, setShowDialog] = useState<any>(false)
    const [columnToShow, setColumnToShow] = useState<any>(columns);
    //const [formattedColumns, setFormattedColumns] = useState<any>(columns);
    const [multiSortMeta, setMultiSortMeta] = useState<any>([{field: "Name", order:1}])
    const [selectedValue, setSelectedValue] = useState<any>(null)
    const [selectedField, setSelectedField] = useState<any>(null)

    useEffect(()=>{
        let multiSortMeta = widgetInfo.multiSortMeta !== undefined 
          && widgetInfo.multiSortMeta !== null ? JSON.parse(widgetInfo.multiSortMeta) : [{field: "Name", order:1}]
        setMultiSortMeta(multiSortMeta)
    },[widgetInfo.multiSortMeta])
  
    useEffect(() => {
      let updatedColumns = GetColumnToCustomTemplate(rtdService,columns, widgetInfo.appliedCFRules);
      //setFormattedColumns(updatedColumns)
      let filterColumns = GetColumnToShow(updatedColumns, widgetInfo.selectedColumns);
      //let groupColumns = getChangeColumnHeader(widgetInfo,filterColumns)
      setColumnToShow(filterColumns)
    }, [widgetInfo.selectedColumns,widgetInfo.appliedCFRules])


    const onSaveColReorder = (item) => {
        let newDragIndex = item.dragIndex - 1;
        let newDropIndex = item.dropIndex - 1;
        var element = columnToShow[newDragIndex];
        columnToShow.splice(newDragIndex, 1);
        columnToShow.splice(newDropIndex, 0, element);

        let reorderColumns = columnToShow.map((item) => {
            return item.id;
        });
        let updatedWidgetInfo = {...widgetInfo, selectedColumns: JSON.stringify(reorderColumns)}
        onChangeItem(updatedWidgetInfo)
    };

    const onSort = (e) => {
        setMultiSortMeta(e.multiSortMeta)
        let updatedWidgetInfo = {...widgetInfo, "multiSortMeta": JSON.stringify(e.multiSortMeta)}
        onChangeItem(updatedWidgetInfo)
    }

    const linkCustomTemplate = (e,field) => {
        let cdata = e.data
        return (cdata[field] > 0 ? <a className="" onClick={() => {showRequestDetails(cdata,field)}}>{cdata[field]}</a> : '-')
    }

    const showRequestDetails = (currentData, currentField) => {
      setSelectedValue(currentData)
      setSelectedField(currentField)
      setShowDialog(true)
    }

    const onHide = () =>{
      setShowDialog(false)
    }

    const TicketStatusDetailContent = (rdata) => {
      let updatedMasterData = checkUndefinedAndNull(rdata) ? rdata : []
      if(checkUndefinedAndNull(selectedValue)){
        let groupedFieldValues = selectedValue.id.split(':')
        let groupedFields = selectedValue.idFields.split(':')

        groupedFields.forEach((item,i) => {
          let fieldValue = groupedFieldValues[i]
          updatedMasterData = updatedMasterData.filter(a=>a[item] === fieldValue)
        });
      }

      if(selectedField !== 'Total'){
        updatedMasterData = updatedMasterData.filter(a=>a.HoursBeforeDeadline === selectedField)
      }
      return(<>
          <Dialog visible={showDialog} 
            modal 
            header="Email Case Detail" 
            resizable
            style={{ width: '50rem' }} 
            onHide={() => onHide()}
          >
            <OpenTicketStatusDetail data={updatedMasterData}/>
          </Dialog>
      </>)
    }

    return (
        <>
          <div className="AgentMarketlist">
              <TreeTable
                  value={data}
                  onSort={onSort}
                  sortMode="multiple"
                  globalFilter={checkUndefinedAndNull(globalFilterInfo) ? globalFilterInfo['global'].value : ""}
                  filterMode = {checkUndefinedAndNull(globalFilterInfo) ? globalFilterInfo['global'].matchMode : ""}
                  multiSortMeta={multiSortMeta}
                  resizableColumns
                  removableSort
                  reorderableColumns
                  columnResizeMode="expand"
                  onColReorder={onSaveColReorder}
                  scrollable
                  scrollHeight={widgetInfo.dynamicHeight}
              >
              <Column expander style={{ width: "4em", justifyContent: "center" }} />
              {columnToShow.map((column, index) => {
                  return (
                  <Column
                      key={column.id}
                      hidden={!column.visible}
                      sortable={column.sortable !== undefined ? column.sortable : false}
                      resizeable={
                        column.resizeable !== undefined ? column.resizeable : false
                      }
                      field={column.id}
                      header={column.label}
                      body={column.customTemplate}
                  ></Column>
                  );
              })}
              </TreeTable>
          </div>
          {TicketStatusDetailContent(detailData)}
        </>
    );
})

export default OpenTicketList