import { forwardRef, useEffect, useState } from "react"
import DynamicComponent from "../../../../../Core/components/DynamicComponent";
import { ViewTypeConstants, WidgetComponentConstants } from "../../../../constants/RTDConstants";
import { useRTDService } from "../../../../services/RTDService";
import { emitCareDesignData, processOpenTicketStatus, unsubscribeCareDesignEvent, updateCareDesignData } from "../../../../services/careDesignService";
import { checkUndefinedAndNull, checkUndefinedAndNullLength } from "../../../../services/helper";
import { useRTDWidgetDataContext } from "../../../../contextProviders/RTDWidgetDataContextProvider";
import PageLoader from "../../../../../../components/PageLoader";

const OpenTicketStatus = forwardRef((props: any, ref) => {
    const { widgetInfo, onChangeItem, filterInfo, globalFilterInfo, clientSocket } = props
    //const [clientSocket,setClientSocket] = useState<any>(undefined)
    const [designRequestData, setDesignRequestData] = useState<any>(undefined)
    const [requestDetailData, setRequestDetailData] = useState<any>([])
    const { openTicketData } = useRTDWidgetDataContext()
    // const { 
    //     initialSocketConnection
    // } = useRTDService();

    // useEffect(()=>{
    //     let socket = initialSocketConnection(clientSocket)
    //     setClientSocket(socket)   
    //     return () => {
    //       unsubscribeCareDesignEvent(clientSocket)
    //       if (clientSocket) {
    //           console.log("Disconnecting socket...");
    //           clientSocket.disconnect()
    //       }
    //     };
    // },[clientSocket])

    useEffect(() => {
        if(checkUndefinedAndNullLength(openTicketData)){          
          let combinedData = processOpenTicketStatus(openTicketData, filterInfo, widgetInfo);
          careDesignDataCallback(null, combinedData);
        }
        // if(clientSocket){
        //   let chachedData = queryClient.getQueryData(["openTicketStatus"])
        //   if(checkUndefinedAndNull(chachedData)){
        //     let combinedData = processOpenTicketStatus(chachedData,filterInfo,widgetInfo)
        //     careDesignDataCallback(null,combinedData)
        //   } else {
        //     emitCareDesignData(clientSocket,queryClient,null)
        //   }
        //   updateCareDesignData(clientSocket, filterInfo,widgetInfo,careDesignDataCallback,queryClient)
        // }
    }, [clientSocket, openTicketData, filterInfo, widgetInfo.groupField, widgetInfo.subLevelGroupFields])

    const careDesignDataCallback = (err, currentData) => { 
      setDesignRequestData(currentData.processData)
      setRequestDetailData(currentData.rowData)
    }

    const getWidgetItemByType = () => {
        let currentWidgetComponent: any = WidgetComponentConstants.find(
          (item) => item.widgetId === widgetInfo.widgetType
        );
        let currentWidgetItem = currentWidgetComponent?.items?.find(
          (item) => item.id === widgetInfo.viewItemId
        );
        let currentWidgetType = currentWidgetItem?.viewTypes?.find(
          (item) => item.id === widgetInfo.viewTypeId
        );
    
        return (
          <DynamicComponent
            component={currentWidgetType?.viewCompoent}
            data={designRequestData}
            widgetInfo={widgetInfo}
            onChangeItem={onChangeItem}
            detailData = {requestDetailData}
            globalFilterInfo={globalFilterInfo}
          />
        )
      }
      
      return (
        <>
          {designRequestData && (<div
            className="role-content"
            style={{ overflow: "auto", height: "auto", position: "relative" }}
          >
            {getWidgetItemByType()}
            
          </div>)}
          {!designRequestData && <PageLoader />}
        </>
      )
})

export default OpenTicketStatus