import React,{ createContext, useContext, useEffect, useState } from "react";
import { getUserByEmailUrl, Privileges } from "../constants/CommonConstant";
import { useAuthContext } from "./AuthContextProvider";
import { useAxiosContext } from "./AxiosContextProvider";
import { sessionQuery, sessionStore } from "../state";
import { useCommonService } from "../services/CommonService";

import { IUserBaseDetails } from "../contracts/IUserBaseDetails";

let userInfoObj: any = {};
const UserContext = createContext(userInfoObj);

export const UserContextProvider = (props: any) => {
  const authInfo = useAuthContext();
  const commonService = useCommonService();
  const { axiosObj } = useAxiosContext();

  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUserBaseDetails | null>(userInfoObj);
  const [allUsersRequestData, setAllUsersRequestData] = useState<any>([]);

  const [loggedUserInfo,setLoggedUserInfo] = useState(sessionQuery.getLoggedInUserInfo());


  useEffect(function () {
    if(loggedUserInfo !== null && loggedUserInfo.email === "" && authInfo.isLoggedIn()){
      const profileInfo = authInfo.getProfile();
      let requestObj = {
        email: profileInfo.email,
      };

      const userByEmailReq = axiosObj.post(getUserByEmailUrl, requestObj);
      userByEmailReq.then((result) => {
        var res = result.data;

        if(res.isSuccess){
          var userDetail = res.userDetail;

          sessionStore.setLoggedUserInfo({
              email: userDetail.email,
              firstName: userDetail.firstName,
              lastName: userDetail.lastName,
              fullName: userDetail.fullName,
              userName: userDetail.userName,
              teamMemberID: userDetail.teamMemberID,
              userID: userDetail.userID,
              isWFMUser: userDetail.isWFMUser,
              center: userDetail.center,
              centerId: userDetail.centerId,
              isManager : userDetail.isManager,
              managerUserId : userDetail.managerUserId,
              designation : userDetail.designation,
              assignedPrivileges: userDetail.assignedPrivileges,
              userTheme: userDetail.theme
            }
          );
          setLoggedUserInfo(sessionQuery.getLoggedInUserInfo());
        }
        else{
          commonService.showToast('error',res.message); 
        }
      }).catch((err) => {
        commonService.showToast('error',err.message);        
      });
    }
  }, []);

  const canAccessParticularPrivilege = (privilegeId : number) => {
    var userInfo = sessionQuery.getLoggedInUserInfo();
    if(userInfo !== null || userInfo !== undefined)
    {
        var canAcceesPrivilege = userInfo?.assignedPrivileges?.split(',')
              .filter(role => { return role.trim() === privilegeId.toString()})  
      
        return canAcceesPrivilege !== undefined ? canAcceesPrivilege?.length > 0 : false;
    }

    return false;
  }

  userInfoObj = {
    selectedUser,
    loader,    
    setLoader,
    btnLoader,
    setBtnLoader,
    setSelectedUser,
    allUsersRequestData,
    setAllUsersRequestData,
    loggedUserInfo,
    canAccessParticularPrivilege
  };

  return (
    <UserContext.Provider value={userInfoObj}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
