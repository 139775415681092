import { Carousel } from 'primereact/carousel'
import GaugeChart from 'react-gauge-chart'
import "../../../../CSS/carouselGlobalCSS.css"
import Timer from '../../Timer';
import { getMarketGroupName } from '../../../../services/helper';
import { useEffect, useState } from 'react';

const ChatAnalysisCard = (props:any) => {
    const { data, widgetInfo } = props

    const [chartData, setChartData] = useState<any>([])
    useEffect(() => {
        setChartData(data)
    }, [data])
  
    const responsiveOptions = [
        {
            breakpoint: '1920px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '1024px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '360px',
            numVisible: 1,
            numScroll: 1
        }
    ]

    let groupName = getMarketGroupName(widgetInfo)
     
    const productTemplate = (item,groupName) => {
        let percentage = item.data.ServiceLevel/100
        return (
           <div className="carousel-item">
                <div className="carousel-item-content chat-widget-box">
                    <div className="carousel-heading">{`${groupName}: ${item.data.QueueName}`}</div>
                    <div className="firstpart-carousel">
                         <div className="carousel-box">
                             <p>OFFERED</p>
                             <span>{item.data.Offered}</span>
                         </div>
                        <div className="carousel-box">
                             <p>HANDLED</p>
                             <span>{item.data.Completed}</span>
                        </div>
                        <div className="carousel-box">
                             <p>IN PROGRESS</p>
                             <span>{item.data.In_Progress}</span>
                        </div>
                        <div className="carousel-box">
                           <p>ABANDONED</p>
                           <span>{item.data.Missed}<sub>{item.data.AbandonedRate?.toFixed(1)}%</sub></span>
                         </div>
                    </div>
                     <div className="sla-mainbox withotheroption">
                        <div className="carousel-box">
                           <p>ASA</p>
                           <span>{item.data.AWT?.toFixed()}</span>
                         </div>
                       <div><GaugeChart id={`service-level-${item.id}`} 
                            nrOfLevels={3} arcWidth={0.3} 
                            arcsLength={[0.5, 0.35, 0.15]}
                            colors={['#EA4228','#F5CD19','#5BE12C']}
                            arcPadding={0.03}
                            animate={false}
                            percent={percentage} textColor="#000"/>
                            <div className='sla-text'>SLA</div>
                        </div>
                        <div className="carousel-box">
                           <p>AHT</p>
                           <span>{item.data.ACD?.toFixed()}</span>
                         </div>
                     </div>  
                     <div className="carousel-data">
                         <div className="carousel-box">
                            <p>IN QUEUE</p>
                            <span>{item.data.Waiting}</span>
                         </div>
                         <div className="carousel-box">
                            <p>QUEUE TIME</p>
                             <span>{item.data && <Timer data={item.data} rules={widgetInfo.appliedCFRules} />}</span>
                         </div>
                     </div>
           </div>
          </div>
             
        );
    }

    return (
    <>
    <div>
        <div className="carousel-part">
            <div className="card">
            {data &&(<Carousel value={chartData} circular 
                        autoplayInterval={5000} 
                        numVisible={1} numScroll={1} 
                        responsiveOptions={responsiveOptions}
                        itemTemplate={(item) => productTemplate(item,groupName)} /> )}
            </div>
        </div>
    </div>
    </>
    )
}

export default ChatAnalysisCard;